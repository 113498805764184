<template>
  <div class="infoDetail">
    <el-header>
      <v-head :active="5" :isBanner="false"></v-head>
    </el-header>
    <div class="msg_box" style="background: #f7f9fc;">
      
      <div class="mgwhite">

        <div class="container flexs" style="height: 100px">
          <div class="nsv">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
              >
              <el-breadcrumb-item :to="{ path: '/agreement' }"
                >隐私政策</el-breadcrumb-item
              >
              <el-breadcrumb-item>
                <a href="">比亚迪产品隐私政策声明</a>
              </el-breadcrumb-item>
            </el-breadcrumb>
            <div style="position: absolute;left: 900px;">
              <select name="" id="" @input="type($event)">
                <option value="1">中文</option>
                <option value="2">English</option>
                <option value="3">Língua portuguesa português</option>
                <option value="4">español</option>
              </select>
            </div>
          </div>
        </div>

        <!-- <div class="content container clearfix" >
          <h1>{{dataInfo[info].title}}</h1>
          <ol>
            <li>{{dataInfo[info].projectName}}</li>
            <li>{{dataInfo[info].projectContent}}</li>
            <li>{{dataInfo[info].transaction}}</li>
            <div style="text-align: left" v-if="dataInfo[info].TemplateID == 0">
              <ol style="margin-top: -5px">{{dataInfo[info].suppliers}}</ol>
              <ol style="margin-top: 5px">{{dataInfo[info].amount}}</ol>
              <ol style="margin-top: 5px">{{dataInfo[info].address}}</ol>
            </div>
            <li>{{dataInfo[info].timeLimit}}</li>
          </ol>
          <div class="msg_foot">
            <h6>{{dataInfo[info].company}}</h6>
            <h6>{{dataInfo[info].time}}</h6>
          </div>
        </div> -->
        <div class="content container clearfix" >
          <div v-if="language == 1" style="padding: 0 40px;">
            <div class="title">隐私协议</div>
            <div class="contents">
              <div class="text1">本应用非常重视用户的个人信息和隐私保护。您在使用本应用产品及/或服务时，本应用可能会收集和使用您的相关信息。本应用希望通过本《隐私协议》向您说明，在使用本应用产品及/或服务时，本应用如何收集、使用、分享、保存和管理这些信息。</div>
              <div class="mt10">本《隐私协议》将帮助您了解以下内容：</div>
              <div class="text1">第一章 &nbsp;&nbsp;&nbsp;&nbsp;   本应用如何收集您的个人信息</div>
              <div class="text1">第二章 &nbsp;&nbsp;&nbsp;&nbsp;   本应用如何使用您的个人信息</div>
              <div class="text1">第三章 &nbsp;&nbsp;&nbsp;&nbsp;   本应用如何共享、转让、公开披露您的个人信息</div>
              <div class="text1">第四章 &nbsp;&nbsp;&nbsp;&nbsp;   本应用如何保护您的个人信息</div>
              <div class="text1">第五章 &nbsp;&nbsp;&nbsp;&nbsp;   本应用如何保存您的个人信息</div>
              <div class="text1">第六章 &nbsp;&nbsp;&nbsp;&nbsp;   管理、查看或删除您的个人信息</div>
              <div class="text1">第七章 &nbsp;&nbsp;&nbsp;&nbsp;   本政策如何更新</div>
              <div class="text1">第八章 &nbsp;&nbsp;&nbsp;&nbsp;   投诉及建议</div>
              <div class="text1">第九章 &nbsp;&nbsp;&nbsp;&nbsp;   争议管辖</div>
              <div class="text1">第十章 &nbsp;&nbsp;&nbsp;&nbsp;   联系方式</div>

              <div class="mt10 bold">
                本《隐私协议》与您所使用的产品及/或服务息息相关，希望您在使用我司的产品及/服务前仔细阅读并确认您已充分理解本《隐私协议》项下的内容。在需要时，本应用希望您按照本《隐私协议》的指引做出您认为适当的选择。本《隐私协议》中涉及的相关技术词汇或术语，本应用尽量以简明扼要的表述，以便您更好地理解。
              </div>
              <div class="mt10 bold">
                第一章  本应用如何收集您的个人信息
              </div>
              <div class="text1">
                1.1    本应用收集您所使用的相关设备信息
当您使用本应用的产品及/或服务时，本应用将收集您所使用的相关设备信息，以便本应用为您提供持续稳定的网络支持（如提供GPRS服务等），同时有助于本应用不断改善自身产品及/或服务，从而使您在使用本应用的产品及/或服务过程中获得最优体验。本应用收集到您所使用的相关个人信息，包括SIM卡码号信息、设备名称、设备型号、您的邮箱账号等。

              </div>
              <div class="text1">
                1.2    本应用通过调用设备权限收集您的相关个人信息
              </div>
              <div class="text1">
                1.3    您充分知晓，在以下情形下，我们收集、使用个人信息无需征得您的授权同意：
              </div>
              <div class="text1">
                <p class="text2">1)	与国家安全、国防安全有关的；</p>
                <p class="text2">2)	与公共安全、公共卫生、重大公共利益有关的；</p>
                <p class="text2">3)	与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                <p class="text2">4)	出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</p>
                <p class="text2">5)	所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
                <p class="text2">6)	从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道</p>
                <p class="text2">7)	根据您的要求签订合同所必需的；</p>
                <p class="text2">8)	用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；</p>
                <p class="text2">9)	法律法规规定的其他情形。</p>
              </div>

              <div class="mt10 bold">
                第二章     本应用如何使用您的个人信息
              </div>
              <div>在收集您的个人信息后，本应用将根据如下规则使用您的个人信息：</div>
              <div class="text1">2.1    本应用会根据本《隐私协议》的约定并为实现本应用的产品及/或服务功能对所收集的个人信息进行使用。</div>
              <div class="text1">2.2   请您注意，对于您在使用本应用的产品及/或服务时所提供的所有个人信息，除非您删除或通过相关设置拒绝我们收集，否则您将在使用产品及/或服务期间持续授权本应用使用。在您注销账号时，本应用将停止使用并对您的个人信息进行删除或匿名化处理。</div>
              <div class="text1">2.3    本应用会对旗下产品及/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示本应用产品及/或服务的整体使用趋势。但这些统计信息不会包含您的任何身份识别信息。</div>
              <div class="text1">2.4    当本应用要将您的个人信息用于本政策未载明的其它用途时，或将基于特定目的收集而来的信息用于其他目的时，本应用会主动事先征求您的明示同意。</div>
            
              <div class="mt10 bold">第三章     本应用如何共享、转让、公开披露您的个人信息</div>
              <div>共享</div>
              <div class="text1">3.1    本应用将严格遵守相关法律法规，对您的个人信息予以保密。除以下情况外，我们不会向其他人共享您的个人信息：</div>
              <div  class="text1">
                <p class="text2">1)	事先获得您明确的同意或授权；</p>
                <p class="text2">2)	根据适用的法律法规规定，或基于司法或行政主管等部门的强制性要求进行提供；</p>
                <p class="text2">3)	在法律法规允许的范围内，为维护您或其他本应用用户或其他个人的生命、财产等合法权益或是社会公共利益而有必要提供；</p>
                <p class="text2">4)	根据与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定而提供；</p>
                <p class="text2">5)	与本《隐私协议》第一章所述第三方进行共享：您在使用本应用产品及/或服务的过程中使用本《隐私协议》第一章所述的第三方服务时，本应用与第三方的共享规则将遵循本《隐私协议》第一章项下相关约定；</p>
                <p class="text2">6)	本应用可能会基于您的相应授权将您的个人信息与本应用的关联方共享。但本应用只会共享必要的个人信息，且受本《隐私协议》所述目的之约束。本应用的关联方如要改变个人信息的处理目的，将适时向您征得明示同意。</p>
              </div>
              <div class="text1">
                3.2    对本应用与之共享个人信息的公司、组织和个人，本应用将尽商业上合理地要求其在处理您的个人信息时遵守相关法律法规，尽力要求其采取相关的保密和安全措施，以保障您的个人信息安全。
              </div>
              <div>
                转让
              </div>
              <div class="text1">3.3    本应用不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</div>
              <div class="text1">
                <p class="text2">1)	事先获得您明确的同意或授权</p>
                <p class="text2">2)	根据适用的法律法规、法律程序的要求、强制性的行政或司法要求而必须进行提供；</p>
                <p class="text2">3)	根据与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定而提供；</p>
                <p class="text2">4)	在涉及收购、兼并、破产清算、重组等变更时，如涉及到个人信息转让，本应用会要求新的持有您个人信息的公司或组织继续履行本《隐私协议》项下的责任和义务。如变更后的主体需变更个人信息使用目的，我们会要求其事先获得您的明示同意。</p>
              </div>
              <div>
                公开披露
              </div>
              <div class="text1">3.4    本应用仅会在以下情况下，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</div>
              <div class="text1">
                <p class="text2">1)	根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
                <p class="text2">2)	根据法律、法规的要求、行政或司法机关的强制性要求，我们可能会公开披露您的个人信息。当本应用收到上述披露请求时，本应用会依法要求请求方出具相关法律文件，如传票或调查函等。本应用会慎重审查每一个披露请求，以确保该等披露请求符合相关法律规定。在法律法规许可的前提下，本应用会对包含披露信息的⽂件进行加密保护。</p>
              </div>

              <div class="mt10 bold">第四章     本应用如何保护您的个人信息</div>
              <div class="text1">4.1    本应用非常重视个人信息安全，并会采取一切合理可行的措施，持续保护您的个人信息，以防其他人在未经授权的情况下访问、篡改或披露本应用收集的您的个人信息：</div>
              <div class="text1">
                <p class="text2">1)	本应用已采用符合行业标准的安全防护措施来保护您的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的移动设备与本应用产品及/或服务之间交换数据时进行SSL加密保护；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。</p>
                <p class="text2">2)	本应用仅允许有必要知晓的人员访问相关个人信息，并为此设置了严格的访问权限控制和监控机制。本应用同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被终止与本应用的相应法律关系</p>
              </div>
              <div class="text1">4.2    本应用会采取一切合理可行的措施，确保未收集无关的信息。</div>
              <div class="text1">4.3    请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入本应用的服务所用的系统和通讯网络，有可能因本应用可控范围外的因素而出现问题。</div>
              <div class="text1">4.4    在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您；难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。</div>

              <div class="mt10 bold">第五章     本应用如何保存您的个人信息</div>
              <div>保存期限</div>
              <div class="text1">5.1    在用户使用本应用产品及/或服务期间，我们会持续保存用户的个人信息。</div>
              <div class="text1">5.2    对于其他数据的保存期限，本应用承诺将是为了实现本应用产品及/或服务目的所必需的最短期限。在超出上述期限后，本应用会对您的相关信息进行删除或匿名化处理。</div>
              <div>保存地域</div>
              <div class="text1"> 5.3    您的个人信息将全部被存储于与您购买设备时所在的地理位置有关，见下表所示：</div>
              <div class="table">
                <table>
                <tr><td>购车所在区域</td><td>保存地域</td></tr>
                <tr>
                  <td>东南亚区域</td>
                  <td>新加坡</td>
                </tr>
                <tr>
                  <td>中东非区域</td>
                  <td>法国</td>
                </tr>
                <tr>
                  <td>南美洲区域</td>
                  <td>巴西</td>
                </tr>
                
              </table>
              </div>
              <div class="mt10 bold">第六章     管理、查看或删除您的个人信息</div>
              <div>本应用非常尊重您对自己的个人信息所享有的权利。我们保障您对个人信息所享有的访问、更正、删除、管理等权利。</div>
              <div>访问和更正您的个人信息</div>
              <div class="text1">6.1    除法律法规另有规定之外，您有权行使数据访问权。当您发现我们处理关于您的个人信息有错误或者您有其他修改、补充需求时，您也有权要求本应用或自行予以更正。您行使数据访问和更正权的方式包括但不限于：您的邮箱账号信息。</div>
              <div class="text1">6.2    在以下情形中，您可以向本应用提出删除个人信息的请求：</div>
              <div class="text1">
                <p class="text2">1)	如果本应用处理个人信息的行为违反相关的法律法规；</p>
                <p class="text2">2)	如果本应用收集、使用您的个人信息，却未征得您的同意；</p>
                <p class="text2">3)	如果本应用处理个人信息的行为违反了与您的约定；</p>
                <p class="text2">4)	如果您不再使用本应用的产品及/或服务，或者您注销了相关账号；</p>
                <p class="text2">5)	如果本应用不再为您提供产品及/或服务。</p>
              </div>
              <div class="text1">6.3    若本应用决定响应您的删除请求，本应用还将同时通知从本应用获得您的个人信息的实体，要求其及时删除，除⾮法律法规另有规定或这些实体已获得您的独立授权。</div>
              <div>撤回同意</div>
              <div class="text1">6.4    您有权向本应用撤回您此前作出的有关同意收集、使用您的个人信息的授权。当您撤回同意后，本应用将不再处理您的相关个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理活动。</div>
              <div class="text1">6.5    您可以通过删除相关个人信息的方式撤回您此前就特定个人信息而对本应用作出的同意授权。</div>
              <div>注销账号</div>
              <div class="text1">6.6    您有权随时注销您在本应用产品及/或服务中注册的账号。为保障账号及财产安全，您需要通过客户服务或本《隐私条款》第十章载明的联系方式向本应用提出您的账号注销请求。本应用将在与您核实相关信息后对您的请求作出响应。</div>
              <div class="text1">6.7    请您注意，注销本应用相关产品及/或服务账号是不可恢复的操作。在注销账号之后，本应用将停止为您提供产品及/或服务，并将删除或匿名化处理该账号项下的您的个人信息，除⾮法律法规另有规定。</div>
              <div>获取个人信息副本</div>
              <div class="text1">6.8    根据您的请求，本应用可以向您提供本应用持有的有关您的个人信息副本（如个人邮箱账号信息）。您可以通过客户服务或本《隐私条款》第十章载明的联系方式向本应用提出请求。</div>
              <div>约束信息系统自动决策</div>
              <div class="text1">6.9    在本应用仅依据信息系统、算法等在内的非人工自动决策机制做出决定，并且这些决定显著影响您的合法权益的情况下，您有权要求本应用做出解释，本应用也将提供适当的救济方式。</div>
              <div>响应您的上述请求</div>
              <div class="text1">6.10    如果您对本应用在以上列明的有关访问、更正、删除您的个人信息，以及撤回同意、注销账号、约束信息系统自动决策方法有任何疑问，您可以通过客户服务或本《隐私协议》第⼗章载明的联系方式与本应用联系。</div>
              <div class="text1">6.11    本应用已经建立申诉管理机制，包括跟踪流程等。为了保障账号及财产安全，本应用可能会与您核实相关信息。本应用将在收到您的反馈后尽快答复您的请求，最长不超过30天。若您对答复意见不满意，您可以再次通过客户服务进行申诉。</div>
              <div class="text1">6.12    对于您合理的请求，本应用原则上不收取费用，但对多次重复、超出合理限度的请求，本应用将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，本应用可能会予以拒绝。</div>
              <div class="text1">6.13    在以下情形下，我们可能无法响应您的请求：</div>
              <div class="text1">
                <p class="text2">1)	与国家安全、国防安全有关的；</p>
                <p class="text2">2)	与公共安全、公共卫生、重大公共利益有关的；</p>
                <p class="text2">3)	与犯罪侦查、起诉和审判等有关的；</p>
                <p class="text2">4)	有充分证据表明您存在主观恶意或滥用权利的；</p>
                <p class="text2">5)	响应您的请求将导致其他个人、组织的合法权益受到严重损害的；</p>
                <p class="text2">6)	涉及商业秘密的。</p>
              </div>
              <div class="mt10 bold">第七章     本政策如何更新</div>
              <div>7.1    如本应用产品及/或服务发生以下变化，本应用将及时对本《隐私协议》进行相应的修订：</div>
              <div class="text1">
                <p class="text2">1)	本应用产品及/或服务所涉业务功能发生变更，导致处理个人信息的目的、类型、使用方式发生变更；</p>
                <p class="text2">2)	您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
                <p class="text2">3)	本应用负责处理您的个人信息安全的部门的联络方式及投诉渠道发生变更；</p>
                <p class="text2">4)	发生其他可能影响用户个人信息安全或影响用户隐私权利的变更等。</p>
              </div>
              <div class="text1">
                7.2    《隐私协议》修订后本应用会在本应用产品及/或服务相关界面发布最新版本并以推送通知的方式告知用户，以便用户及时了解《隐私协议》的最新版本。
              </div>
              <div class="text1">7.3    未经您的明确同意，本应用不会削减您基于本《隐私协议》所享有的权利。</div>
              <div class="text1">7.4    如无特殊说明，修订后的《隐私协议》自公布之日起生效。</div>
              <div class="mt10 bold">第八章     投诉及建议</div>
              <div class="text1">8.1    您在使用本应用产品及/或服务的过程中，如果对本《隐私协议》有任何的疑问或建议，或您认为您的个人信息没有得到本《隐私协议》规定的保护，您可以通过客户服务或本《隐私条款》第十章载明的联系方式联系本应用，本应用将真诚地处理您的投诉及建议。</div>
              <div class="mt10 bold">第九章    争议管辖</div>
              <div class="text1">9.1    各方如因本协议发生争议而无法协商解决的，任何一方均可向我司所在地（中华人民共和国广东省东莞市南城区）人民法院提起诉讼解决。</div>
              <div class="mt10 bold">第十章    联系我们</div>
              <div class="text1">10.1   当您对本隐私协议有任何疑问、建议或需求，您可以将您的问题发送至gdybkf@yunbaitech.cn邮箱，我们的地址为中华人民共和国广东省东莞市南城街道中熙时代大厦18楼。在验证您的身份后，我们将会依据相关法律规定及时处理您的问题。</div>
            </div>
          </div>


          <div v-if="language == 2" style="font-size: 30px;padding: 240px 0;">
            Content Update...
          </div>
          <div v-if="language == 3" style="font-size: 30px;padding: 240px 0;">
            Conteúdo em atualização...
          </div>
          <div v-if="language == 4" style="font-size: 30px;padding: 240px 0;">
            Contenido en actualización...
          </div>
        </div>

      </div>

    </div>
    <el-footer style="">
      <t-foot></t-foot>
    </el-footer>
    <scroll-top></scroll-top>
  </div>
</template>

<script>
import vHead from "../components/head.vue";
import tFoot from "../components/footer.vue";
// import { Template } from 'webpack'
// import { getMySubMember } from '@/api/test'
export default {
  components: {
    vHead,
    tFoot,
  },
  data() {
    return {
      info: this.$route.query.info,
      language:1,
      dataInfo: [
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：车联网智慧运营管理物联网项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧运营管理服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：51000000元，合同期1年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2022年03月03日",
          TemplateID: "0",
        },
      ],
    };
  },
  mounted() {
    console.log("test", this.info);
    console.log(typeof this.info);
    console.log(this.info);
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/info" });
    },
    type(e){
      console.log(e)
      this.language = Number(e.target.value)
    }
  },
};
</script>

<style lang="scss" scoped>
.mgwhite{
  width: 1200px;
  margin: 0 auto;
  background: white;
}
.info {
  .base_head {
    height: 80px;
  }
}
.infoDetail {
  .msg_box {
    background: #ffffff;
    height: auto;
    display: block;
    padding-bottom: 60px;
    // height: calc(100vh - 140px);
    .content {
      background: #fff;
      height: auto;
      border-radius: 10px;
      padding: 50px 20px;
      box-sizing: border-box;
      position: relative;
      margin-top: 50px;
      .title{
        font-size: 34px;
        font-weight: 600;
      }

      h1 {
        font-size: 30px;
        font-family: Source Han Sans SC, Source Han Sans SC-Bold;
        font-weight: 600;
        text-align: center;
        color: #4d4d4d;
        line-height: 57px;
      }
      h4 {
        margin-top: 50px;
        text-align: left;
        font-size: 25px;
        padding-left: 50px;
        font-weight: 500;
      }
      ol {
        padding-left: 40px;
        margin-top: 30px;
        font-size: 16px;
        li {
          font-size: 20px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          text-align: left;
          color: #4d4d4d;
          line-height: 60px;
        }
      }
      .msg_foot {
        float: right;
        padding-right: 50px;
        h6 {
          font-size: 18px;
          font-family: Source Han Sans SC, Source Han Sans SC-Bold;
          text-align: right;
          color: #666666;
          line-height: 27px;
        }
      }
    }
  }
}
.nsv {
  margin-top: 120px;
  margin-left: 45px;
  position: relative;
}
:deep(.el-breadcrumb__inner) {
  color: #999999 !important;
}
.contents{
  text-align: left;
  padding-top: 40px;
  div{
    font-size: 18px;
    line-height: 32px;
  }
  p{
    font-size: 18px;
    line-height: 32px;
  }
  .table{
    margin: 0 auto;
    text-align: center;
    padding-left: 40px;
  }
  table{text-align: center;}
  tr,td{font-size: 18px;line-height: 32px;}
  table,td{border: 1px solid #000000;
    width:500px;
    text-align: center;
    height: 44px;
    line-height: 44px;
border-collapse: collapse;
}
  .text1{
    text-indent: 2em;
  }
  .text2{
    text-indent: 3em;
  }
  .mt10{margin-top: 10px;}
  .bold{
    font-weight: bold;
  }
}
</style>