import { render, staticRenderFns } from "./wisdom_park.vue?vue&type=template&id=35b2e5fa&scoped=true"
import script from "./wisdom_park.vue?vue&type=script&lang=js"
export * from "./wisdom_park.vue?vue&type=script&lang=js"
import style0 from "./wisdom_park.vue?vue&type=style&index=0&id=35b2e5fa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b2e5fa",
  null
  
)

export default component.exports