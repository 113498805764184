<template>
  <div class="article">
    <el-header>
      <v-head :active='0' :isBanner="false"></v-head>
    </el-header>
    <div class="details">
      <div class="container">
        <div class="nsv">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }" style="position: relative;top:1px;">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
              <a href="/" style="position: relative;top:1px;">新闻详情</a>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="content" v-if="showIndex == 0">
          <h1>行业动态 | 工地人员定位的出发点，以及工地定位的意义</h1>
          <h5>2020年12月14日 06:02</h5>
          <div class="line"></div>
          <p>
            随着我国的发展速度加快，针对基础建设一类的需求也日益增加。城市地铁、铁路施工单位改善了路线技术指标、缩短了路程和行车时间，提高了运营效益，国家不但加大施工单位的建设力度；然而施工单位建设造价高、运营管理相对复杂，所以各地对施工单位的建设都十分重视，不敢掉以轻心。
            这一类的大型的施工一旦出现责任事故都是严峻重大的，所以近几年社会各界以及政府单位对施工的安全越来越重视。那么对于施工单位方面应该如何提高安全监管落后的管理模式、从而实现智能化、现代化、信息化的转型升级呢？这成为了需要管理者去研究的重要课题。
          </p>
          <img src="../static/detail/new0.png" alt="">
          <p>
            工地定位考勤系统不仅仅是简单的上班考勤记录系统，还包括另外的功能，例如区域定位、安全预警、灾后急救、日常管理等，是目前国内技术领先、专业化设计的现场监测系统。通过该系统可以准确掌握现场人员的位置，以及施工设备的运动轨迹。一旦有突发情况发生时，救援人员可根据该系统的高精度定位来判断相关人员的位置情况，采取及时的救援措施，提高救援工作的效率。这个功能的完善大大提高工地安全性，进一步加强管理者对现场情况的控制。
          </p>
          <p>
            工作原理：有源射频卡：施工人员或工作人员随身携带，一般在施工人员的皮带或安全帽上。卡上有双向和单向的：单向的智能发送自身的ID号，双向的不但可以给监控中心发信息，监控中心也可以给每个施工人员发信息，同时双向卡有一个按钮和指示灯，可以在遇到蔚县的情况下按下紧急按钮，进行紧急呼救。一般项目如果是以考勤为主，建议使用单项卡；如果系统以考勤和定位为主，建议使用双向卡。例如：当施工单位里面出现特殊情况时，施工人员只要按一下按钮，监控中心可以给每个施工人员发信号，也可以群发，通过指示灯完成提示。
          </p>
          <p>
            读卡器：不管是在施工单位的内外都需要安装放置读卡器，至少在一个系统中需要2台的。如果时定位，则需要更多。具体要根据施工单位的情况进行安装。一般的原则时每隔150米安装一个，也可以在拐弯和一些特殊需要监控的区域进行安装。
          </p>
          <p>
            工地人员考勤系统的原理是区域定位，根据每个读卡器的位置进行定位的，因此定位的精度取决于阅读器的识别距离，阅读器的距离是可以通过软件进行调节的，因此在考虑定位精度的时候也要考虑读卡的距离和系统的整个成本，也就是说定位精度越高，读卡识别距离就近，成本就高。
          </p>
          <p>光纤转换器：把信号与光纤信号进行转化，扩充传输距离当传输距离超过600米的话就要用光纤转换器，通过光纤来传输数据。读卡终端通过转光纤，最后通过光纤将读到的数据信息传到电脑主机上。</p>
          <p>电脑主机：安装在施工单位口不远处的监控室里，电脑内装有考勤和定位软件，通过电脑对施工人员进行监控。它通过串口与终端连接。同时该电脑主机还控制LED屏的显示内容。</p>

        </div>
        <div class="content" v-if="showIndex == 1">
          <h1>华云神舟开放式IoT平台的组成</h1>
          <h5>2020年12月14日 06:02</h5>
          <div class="line"></div>
          <p>
            物联网IoT平台是通过各种传感技术、通讯手段，将传统物体与互联网进行连接。以实现远程监控、自动报警、远程控制、远程诊断、远程维护。进而实现管理、控制、营运一体化的网络。作为新一代的信息通信技术，实现人与人、人与物、物与物的信息互联。随着物联网(IoT)继续向企业领域和面向消费者的应用程序渗透，物联网有望转变我们的业务模式，甚至转变业务本身的性质。物联网不是单一的技术，而是很多技术相互作用的结果，因此它与行业和业务之间的相关性是高低不等的。华云神舟开放式IOT平台：开放式IoT平台是华云神舟自主研发的新一代基于物联网产品，主要面向智能家居、智慧社区、智慧园区和生活服务，综合运用物联网技术、边缘计算、大数据分析、自动化等技术手段，构建"互联网+设备"的智能化平台，建立设备与云端之间的双向通信，支持海量数据的收集、预测、告警和监控，通过分布式结构和多重数据保障机制进行大量的信息传输，从而在极短时间内为产品赋予联网智能化，真正达到万物互联。
          </p>
          <img src="../static/detail/new1.png" alt="">
          <p>
            IoT平台每一项业务都需要以独特的方式确定自身对未来的需求，即使是那些独占一个垂直市场的业务也不例外。唯一可能会得到共同认可的结论是，发展IoT平台将成为业务经营方式的组成部分，也将在可预见的未来成为业务增长方式的一部分。
          </p>
          <p> 物联网通讯：物联网是通过各种传感技术、通讯手段，将传统物体与互联网进行连接。</p>
          <p>数据监控：以实现远程监控、自动报警、远程控制、远程诊断、远程维护。</p>
          <p> 数据分析：作为新一代的信息通信技术，实现人与人、人与物、物与物的信息互联。</p>
          <p> 设备互联：进而实现管理、控制、营运一体化的网络。 </p>
          <p>设备接入--提供设备端标准SDK，快速实现连接设备上云，效率高。同时支持各类设备接入、异构网络设备接入、多环境下设备接入和多协议设备接入。</p>
          <p> 服务性能--具有千万级设备的长连接能力、十万级并发处理能力，架构支撑水平性扩展。</p>
          <p> 数据安全--提供多重防护，保障设备数据安全。设备认证保障设备安全与唯一性。 传输加密保障数据不被篡改。 </p>
          <p>平台稳定--服务可用性高达99.9%。去中心化，无单点依赖。拥有多数据中心支持。</p>
          <p>简单易用--一站式设备管理、实时监控设备场景，数据订阅实现三方产品无缝连接。可灵活简便地搭建复杂物联网应用。 </p>
          <p>设备管理：设备不是静止存在的。它们需要持续不断的管理、重新配置、更新和设置控制，基于IoT平台的远程访问对于它们的经济可行性来说至关重要。 </p>
          <p>
            “构建、部署、进化”式应用程序开发方法：基于强健的平台之上，应用程序的生命周期应该是开放的——随着时间的推移创建应用程序、推销应用程序和改进应用程序。这种“构建、部署、进化”式平台战略应该从一开始就启用，以便支持反馈回路和未来的应用程序功能迭代。
          </p>
          <p> 双向、灵活的连接：利用双向连接，可以增强对产品的控制、无缝交付更新并更快地对问题做出响应。</p>
          <p> 云端之外的备份：云计算带来了对整个行业生态系统进行扩展和集中控制的机会。但我们不能忽略最坏的情况：当网络出现故障或云提供商停业时，将会发生什么？如果无法及时从云端提取任务关键型产品和数据，将会造成灾难性的后果。
          </p>
          <p>数据分析：数据是物联网的生命线，也是其价值的重要源泉。行业级IoT平台应该提供一整套数据分析设施，以便获取这一价值，同时防止企业淹没在缺乏协调性的新信息洪流之中。</p>
          <p>
            协作和打破孤岛：物联网旨在打破阻碍不同部门和供应链不同层次之间进行交流的孤岛。通过连接局域网(LAN)系统，并且为沿价值链上下发送数据制定新的协议，行业参与者可以从任何地点查看一切信息，并通过一条管道来控制何时需要采取代理级的行动，从而变得更加敏捷、更易于做出反应。
          </p>
          <p> 可用性、可扩展性和可靠性：当企业尝试使用自产解决方案或与小市场、单一业务供应商合作时，可扩展性可能就会受损或丧失。作用于平台边缘的解决方案不会单独发挥作用。物联网的先天优势得不到转化，问题也可能会融入新平台中。
          </p>
          <p>
            易维护性：在当今的流动性工作环境中，系统协议应该能够为新的工作者团队接受和理解，这是十分重要的。IoT平台必须要接受转移特性方面的评估。平台必须组织有序、直观且具有交互性，以便在有新管理员和开发人员接手时确保无缝过渡。
          </p>
          <p>
            灵活性和网络无关性：企业可能需要一种IoT平台，用以在接下来几个季度保持竞争力。但是，这并不意味着所有设置一开始都处于理想的状态，也不意味着所有流程一开始就会得以简化。物联网技术有很多调整，等待着每个采用这一技术的行业去实施。
          </p>
          <p> 安全性和数据隐私：就物联网来说，可能没有哪一个方面会比安全传输数据和确保操作系统完整性引起更多的问题和关注。每一次网络扩展，甚至小到拥有IP的传感器或设备，都可能会随之出现新的安全漏洞。 </p>
          <p>
            总结：随着物联网的影响力不断扩大，它注定会引来无休止的分析，还有大肆宣传。跟上研究的步伐几乎与维护企业当前的技术设备一样富有挑战性。但是，很少有行业参与者能够笃定：抛开炒作因素，物联网的影响力已经形成，未来只会不断扩大。
          </p>
          <p> 企业可以持续专注于自身IoT平台的基本任务和功能，从而摆脱噪音的干扰。用一种条理分明的方法来梳理哪些是必要的、哪些是可取的，而哪些是多余的，这可以简化选择方法，更容易看清理想平台应有的结构。</p>

        </div>
        <div class="content" v-if="showIndex == 2">
          <h1>智慧农业大棚环境监控系统</h1>
          <h5>2020年12月14日 06:02</h5>
          <div class="line"></div>
          <p>一、方案概述
            智慧农业大棚环境监控系统基于传统服务器或云服务器平台和智能传感网络技术（2.4G、433M、GPRS、NB-IOT等），采用物联网智能网关、智能环境监测装置、智能测控装置、环境监测传感器等，实现对空气温湿度、土壤温湿度、光照、CO2浓度、土壤PH值、风速风向、雨量等大棚农作物的生长环境参数的实时采集、无线传输、监测和控制。
          </p>
          <img src="../static/detail/new2.png" alt="">
          <p>二、硬件介绍 系统包含物联网数据采集网关、智能转换器（触摸屏转接）、智能采集器、开关量输出模块、智能温湿度传感器、二氧化碳传感器、光照度传感器等硬件产品。</p>
          <p> 三、平台展示
            平台包含多种功能。读取读取智能网关、智能测控装置、智能环境监测装置等通过GPRS、NB-IOT上传的数据，生成历史数据库；对数据进行统计、分析，生成用户所需的各种报表、曲线、图形，技术指标；实时显示、发布各种环境参数数据等；自动推送通知、报警信息，提醒相关人员进行支援；PC、移动终端，依据访问权限，查询、浏览相关数据；通过PC或移动智能终端，对现场设备进行控制；支持百度地图和视频图像显示。
          </p>

        </div>
        <div class="content" v-if="showIndex == 3">
          <h1>物联网 (IoT) 安全市场</h1>
          <h5>2020年12月14日 06:02</h5>
          <div class="line"></div>
          <p>物联网 (IoT) 安全是企业安全需要处理的全新球类游戏。它涉及保护数百亿台设备，每台设备都代表组织系统的入口。</p>
          <p>
            然而，尽管物联网带来了明显的安全噩梦，但人们强烈希望充分利用其业务和管理潜力。例如，世界各国政府正在与物联网供应商合作，将连接的传感器和设备作为智慧城市项目的一部分。制造商在其运营中连接不同的系统，以提供更精细的流程、输出和产品。消费者也配备了物联网传感器，可以执行诸如提醒他们订购某些杂货之类的任务。连接设备数量的激增正在推动物联网创新。
          </p>
          <p> 物联网增加了潜在威胁根据物联网 (IoT) 安全产品市场报告，移动网络连接渗透率的上升大大增加了安全威胁和整体风险。这种感知到的威胁正在推动物联网 (IoT) 安全产品市场，从现在到 2030 年，该市场正以每年
            15% 的强劲速度增长。 </p>
          <p>“由于对智能汽车、智能电表和机器对机器 (M2M) 通信等互联设备的需求不断增加，对物联网的需求正在显着增加——这些因素正在增加对物联网 (IoT) 安全产品市场的需求， ”报告说。</p>
          <p> 具体到应用，智能电网部分预计将见证这十年中的最高增长率。智能电网的需求量很大，是美国能源部正在进行的效率计划的一部分，并得到公用事业、IT 供应商和广大消费者的支持。</p>
          <p>
            在许多方面，物联网安全产品的发展速度使整个物联网能够得到更广泛的推广。虽然在消费产品中安装智能传感器发生在安全保障措施之前，但企业市场更加谨慎和要求更高。物联网安全软件解决方案的成熟将深刻影响企业对M2M通信和车对车（V2V）通信的验收应用。
          </p>
          <p> 巨大的市场潜力</p>
          <p> 安全市场的优势是巨大的。毕竟，在 2017 年超过 1000 亿美元之后，全球整体物联网解决方案市场已经超过 2000 亿美元。据 Statista 称，到 2025 年，市场价值可能高达 1.6
            万亿美元。如果物联网安全甚至占据 5%，这对初创公司和传统安全公司来说都是一个巨大的福音。</p>
          <p> 然而，必须认识到，与传统的it安全相比，物联网需要不同的技术、程序、协议和标准。例如，在许多消费设备中没有双因素身份验证。想象一下，你必须登录你的冰箱，然后收到一条短信，并输入一个代码来获取橙汁。肯定是不可行的。
          </p>
          <p>
            类似地，在许多企业应用程序中，双因素身份验证可能会对车间生产率造成太多的障碍。也许首先进行了身份验证，然后用户就可以自由操作，而无需承担进一步的安全负担。这些细节必须在安全与功能的正确平衡的基础上随着时间的推移而发展。
          </p>
          <p>
            健康担忧以医疗保健为例。医疗保健行业是物联网无疑具有真正前景的另一个领域。但转向物联网以提高医疗设备的连接性带来了严峻的挑战。从好的方面来说，患者和提供者可以获得更高效的管理、更快的数据访问、更好的分析和更高水平的自动化。但是，必须采取足够的安全措施来阻止黑客入侵。此类措施必须保护患者数据和患者健康。但你最不想要的就是护士在一个人的生存受到威胁时紧急等待获得药物。同样，平衡是关键。
          </p>
          <p>物联网安全供应商有责任与医疗保健行业和一般 IT
            参与者合作，发展在现实世界中发挥最佳作用的规则和程序。对于许多人来说，这可能需要改变运营基础。传统上，供应商注意到一个问题并对其进行数月或数年的研究，然后将其推向市场。但物联网蓬勃发展的步伐和市场需求值得更密切的合作。当您进入高度专业化的应用领域时，例如医疗保健、汽车、电信或制造，IT
            和安全人员必须认识到一个基本要素：他们可能是作为一门通用学科的安全专家，但在涉及这些专业领域的运作模式。因此，他们必须与每个垂直领域的用户密切合作，以开发与持续运营相吻合的安全解决方案。</p>

        </div>
        <div class="content" v-if="showIndex == 4">
          <h1>NEC 电子标签解决方案简要介绍</h1>
          <h5>2020年12月14日 06:02</h5>
          <div class="line"></div>
          <p>
            对于与您朝夕相处、必不可缺的商品，您只有充分了解，才能尽情享用。如果每件商品都备有档案可供随时查询，那么即使是天真的白雪公主，也不会轻易被迷惑了。随着宽带的实现，自由联通的网络社会即将到来，作为基础技术的电子标签技术由此备受关注,当所有物品都带有IC芯片并与网络相连的时候,随时随地查询物品信息、进行物品管理的美好前景将轻松实现。
          </p>
          <p> 电子标签RFID（Radio Frequency Identification）是将存有信息的标记状或卡片状芯片附于人或物品上，用电波进行读取和识别的自动识别方法之一。
            芯片大小从几毫米到几厘米不等,分为"无源型"和"有源型"两种，" 无源型"是指受读写器发射的电波激发而工作的类型，而"有源型"是指由 内置电源向读写器发射电波的类型。将记录从价格到生产流程等在内大
            量信息的芯片贴在商品表面或埋入商品里,通过读取器来读取、更新信息。</p>
          <p>
            电子标签的主要特点是利用电波交换信息的非接触式操作，通过数据读取器即电波交换信息可同时读取多个IC芯片的信息，而且"无源型"从数厘米到1米多、"有源型"在数米的距离下均可进行读取；芯片容量更大，并可进行内容追加和更新；此外，可对移动对象进行读取，并有耐用、安全（防不当复制、篡改）等特点(请参考图释"电子标签芯片的特征")。
          </p>
          <p> “白雪公主”再也不会吃到来历不明的苹果 </p>
          <p>苹果在哪里、是谁、以何种方法生产的，及其它相关信息都被明确无误地记录在苹果芯片上,
            "白雪公主"可以很轻松地了解并最终得到最满意的那个苹果。如您所见，电子标签技术能代替商品条形码实现更有效的产品管理、提高安全性,此外，它还能通过与网络的连接，广泛应用于其它领域。 </p>
          <p>在医疗领域,如果在医疗器械、药品和患者身上设有芯片的话,可提高医疗管理效率、有效防止医疗事故的发生.尤其是将芯片贴在诸如使用过的注射器等医疗废弃物上,可有效构筑一个医疗废弃物的追踪系统,提高社会安全性。</p>
          <p> 在金融领域,如在纸币、有价证券或各种代金券里埋入芯片,通过网络即可判定其真伪,也可用于结算。 </p>
          <p>在汽车业，将芯片贴在汽车上,可记载行车里程,查询维修记录和车主信息, 防止各类数据的伪造篡改,并有效防盗，从而创建更便利、快捷、安全的购买、维修环境。</p>
          <p>
            在超市零售业,将带有芯片的商品放在手推车里就可自动进行结算，更有趣的是，如果您的冰箱能读取食品上的芯片信息,并连接上网,就可为您进行冰箱内的食品管理,让您在超市里就可对冰箱"库存"情况了如指掌,做出正确、及时的购买决定。
          </p>
          <p>在旅游业，如果您的行李带有芯片，您只需将其寄存机场后在目的地放心等待即可，行李会按照芯片内的资料提示被安然送达。 在不远的将来,数据读取器还可安装在手机上,让您在移动中都能读取芯片、确认信息。</p>
          <p>为“苹果”的生产提供综合的电子标签系统 </p>
          <p>除了将极大地改变我们的生活方式, 电子标签还将为商务活动带来更深远影响。 </p>
          <p>
            2005年伊始，世界最大的零售业主沃尔玛对其排名前100位的供应商提出在所有货品上贴加IC芯片的要求。电子标签技术在流通领域的运用,改善了欠货率、实现了检查产品作业的自动化,最终达到成本节约的目的。目前，其供应商的三分之二为中国企业,因此可以预见中国企业将很快进入使用IC芯片的时代。
          </p>
          <p>
            在制造业,将芯片贴在原材料、配件上,更容易进行材料及库存的管理,而且以往使用的纸张传票方式都可芯片化,以便于管理者宏观掌控、实现生产效率的提高。除生产之外,在采购、库存管理、售后等环节还可进一步扩大电子标签的使用,使整体效率、品质和CS都得到全面提高。
          </p>
          <p>
            另外,电子标签在图书馆、美术馆和博物馆等社会公共服务机构的应用,可提高服务的品质和效率.在日本图书馆导入融合IC芯片和图书馆管理系统的图书馆管理综合系统(请参考图释"综合图书管理系统").因导入IC芯片,可记载条形码从来无法记录的书名、作者等信息,通过跟电脑连接的读取器,只需5秒钟即可处理出租、归还的业务,还可快速处理新书入库、藏书确认等事宜，有效提高服务品质和效率。
          </p>
          <p>为了更好地利用IC芯片,与网络连接的综合性系统的构筑极其重要.电子标签系统通常由芯片、天线、数据读取器、信息录入系统、信息后台处理系统等部分组成(请参考"电子标签系统的构成图").
            信息后台处理系统的作用是读取ID、查询ID数据、随时通过网络交换大量信息.而如何在网络上管理大量数据,就成为重要课题，中间件及后台处理系统正是其答案。中间件可区别整理多个生产商的多种芯片信息,传递给后台处理系统及生产管理系统,从而实现生产效率化。
          </p>
          <p>
            凭借中间件及网络构筑方面的技术优势，NEC开发了从芯片、标签到数据读取器的综合电子标签解决方案，加之NEC丰富的行业实践经验和信息系统的雄厚实力、在日本广受赞誉的应用业绩,我们完全能够为您提供高附加值的电子标签解决方案。
          </p>
          <p> NEC通过创新技术提供更佳解决方案,让商务更高效、生活更丰富！</p>


        </div>
        <div class="content" v-if="showIndex == 5">
          <h1>智慧工厂人员定位系统解决方案案例详解</h1>
          <h5>2020年12月14日 06:02</h5>
          <div class="line"></div>
          <p>
            智慧工厂是在数字化工厂的基础上，利用物联网的技术和设备监控技术加强信息管理和服务，EHIGH恒高利用高精度位置感知技术面向不同类型工业企业提供位置信息服务，以UWB定位技术为核心，针对工厂生产要素，搭建安全生产整体解决方案，为各大企业打造工厂人员定位系统，配合数字孪生技术打造了三维可视化安全管控平台，为其安全生产提供有效解决方案。
          </p>
          <p>工厂企业现存问题和管理痛点</p>
          <p> 1.重要设备参数可视化程度不高，与现场设备关联性较弱。</p>
          <p> 2.厂区人员成分复杂，员工作业安全及规范化缺乏有效管控。 </p>
          <p>3.厂区设备众多，设备维护困难，设备维护消耗资金大，且设备隐患风险及故障处理无有效手段。</p>
          <p> 4.各类系统相互独立成信息孤岛，管理人员在使用各类系统时，各部门重复操作，效率低下。工厂人员定位系统解决方案
            为了更好的适应新时期市场需求，促进工厂企业智能化发展，信息技术与生产流程的深度融合，打造信息化生产管理体系，提升信息化应用水平，从而提高经营效率、强化核心竞争力。</p>
          <p> 根据现场环境状况，EHIGH恒高基于UWB定位技术，通过在生产区域安装防爆定位基站和为作业人员配备防爆定位标签，为该公司打造化工厂人员定位系统，协助其实现人员在岗在位管理。</p>
          <p>同时，利用定位系统的兼容性，与工厂园区现有的视频系统、门禁系统、广播系统等进行对接，方便管理者对作业现场实现远程可视化监管，保障企业生产安全。 </p>
          <p>工厂人员定位系统硬件配置 </p>
          <p>EHIGH恒高自主研发的基于低功耗技术的UWB定位设备：防爆工牌标签，本安型定位基站，本安型电源供电，光纤级联方式回传数据。特点如下：</p>
          <p> 1.专业防爆：具有Ex ib IIC T4 Gb防爆等级； </p>
          <p>2.防水防尘：IP65防水级别； </p>
          <p>3.低功耗、发射功率可配置；</p>
          <p> 4.部署简单，精度高。</p>
          <p>可实现功能： </p>
          <p>保密区域隔离：对指定保密区域进行防护，非授权人员进入在管理平台进行告警并推送消息； </p>
          <p>资产防护：贵重资产安装标签，当离开规定区域时报警提示； </p>
          <p>厂商与访客区域管控：外部人员赋予一定的权限，对于进入无权限的区域，系统进行报警； </p>
          <p>车辆区域管控：车辆权限管理，对进入管控区的车辆进行控制； </p>
          <p>人员状态监测系统：系统根据定位报警源或者对接系统的报警源，做出不同的应急响应，如报警提示、逃生通知、联动消防系统报警等；</p>
          <p> 视频管理系统：解决视频在系统平台上实现对监控画面的实时播放、回放、嵌入、控制； </p>
          <p>物资定位与管理：实时定位物资和载具的位置，统计物资在库分布情况，快速查找物资。 </p>
          <p>传统工业数字智慧升级的背后是各类科技技术的快速发展和成熟应用，想要建立安全、高效、灵活的智慧工厂，EHIGH恒高工厂人员定位系统推荐智慧。</p>
          <p>工厂布局优化和技术升级，建立完善安全管理体系以及风险预警机制，集人员定位管理、车辆定位管理以及物资设备定位管理为一体，有效解决传统工厂痛点，推进智慧工厂的建立。</p>


        </div>
      </div>
    </div>



    <el-footer style="background: rgb(244, 246, 249);">
      <t-foot></t-foot>
    </el-footer>
    <!-- <scroll-top></scroll-top> -->
  </div>
</template>

<script>
import vHead from '../components/head.vue'
// import tFoot from '../components/text_footer.vue'
import tFoot from '../components/footer.vue'
export default {
  components: {
    vHead, tFoot
  },
  data() {
    return {
      showIndex: this.$route.query.id
    }
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/home' })
    }
  }
}
</script>

<style lang="scss" scoped>
.article {
  .base_head {
    height: 60px;
  }
}

.details {
  background: #f7f9fc;
  padding-bottom: 100px;
  padding-top: 50px;
}

.container {
  width: 1200px;
  background: #ffffff;
  .nsv {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    color: #999999;
    height: 60px;
    margin-left: 50px;
    img {
      width: 18px;
      height: 18px;
      cursor: pointer;
      vertical-align: sub;
      margin-right: 10px;
      position: relative;
      top: 1px;
    }

    .el-breadcrumb {
      margin-top: 3px;
    }
  }

  .content {
    background: #ffffff;
    border-radius: 0px;
    box-shadow: none;
    padding: 50px;
    padding-top: 0 !important;

    h1 {
      font-size: 32px;
      font-family: Source Han Sans SC, Source Han Sans SC-Bold;
      font-weight: 700;
      text-align: left;
      color: #4d4d4d;
      line-height: 57px;
    }

    h5 {
      font-size: 17px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 500;
      text-align: left;
      color: #999999;
      line-height: 57px;
    }

    .line {
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
    }

    p {
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: left;
      color: #4d4d4d;
      line-height: 32px;
      text-indent: 2em;
      margin-bottom: 30px;
    }

    img {
      width: 100%;
      height: 600px;
    }

    h6 {
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      line-height: 40px;
    }
  }
}
:deep(.el-breadcrumb__inner){
  color: #999999 !important;
}
:deep(.el-breadcrumb__inner){
  position: relative;
  top:-2px;
}
:deep(.el-breadcrumb__separator){
  position: relative;
  top: -2px;
}

</style>