<template>
  <div class="wisdom_park">
    <el-container>
      <el-header>
        <v-head :active="10"  :isBanner="false" ></v-head>
      </el-header>
      <el-main class="main-bottom">
        <div class="top_bg">
            <div class="top_text_mc">
                <div class="top_text_box">
                    <div class="top_text_tit">车联网解决方案</div>
                    <div class="top_text_info">
                        为车主提供更好的出行服务体验，增加产品竞争力。提供满足车联应用的云计算、大数据、人工智能、物联网等云服务，助力车企打造智能网联汽车，让客户享受人车智能生活。
                    </div>
                    <div class="top_text_btn" @click="isShowFn">获取专属方案</div>
                </div>
            </div>
        </div>

       <div class="bg1">
            <div class="max_tit">平台优势</div>
            <div class="cont">
                <div class="cont_flex">
                    <div class="list" v-for="(item,index) in dataList" :key="index">
                        <img :src="item.icon" class="icon">
                        <div>{{item.text}}</div>
                        <img :src="item.img" class="img">
                    </div>
                </div>            
            </div>
       </div>

       <div class="bg2">
            <div class="max_tit">平台介绍</div>
            <div class="min_tit">国内外的MNO开发经验，成功对接海外运营商，提供全球车联网服务能力。</div>
            <div class="cont">
                <div class="cont_flex">
                    <div>
                        <img src="@img/InsidePages/ic_home_fw_map.png" alt="">
                    </div>
                    <div class="rights">
                        <img src="@img/InsidePages/ic_home_pt_gy_yj.png" alt="">
                        <div>
                            定制开发国际IoT服务平台，支持美国、加拿大、巴西、日本、澳大利亚等几十个世界主要国家和地区的跨国设备跟踪、监测，提供流量消耗查询、订单管理、到期时间、充值续费以及预警提醒等功能。
                        </div>
                    </div>
                </div>
            </div>
       </div>

        <div class="bg1">
            <div class="max_tit">定制方案</div>
            <div class="min_tit">贴合车企需求开发 更高效、更人性化的实名制工具，满足车企和工信部的管理要求。</div>
            <div class="cont">
                <div class="cont_flex">
                    <img src="@img/InsidePages/img_ny_clw_dz.png" style="margin-top: 45px;">
                    <!-- <div class="lists">
                        <img src="@img/InsidePages/tab1.png">
                    </div>
                    <div class="puls">+</div>
                    <div class="lists">
                        <img src="@img/InsidePages/tab2.png">
                    </div>
                    <div class="puls">+</div>
                    <div class="lists">
                        <img src="@img/InsidePages/tab3.png">
                    </div> -->
                </div>            
            </div>
        </div>
      </el-main>

      <planDialog :isShow="isShow" v-if="isShow" @closeTop="closeTop">
      </planDialog>
      <!-- <scroll-top></scroll-top> -->
      <el-footer style="background: rgb(244, 246, 249);">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import vHead from "../../components/head.vue";
import vFooter from "../../components/footer.vue";
import planDialog from "../../components/planDialog.vue";
export default {
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      isShow: false,
      activeName: "first",
      bannerData: [
        {
          title: "云百工业数字化平台",
          content:
            "帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
          button: "获取专属方案",
          img: require("@img/plan/gy_img_banner_a_r.png"),
          banner: "banners banners1",
        },
      ],
      dataList:[
        {
            icon:require("@img/InsidePages/icon1.png"),
            text:'车联网方案定制能力',
            img:require("@img/InsidePages/no1.png")
        },
        {
            icon:require("@img/InsidePages/icon2.png"),
            text:'故障预案能力',
            img:require("@img/InsidePages/no2.png")
        },
        {
            icon:require("@img/InsidePages/icon3.png"),
            text:'快速排障和处理能力',
            img:require("@img/InsidePages/no3.png")
        },
        {
            icon:require("@img/InsidePages/icon4.png"),
            text:'7*24小时售后服务',
            img:require("@img/InsidePages/no4.png")
        },
        {
            icon:require("@img/InsidePages/icon5.png"),
            text:'车企和运营商驻点支撑',
            img:require("@img/InsidePages/no5.png")
        }
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    isShowFn(){
        this.isShow = true;
    },
    closeTop(){
         this.isShow = false;
    }
  },
};
</script>

<style scoped lang="scss">
    .main-bottom{
        padding-top: 80px;
        .top_bg {
            // width: 1920px;
            height: 460px;
            background: url("../../../public/img/home/banner/banner5.png");
            background-repeat: no-repeat;
            background-size: cover;
            .top_text_mc{
                width: 1200px;
                margin: 0 auto;
                .top_text_box{
                    width: 502px;
                    height: 229px;
                    border-radius: 12px;
                    backdrop-filter: blur(4px);
                    padding: 38px 28px;
                    color: #fff;
                    position: relative;
                    top: 74px;
                    background: rgba(0,0,0,.2);
                    .top_text_tit{
                        font-size: 36px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 30px;
                    }
                    .top_text_info{
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        line-height: 26px;
                    }
                    .top_text_btn{
                        width: 124px;
                        height: 44px;
                        line-height: 44px;
                        border: 1px solid #ffffff;
                        border-radius: 4px;
                        position: absolute;
                        bottom: 20px;
                        cursor: pointer;
                    }
                }
            }
           
        }
        .max_tit{
            width: 100%;
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #141414;
            text-align: center;
            padding: 68px 0 48px 0;
        }
        .min_tit{
            font-size: 24px;
            font-family: MiSans, MiSans-Medium;
            font-weight: 500;
            text-align: center;
            color: #323232;
            letter-spacing: 0.72px;
            width: 100%;
        }
        .cont{
            width: 1200px;
            margin: 0 auto;
            .cont_flex{
                display: flex;
                justify-content: space-between;
                .list{
                    width: 212px;
                    height: 323px;
                    background: #ffffff;
                    border: 1px solid #0063e6;
                    border-radius: 7px;
                    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
   
                    .icon{
                        width: 49px;
                        height: 43px;
                        margin-top: 44px;
                        margin-bottom: 34px;
                    }
                    div{
                        width: 182px;
                        height: 50px;
                        font-size: 22px;
                        font-family: MiSans, MiSans-Medium;
                        font-weight: 500;
                        text-align: center;
                        color: #141414;
                        letter-spacing: 0.22px; 
                        text-align: center;
                        margin: 0 auto;margin-bottom: 34px;
                    }
                    .img{
                        width: 82px;
                        height: 82px;
                    }
                }
                .lists{
                   margin-top: 45px; 
                }
                .list:hover{
                    // box-shadow: 5px 5px 5px #8f8e8e;
                    box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
                    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
                }
            }
        }
    }
.bg2{
    background: #f4f6f9;
    margin-top: 68px;
    .cont{
        margin-top: 73px;
        padding-bottom: 63px;
        .rights{
            width: 385px;
            height: 484px;
            background: #ebeff5;
            border-radius: 8px;
            img{
                width: 326px;
                height: 237px;
                margin: 32px 29px;
            }
            div{
                width: 316px;
                height: 138px;
                font-size: 16px;
                font-family: MiSans, MiSans-Normal;
                font-weight: normal;
                text-align: center;
                color: #0063e6;
                line-height: 28px;
                letter-spacing: 0.48px;
                margin: 0 auto;
            }
        }
    }
}
.puls{
    font-size: 63px;
    font-family: MiSans, MiSans-Bold;
    font-weight: 700;
    text-align: center;
    color: #0063e6;
    line-height: 525px;
    letter-spacing: 1.89px;
    }
</style>
