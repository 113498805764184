const carts = {
	state: {
    active: 0
	},
	mutations: {
    SET_ACTIVE: (state, val) => {
      state.active = val
    }
	},
	actions: {
    
	}
} 

export default carts