<template>
  <div id="app">
    <transition name="fade">
      <router-view></router-view>   
    </transition>
    <!-- // 绑定app盒子 -->
  </div>
</template>

<script>
// import { isMobile } from "./utils/isMobile"
export default{
    name: 'App',
    created() {
      
    },
    mounted() {
      // isMobile()
    },
}
</script>

<style lang="scss">
#app {
  // font-family: 'ang';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  color: #161719;
}

</style>
