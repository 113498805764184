const TOKEN_KEY = '44f1cc5c-d322-4009-8f28-ef27fd7d2d7d'
const USER_KEY = 'mxg-msm-user' 
// 获取 token 
export function getToken() { 
	return localStorage.getItem(TOKEN_KEY) 
}
// 保存 token 
export function setToken(token) { 
	return localStorage.setItem(TOKEN_KEY, token) 
}
//移除用户信息 
export function removeToken() {
	localStorage.removeItem(TOKEN_KEY)
	localStorage.removeItem(USER_KEY) 
}
