import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '@/pages/home.vue'
import Serve from '@/pages/serve.vue'
import Details from '@/pages/details'
import IndusAuto from '@/pages/indusAuto'
import AboutUs from '@/pages/aboutUs'
import Solution from '@/pages/solution'
import Info from '@/pages/info'
import InfoDetail from '@/pages/infoDetail'
import wisdomPark from '@/pages/InsidePages/wisdom_park'
import carNetworking from '@/pages/InsidePages/carNetworking'
import warehousing from '@/pages/InsidePages/warehousing'
import farming from '@/pages/InsidePages/farming'
import coach from '@/pages/InsidePages/coach'
import collect from '@/pages/InsidePages/collect'
import port from '@/pages/InsidePages/port'
import information from '@/pages/information'
import Agreement from '@/pages/agreement'
import AgreementInfo from '@/pages/agreementInfo'
const routes= [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: Home
    },
    {
        path: '/serve',
        component: Serve
    },
    {
        path: '/indusAuto',
        component: IndusAuto
    },
    {
        path: '/details',
        component: Details
    },
    {
        path: '/infoDetail',
        component: InfoDetail
    },
    {
        path: '/information',
        component: information
    },
    {
        path: '/aboutUs',
        component: AboutUs
    },
    // {
    //     path: '/solution',
    //     component: Solution
    // },
    {
        path: '/test',
        component: () => import('@/pages/test')
    },
    {
        path: '/info',
        meta: {
            showFooter: true
        },
        component: Info,
        // children:[
            // {
            //     path: 'detail',
            //     name: 'detail',
            //     component: InfoDetail
            // }
        // ]
    },
    {
        path: '/agreement',
        component: Agreement
    },
    {
        path: '/agreementInfo',
        component: AgreementInfo
    },
    {
        path: '/InsidePages/wisdom_park',
        component: wisdomPark
    },
    {
        path: '/InsidePages/carNetworking',
        component: carNetworking
    },
    {
        path: '/InsidePages/warehousing',
        component: warehousing
    },
    {
        path: '/InsidePages/farming',
        component: farming
    },
    {
        path: '/InsidePages/coach',
        component: coach
    },
    {
        path: '/InsidePages/collect',
        component: collect
    },
    {
        path: '/InsidePages/port',
        component: port
    },
    // {
    //     path: '/info/detail',
    //     component: () => import('@/pages/infoDetail')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router