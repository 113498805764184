import fetch  from '@/servers/fetch.js' 

export const getList = () => fetch.get('/api/home');

export const getServe = () => fetch.get('/api/serve');

export const getIndus = () => fetch.get('/api/indus');

export const getSolution = () => fetch.get('/api/solution');

