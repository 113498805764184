<template>
  <div class="infoDetail">
    <el-header>
      <v-head :active="5" :isBanner="false"></v-head>
    </el-header>
    <div class="msg_box" style="background: #f7f9fc;">
      
      <div class="mgwhite">

        <div class="container flexs" style="height: 100px">
          <div class="nsv">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
              >
              <el-breadcrumb-item :to="{ path: '/info' }"
                >信息中心</el-breadcrumb-item
              >
              <el-breadcrumb-item>
                <a href="">信息详情</a>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>

        <div class="content container clearfix" >
          <h1>{{dataInfo[info].title}}</h1>
          <ol>
            <li>{{dataInfo[info].projectName}}</li>
            <li>{{dataInfo[info].projectContent}}</li>
            <li>{{dataInfo[info].numberName}}</li>
            <li>{{dataInfo[info].transaction}}</li>
            <div style="text-align: left" v-if="dataInfo[info].TemplateID == 0">
              <ol style="margin-top: -5px">{{dataInfo[info].suppliers}}</ol>
              <ol style="margin-top: 5px">{{dataInfo[info].amount}}</ol>
              <ol style="margin-top: 5px">{{dataInfo[info].address}}</ol>
            </div>
            <li>{{dataInfo[info].timeLimit}}</li>
          </ol>
          <div class="msg_foot">
            <h6>{{dataInfo[info].company}}</h6>
            <h6>{{dataInfo[info].time}}</h6>
          </div>
        </div>

      </div>

    </div>
    <el-footer style="">
      <t-foot></t-foot>
    </el-footer>
    <scroll-top></scroll-top>
  </div>
</template>

<script>
import vHead from "../components/head.vue";
import tFoot from "../components/footer.vue";
// import { Template } from 'webpack'
// import { getMySubMember } from '@/api/test'
export default {
  components: {
    vHead,
    tFoot,
  },
  data() {
    return {
      info: this.$route.query.info,
      dataInfo: [
        {
          title: "广东云百科技有限公司信号优化覆盖服务项目采购中标公告",
          projectName: "一、项目名称：广东云百科技有限公司信号优化覆盖服务项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+信号网络优化覆盖服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：17150000元，合同期5年，实际金额以建设为准。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2023年11月17日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司信号优化覆盖服务项目采购需求公告",
          projectName: "一、项目名称：广东云百科技有限公司信号优化覆盖服务项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+信号网络优化覆盖服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2023年11月15日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：车联网智慧运营管理物联网项目（第三期）",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧运营管理服务",
          numberName:'三．项目编码：ybS202300605',
          transaction: "四、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：49400000元，合同期1年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2023年06月15日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：车联网智慧运营管理物联网项目（第三期）",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧运营管理服务",
          numberName:'三．项目编码：ybS202300605',
          transaction: "四、供应商要求：基础运营商。",
          timeLimit:"五、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2023年06月09日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：车联网智慧运营管理物联网项目（第二期）",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧运营管理服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：24700000元，合同期1年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2023年04月18日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：车联网智慧运营管理物联网项目（第二期）",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧运营管理服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2023年04月11日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：车联网智慧运营管理高精定位项目",
          projectContent: "二、项目内容：提供用于车辆调度、无人驾驶、车载场景的厘米级精确定位。",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：15000000元，合同期5年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2023年04月07日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：车联网智慧运营管理高精定位项目",
          projectContent: "二、项目内容：提供用于车辆调度、无人驾驶、车载场景的厘米级精确定位。",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2023年03月22日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：车联网智慧运营管理物联网项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧运营管理服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：51000000元，合同期1年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2023年03月03日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：车联网智慧运营管理物联网项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧运营管理服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2023年02月27日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：车联网增值服务物联网项目（二期）",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网增值服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：17300000元，合同期1年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2022年11月29日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：车联网增值服务物联网项目（二期）",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网增值服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2022年11月25日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：信号覆盖及车联网增值服务物联网项目",
          projectContent: "二、项目内容：提供信号覆盖保障+物联网基础通道服务+车联网增值服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：38350000元，合同期1年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2022年11月21日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：信号覆盖及车联网增值服务物联网项目",
          projectContent: "二、项目内容：提供信号覆盖保障+物联网基础通道服务+车联网增值服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2022年11月14日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：车联网智慧连接管理物联网项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧连接管理物联网服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：36080000元，合同期1年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2022年09月21日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：车联网智慧连接管理物联网项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧连接管理物联网服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2022年9月14日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：车联网智慧监控管理物联网项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧监控管理服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：30409200元，合同期1年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2022年8月18日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：车联网智慧监控管理物联网项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧监控管理服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2022年8月15日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：广东云百科技有限公司充电桩项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧监控管理服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：3000000元，合同期5年。",
          address: "供应商地址：深圳市福田区莲花街道深南大道2010号中国移动深圳信息大厦",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2022年3月30日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：广东云百科技有限公司充电桩项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车联网智慧监控管理服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2022年3月23日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：云百车载视频AI智能测试和比对服务包项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车载视频AI智能测试和比对服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：8900000元，合同期1年。",
          address: "供应商地址：广东省东莞松山湖高新技术产业开发区科技九路12号",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2021年10月29日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：云百车载视频AI智能测试和比对服务包项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+车载视频AI智能测试和比对服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2021年10月20日",
          TemplateID: "1",
        },
        {
          title: "广东云百科技有限公司物联网项目采购中标公告",
          projectName: "一、项目名称：广东云百新能源车监测物联网赋能项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+新能源车监测管理服务",
          transaction: "三、成交信息：",
          suppliers: "成交供应商：中国移动通信集团广东有限公司深圳分公司。",
          amount: "成 交 金 额：9000000元，合同期1年。",
          address: "供应商地址：广东省东莞松山湖高新技术产业开发区科技九路12号",
          timeLimit: "四．公告期限：本需求发布日2个工作日内联系我司。",
          company: "广东云百科技有限公司",
          time: "2021年10月25日",
          TemplateID: "0",
        },
        {
          title: "广东云百科技有限公司物联网项目采购需求公告",
          projectName: "一、项目名称：广东云百新能源车监测物联网赋能项目",
          projectContent: "二、项目内容：提供物联网基础通道服务+新能源车监测管理服务",
          transaction: "三、供应商要求：基础运营商。",
          timeLimit:"四、需求期限：供应商需在本需求发布日2个工作日内到广东省东莞市南城街道南城区宏七路中熙时代大厦1810—1814谈判。",
          company: "广东云百科技有限公司",
          time: "2021年10月15日",
          TemplateID: "1",
        },
      ],
    };
  },
  mounted() {
    console.log("test", this.info);
    console.log(typeof this.info);
    console.log(this.info);
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/info" });
    },
  },
};
</script>

<style lang="scss" scoped>
.mgwhite{
  width: 1200px;
  margin: 0 auto;
  background: white;
}
.info {
  .base_head {
    height: 80px;
  }
}
.infoDetail {
  .msg_box {
    background: #ffffff;
    height: auto;
    display: block;
    padding-bottom: 60px;
    // height: calc(100vh - 140px);
    .content {
      background: #fff;
      height: auto;
      border-radius: 10px;
      padding: 50px 0;
      margin-top: 40px;
      box-sizing: border-box;
      position: relative;
      h1 {
        font-size: 30px;
        font-family: Source Han Sans SC, Source Han Sans SC-Bold;
        font-weight: 600;
        text-align: center;
        color: #4d4d4d;
        line-height: 57px;
      }
      h4 {
        margin-top: 50px;
        text-align: left;
        font-size: 25px;
        padding-left: 50px;
        font-weight: 500;
      }
      ol {
        padding-left: 40px;
        margin-top: 30px;
        font-size: 16px;
        li {
          font-size: 20px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          text-align: left;
          color: #4d4d4d;
          line-height: 60px;
        }
      }
      .msg_foot {
        float: right;
        padding-right: 50px;
        h6 {
          font-size: 18px;
          font-family: Source Han Sans SC, Source Han Sans SC-Bold;
          text-align: right;
          color: #666666;
          line-height: 27px;
        }
      }
    }
  }
}
.nsv {
  margin-top: 120px;
  margin-left: 45px;
}
:deep(.el-breadcrumb__inner) {
  color: #999999 !important;
}
</style>