<template>
  <div class="aboutUs">
    <el-container>
      <el-header>
        <v-head :active="3" :isGif="true" :bannerData="bannerData" :color="'#000'" :textColor="'#000'" indicator="none"></v-head>
      </el-header>
      <el-main style="overflow-x: hidden;">
        <img src="@img/about/gywm_ct.png" alt="">
        <div>
          <div class="level">
            <div
                class="head_title"
              >
                
                <div class="max_tit">公司简介</div>
              </div>
            <div class="container about-title">
              <div class="introduce">
                <h3>广东云百科技有限公司</h3>
                <div class="border"></div>
                <p>
                  广东云百科技有限公司，创立于2015年，主要以物联网技术为载体，依托自身的行业耕耘和研发能力，集物联网技术服务，云平台管理和搭建，工业数字化以及智能软硬件定制开发为一体，是一家以ToB提供完整解决方案为核心竞争力的物联网科技企业，并被认定为“高新企业”、“创新型中小企业”、”专精特新企业”及东莞市倍增企业，客户遍布在交通、金融、工业、新能源等各个行业。
                </p>
                <div class="imgs">
                  <div class="imgs_list">
                    <img src="@img/about/icon1.png" alt="">
                    <div class="imgs_list_r">
                      <div class="imgs_list_r_t">500<span>+</span></div>
                      <div class="imgs_list_r_b">服务客户</div>
                    </div>
                  </div>
                  <div class="imgs_list">
                    <img src="@img/about/icon2.png" alt="">
                    <div class="imgs_list_r">
                      <div class="imgs_list_r_t">50<span>+</span></div>
                      <div class="imgs_list_r_b">服务地区</div>
                    </div>
                  </div>
                  <div class="imgs_list">
                    <img src="@img/about/icon3.png" alt="">
                    <div class="imgs_list_r">
                      <div class="imgs_list_r_t">30<span>+</span></div>
                      <div class="imgs_list_r_b">覆盖行业</div>
                    </div>
                  </div>
                  <div class="imgs_list">
                    <img src="@img/about/icon4.png" alt="">
                    <div class="imgs_list_r">
                      <div class="imgs_list_r_t">100<span>%</span></div>
                      <div class="imgs_list_r_b">项目成功交付</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="introduce-img"></div>
              <ul>
                <li>
                  <img src="@img/about/about_img_b.png" />
                </li>
                <li>
                  <img src="@img/about/about_img_c.png" />
                </li>
                <li>
                  <img src="@img/about/about_img_d.png" />
                </li>
              </ul>
            </div>
          </div>

        <div class="level level_bg " >
          <div class="rgbbg
          ">
          <div class="level_flex">
            <div class="left flex_list">
              <img src="@img/about/icon5.png" alt="">
              <div>企业愿景和使命</div>
              <p>让科技为生活赋能，让信息为发展加速。</p>
            </div>
            <div class="right flex_list">
              <img src="@img/about/icon6.png" alt="">
              <div>企业经营理念</div>
              <p>稳健高效、管理透明、共赢发展、务实进取。</p>
            </div>
          </div>
        </div>
        </div>


          <div class="level top-fix" style="padding-bottom: 10px;">
            <div class="container">
              <div>
                <div class="max_tit">为什么选择云百？</div>
              </div>
              <div class="oddBox flexs">
                <div
                  v-for="(item, index) in oddsList"
                  :key="index"
                  class="oddItem bg-box"
                  @mouseenter.stop="mouseenter(index)"
                  @mouseleave.stop="mouseenter"
                 
                >
                  <div class="odd_img">
                    <img :src="item.img" alt />
                  </div>
                  <div class="odd_name">{{ item.name }}</div>
                  <div class="line"></div>
                  <div class="odd_title">{{ item.title }}</div>
                  <!-- <div class="lineB"></div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="level bg_pic2 aptitude_bg">
            <div class="containers">
              <div class="head_title">
                <div class="max_tit">荣誉资质（节选）</div>
              </div>
              <img src="@img/about/img_ab_zz_bg.png" class="bg_aptitude_bg">
              <div class="aptitude">
                <img src="@img/about/img_ab_zz_a.png" alt="">
                <img src="@img/about/img_ab_zz_b.png" alt="">
                <img src="@img/about/img_ab_zz_c.png" alt="">
              </div>
            </div>
          </div>

          <div class="level" style="padding-top: 68px;">
            <div class="container">
              <div
                class="head_title"
              >
                <div class="max_tit">联系我们</div>
              </div>
              <div class="maps">
                <div id="allmap" style="width: 1200px; height: 517px;" ref="allmap"></div>
                <div class="map_text">
                  <div class="map_text_cont">
                    <div class="title">
                      广东云百科技有限公司
                    </div>
                    <div class="line"></div>
                    <div class="address_tel">
                      <img src="@img/component/ic_a_fwrx.png" alt="">
                      <span>0769 - 89008900</span>
                    </div>
                    <div class="address_tel" style="margin-top: 15.5px">
                      <img src="@img/component/ic_a_gsdz.png" alt="">
                      <span>广东省东莞市南城区中熙时代大厦18楼</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </el-main>
      <el-footer style="background:#f4f6f9;">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
    <!-- <about-box></about-box> -->
  </div>
</template>

<script>
import vHead from '../components/head.vue'
import vFooter from '../components/footer.vue'
export default {
  components: {
    vHead,
    vFooter
  },
  data() {
    return {
      oddActive: '',
      selectIndex: 1,
      bannerData: [
        {
          title: "关于我们",
          content: "提供完整解决方案为核心竞争力的物联网科技企业，致力于物联网技术服务，云平台管理和搭建，工业数字化以及智能软硬件定制开发。",
          button: "获取专属方案",
          img: require('@img/about/img_ab_banner_ys.png'),
          banner: 'about_bg'
        },

      ],
      oddsList: [
        {
          img: require('@img/home/ic_home_c_a.png'),
          name: '团队优势',
          title: '核心团队成员主要来自于三大运营商、华为、各大高校、科研机构等。'
        },
        {
          img: require('@img/home/ic_home_c_b.png'),
          name: '技术优势',
          title: '与中科院软件所形成战略合作关系，共同创新技术，结合中科院组建云百独特的物联网、工业数字化及软硬件开发技术团队。'
        },
        {
          img: require('@img/home/ic_home_c_c.png'),
          name: '行业优势',
          title: '与三大运营商、知名科研机构、国内主流车企和大型工业制造企业都保持紧密的合作，拥有丰富的车联网、工业数字化行业经验。'
        },
        {
          img: require('@img/home/ic_home_c_d.png'),
          name: '平台优势',
          title: '自主研发的智能管理平台，为客户的物联网、工业数字化、软硬件定制等业务提供专业智能的平台服务。'
        }
      ],


    }
  },
  methods: {
    mouseenter(i) {
      this.oddActive = i
    },
    baiduMap() {
      var data_info = [
        [
          113.733521,
          22.986059,
          // "<p><b>电话：</b>0769 - 89008900</p><p><b>地址：</b>广东省东莞市南城区中熙时代大厦1810-1814</p>",
          // "<img src='./img/about/logo.png' style='margin:0 auto;text-align:center;'>",
        ],
      ];
      var opts = {
        width: 120, // 信息窗口宽度
        height: 28, // 信息窗口高度
        title: "", // 信息窗口标题
        enableMessage: true, //设置允许信息窗发送短息
      };

      let map = new BMap.Map(this.$refs.allmap);
      map.centerAndZoom(new BMap.Point(113.732721, 22.986059), 19);

      map.enableScrollWheelZoom(); //启用滚轮放大缩小，默认禁用
      map.enableContinuousZoom(); //启用地图惯性拖拽，默认禁用

      function addMarket() {
        for (var i = 0; i < data_info.length; i++) {
          var marker = new BMap.Marker(
            new BMap.Point(data_info[i][0], data_info[i][1])
          ); // 创建标注
          var content = data_info[i][2];
          map.addOverlay(marker); // 将标注添加到地图中
          addClickHandler(content, marker);
          // map.openInfoWindow(new BMap.InfoWindow(content, opts), new BMap.Point(data_info[i][0], data_info[i][1]));
        }
      }
      addMarket();

      function addClickHandler(content, marker) {
        marker.addEventListener("click", function (e) {
          openInfo(content, e);
        });
      }
      function openInfo(content, e) {
        var p = e.target;
        var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
        var infoWindow = new BMap.InfoWindow(content, opts); // 创建信息窗口对象
        map.openInfoWindow(infoWindow, point); //开启信息窗口
      }

    }
  },
  mounted() {
    this.baiduMap()
  },
}
</script>

<style lang="scss" scoped>
.base_head {
  height: auto;
}
.aboutUs {
  .el-carousel__indicators {
    display: none;
  }

  .LOT-title {
    color: #fff;
    font-weight: 400;
    margin-top: 48px;
  }
  .LOT-in {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
  .apply-button {
    background: transparent;
    font-weight: 400;
    color: #ffffff;
    font-size: 16px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    margin-top: 0;
  }
  .about-title {
    display: flex;
    margin-top: 48px;
    justify-content: space-between;
    .introduce {
      width: 707px;
      height: 362px;
      background: linear-gradient(180deg,#f7f9fc, #fefefe);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 0px 4px 8px 0px #eff2f6; 
      h3 {
        font-size: 22px;
        font-weight: 400;
        text-align: left;
        color: #161719;
        margin: 22px 32px;
      }
      p {
        margin: 22px 32px;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        line-height: 30px;
       color: #6E747A;
      }
      .border {
        width: 637px;
        height: 1px;
        opacity: 0.4;
        background: #0051de;
        margin-left: 32px;
      }
      .imgs{
        display: flex;
        justify-content: space-between;
        margin: 0 44px 32px 32px;
        .imgs_list{
          background: #ffffff;
          padding: 12px 8px;
          width: 125px;
          background: #fcfcfc;
          border: 2px solid #ffffff;
          border-radius: 4px;
          box-shadow: 6px 6px 18px 0px rgba(0,55,128,0.08); 
          img{
            margin-right: 8px;
          }
          .imgs_list_r{
            display: inline-block;
            text-align: left;
            .imgs_list_r_t{
              font-size: 20px;
              font-weight: 700;
              color: #0051de;
              line-height: 20px;
              padding-top: 3px;
              span{
                font-size: 17px;
                font-weight: 400;
              }
            }
            .imgs_list_r_b{
              font-size: 12px;
              font-weight: 400;
              text-align: left;
              color: #161719;
              line-height: 13px;
              margin-top: 3px;
             }
          }
        }
      }
    }

    .introduce-img {
      width: 290px;
      height: 366px;
      background: url("../../public/img/about/about_img_a.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 4px;
      box-shadow: 0px 4px 8px 0px #eff2f6;
    }
    ul {
      display: flex;
      height: 366px;
      align-items: center;
      flex-flow: column;
      justify-content: space-between;
      li {
        width: 170px;
        height: 111px;
        img {
          width: 170px;
          height: 111px;
          border-radius: 4px;
          box-shadow: 0px 4px 8px 0px #eff2f6;
        }
      }
    }
  }

 .level_bg{
    padding:0;
    // background: url("../../public/img/about/img_gywm_wh_bg.png");
    background: #f0f5fc;
    background-size: 100%;
    .rgbbg{
      width: 100%;
      padding: 25px 0;
      margin: 0 auto;
      background: #f0f5fc;
    }
    .level_flex{
      width: 1200px;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      .flex_list{
        text-align: center;
        div{
          font-size: 28px;
          font-weight: 400;
          color: #141414;
          line-height: 31px;
          position: relative;
          bottom: 20px;
        }
        p{
          font-size: 16px;
          font-weight: 400;
          color: #141414;
          line-height: 31px;
          margin-bottom: 39px;
        }
      }
    }
  }


  .oddActive {
    background: #1e77ec;
    color: #ffffff;
    .odd_name,
    .odd_title {
      color: #ffffff;
    }
    .line {
      background: #ffffff;
    }
  }
  .container {
    .oddBox {
      margin-top: 32px;
    }
    .oddItem {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
      width: 276px;
      height: 283px;
      transition: all .5s;
      background: linear-gradient(180deg,#f2f6fc, #fcfcfc);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08); 
      .odd_img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          background-size: cover;
        }
      }
      .odd_name {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: #161719;
        letter-spacing: -1px;
        position: relative;
        top: -20px;
      }
      .odd_name::after{
        content: '';
        width: 30px;
        height: 4px;
        background: #0051de;
        position: absolute;
        bottom: -12px;
        left: 20px;
        border-radius: 2px;
      }
      .lineB {
        display: none;
        opacity: 1;
        position: absolute;
        background: #1e77ec;
        bottom: -4px;
        width: 246px;
        height: 2px;
      }
      .odd_title {
        font-size: 14px;
        text-align: center;
        color: #959da7;
        letter-spacing: 0px;
        padding: 0 32px;
        margin-top: 2px;
        line-height: 26px;
      }
    }
    .oddItem:hover{
      background: linear-gradient(180deg,#edf3fc, #fcfcfc);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.16); 
      transition: all .5s;
    }
    .oddActive {
      // border-bottom: 2px solid #1e77ec;
      background: linear-gradient(180deg, #edf2fa, #fefefe);
      box-shadow: 0px 5px 18px 0px #c7d6eb;
      
      .lineB {
        display: block;
        opacity: 1;
        position: absolute;
        background: #1e77ec;
        bottom: 0px;
        width: 246px;
        height: 2px;
      }
    }
  }
}
.aptitude_bg{

  .containers{
    position: relative;
    .bg_aptitude_bg{
      position: absolute;
      right:0;
      top: -50px;
      height: 1017px;
      z-index: 1;
    }
  }
}
.aptitude{
  width: 1393px;
  margin: 0 auto;
  height: 790px;
  margin-top: 48px;
  border-radius: 4px;
  position:inherit;
  z-index: 2;
  .aptitude_t{
    display: flex;
    justify-content: space-between;
    margin: 32px;
    .aptitude_t_list{
      width: 307px;
      .aptimg1{
        width: 280px;
        display: flex;
        justify-content: space-between;
        img{
          width: 88px;
          height: 127px;
        }
      }
      .aptimg2{
        width: 495px;
        display: flex;
        justify-content: space-between;
        img{
          width: 92px;
          height: 127px;
        }
      }
      .aptimg3{
        width: 256px;
        display: flex;
        justify-content: space-between;
        img{
          width: 120px;
          height: 166px;
        }
        div{
          width: 120px;
          text-align: center;
        }
      }
      .aptimg4{
        width: 509px;
        display: flex;
        justify-content: space-between;
        img{
          width: 95px;
          height: 134px;
        }
        div{
          width: 95px;
        }
      }
      .aptitude_t_list_title{
        text-align: left;
        background: #e5eeff;
        padding: 13px 16px;
        margin-bottom: 8px;
        b{font-size: 24px;font-weight: 700;
        text-align: right;
        color: #0051de;
        line-height: 20px;}
        i{
          font-size: 17px;
          font-family:'MiSans-Medium';
          text-align: left;
          color: #0051de;
          line-height: 20px;
          font-style:normal;
          vertical-align: super;
        }
        span{
          font-size: 14px;
          font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
          font-weight: 400;
          text-align: left;
          color: #0051de;
line-height: 13px;
        }
      }
     
    }
  }
  .aptitude_b{
    display: flex;
    margin: 32px;
    justify-content: space-between;
  }
}

#allmap{
  height: 517px;
  width: 1200px;
}
.maps{
  position: relative;
   margin-top: 48px;
}
.map_text{
  
  background: linear-gradient(180deg,#f4f6f9, #ffffff);
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 5px 5px 15px 0px #ebeff5; 
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: left;
  width: 276px;
  height: 171px;
  .map_text_cont{
     margin: 24px;
     .title{
        font-size: 16px;
        font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
        font-weight: 400;
        text-align: left;
        color: #161719;
        letter-spacing: 1.57px;
     }
     .line{
      width: 232px;height: 1px;
      background: #cccaca;
      margin: 15.5px 0;
     }
    .address_tel{
      img{
        width: 18px;
        height: 18px;
        margin-right: 2px;
        display: inline-block;
      }
      span{
        width: 206px;
        font-size: 14px;
        font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 16px;
        display: inline-block;
      }
    }
  }
}
.mt8px{
  margin-top: 8px;
}
:deep(.el-header) {
    padding: 0;
    height: auto !important;
    overflow-x: hidden;
}

.bg_pic2{
  background: #f0f5fc;
  margin-top: 68px;
}
</style>