import { message } from 'element-ui'
//判断验证是否符合要求
export const validate =  function(rule, data, key){
	let bool=true;
	if(!rule[key].rule.test(data[key])){
			//提示信息
      message({
        message: rule[key].msg,
        type: 'error'
      })
			//取反
			bool=false;
			return false;
	}
	return bool;
}
