<template>
 <el-tooltip placement="top" content="回到顶部">
    <back-to-top
      :visibility-height="300"
      :back-position="0"
      transition-name="fade"
    />
    <!-- :custom-style="myBackToTopStyle" -->
  </el-tooltip> 
  
</template>
<script>
import BackToTop from '../../node_modules/element-ui/packages/backtop/index'
export default {
  name: "app",
  components: { BackToTop },
  data() {
    return {
      x: {
        marginTop: '139px',
        right: '15px',
        width: '40px',
        height: '40px',
        borderRadius: '4px',
      
        // background: '#e7eaf1'
        color: '#1a1a1a',
        display: 'flex',
        flexFlow: 'column',
        alignitems: 'center',
      }
    };
  }
};
</script>
<style>
/* .el-backtop{
  bottom: 120px!important;
} */
</style>