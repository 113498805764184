<template>
  <div class="wisdom_park">
    <el-container>
      <el-header>
        <v-head :active="10"  :isBanner="false" ></v-head>
      </el-header>
      <el-main class="main-bottom">
        <div class="top_bg">
            <div class="top_text_mc">
                <div class="top_text_box">
                    <div class="top_text_tit">智慧港口解决方案</div>
                    <div class="top_text_info">
                      项目为散装港口码头提供从0至1的智慧化转型方案，包含从货料运输、客户订购结算、货车装卸、货物监控、货车进出等全链路环节的软硬件开发服务，助力传统港口码头实现智能监管、智能服务、自动装卸、各信息系统数据打通，完成了机械化到智能化的提升。
                    </div>
                    <div class="top_text_btn" @click="isShowFn">获取方案</div>
                </div>
            </div>
        </div>

       <div class="bg1">
            <div class="max_tit">整体架构</div>
            <div class="cont">
               <img src="@img/InsidePages/5797.png" alt="">
            </div>
       </div>

        <div class="bg2">
            <div class="max_tit">一码通系统</div>
            <div class="cont">
              <div class="cont_flex">
                <div class="cont_l" style="margin-top: 0 !important;">
                    <img src="@img/InsidePages/5785.png" alt="">
                </div>
                <div class="cont_r">
                  <div>
                    <div class="cont_r_t">一码通排队系统：</div>
                    <div class="text">车辆到达码头、司机下车使用一码通扫码、系统识别IC卡身份、查询余额和类别、分配显示空闲仓位、通过闸机指引车辆进场。</div>
                  </div>
                  <div class="line"></div>
                  <div>
                    <div class="cont_r_t">一码通进仓提货系统：</div>
                    <div class="text">车辆到达指定仓位、工作人员使用一码通扫码、系统核实信息、读取车辆皮重、输入购买数量、确认散装头是否对准料口、开始装车。</div>
                  </div>
                </div>
              </div>
            </div>
       </div>
       <div class="bg1">
            <div class="max_tit">数据管控平台</div>
            <div class="cont">
               <img src="@img/InsidePages/gkpt.png" alt="">
            </div>
       </div>
      </el-main>

      <planDialog :isShow="isShow" v-if="isShow" @closeTop="closeTop">
      </planDialog>
      <!-- <scroll-top></scroll-top> -->
      <el-footer style="background: rgb(244, 246, 249);">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import vHead from "../../components/head.vue";
import vFooter from "../../components/footer.vue";
import planDialog from "../../components/planDialog.vue";
export default {
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      isShow: false,
      activeName: "first",
      bannerData: [
        {
          title: "云百工业数字化平台",
          content:
            "帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
          button: "获取专属方案",
          img: require("@img/plan/gy_img_banner_a_r.png"),
          banner: "banners banners1",
        },
      ],
      dataList:[
        {
            img:require("@img/InsidePages/image16.png")
        },
        {
            img:require("@img/InsidePages/image2.png")
        },
        {
            img:require("@img/InsidePages/image17.png")
        },
        {
            img:require("@img/InsidePages/image13.png")
        }
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    isShowFn(){
        this.isShow = true;
    },
    closeTop(){
         this.isShow = false;
    }
  },
};
</script>

<style scoped lang="scss">
    .main-bottom{
        padding-top: 80px;
        padding-bottom: 0 !important;
        .top_bg {
            // width: 1920px;
            height: 460px;
            background: url("../../../public/img/InsidePages/port.png");
            background-repeat: no-repeat;
            background-size: cover;
            .top_text_mc{
                width: 1200px;
                margin: 0 auto;
                .top_text_box{
                    width: 502px;
                    height: 229px;
                    border-radius: 12px;
                    backdrop-filter: blur(4px);
                    padding: 38px 28px;
                    color: #fff;
                    position: relative;
                    background: rgba(0,0,0,.2);
                    top: 74px;
                    .top_text_tit{
                        font-size: 36px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 20px;
                    }
                    .top_text_info{
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        line-height: 26px;
                    }
                    .top_text_btn{
                        width: 124px;
                        height: 44px;
                        line-height: 44px;
                        border: 1px solid #ffffff;
                        border-radius: 4px;
                        position: absolute;
                        bottom: 20px;
                        cursor: pointer;
                    }
                }
            }
           
        }
        .max_tit{
            width: 100%;
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #141414;
            text-align: center;
            padding: 68px 0 48px 0;
        }
        .min_tit{
            font-size: 24px;
            font-family: MiSans, MiSans-Medium;
            font-weight: 500;
            text-align: center;
            color: #323232;
            letter-spacing: 0.72px;
            width: 100%;
        }
        .cont{
            width: 1200px;
            margin: 0 auto;
            .cont_title{
                text-align: left;
                font-size: 18px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: left;
                color: #323232;
                line-height: 30px;
            }
            .cont_box{
                background: #f4f6f9;
                padding: 43px 34px 18px 34px;
                margin-top: 31px;
            }
            .cont_flex{
                display: flex;
                justify-content: space-between;
                .list{
                    width: 270px;
                    height: 161px;
                    margin-bottom: 30px;
                }
                .lists{
                   margin-top: 45px; 
                }
                .list:hover{
                    box-shadow: 5px 5px 5px #8f8e8e;
                }
            }
            .cont_box_b{
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: left;
                color: #141414;
                line-height: 26px;
            }
        }
    }
.bg2{
    background: #f4f6f9;
    margin-top: 68px;
    padding-bottom: 80px;
    .cont{
        padding-bottom: 63px;
        .rights{
            width: 385px;
            height: 484px;
            background: #ebeff5;
            border-radius: 8px;
            img{
                width: 326px;
                height: 237px;
                margin: 32px 29px;
            }
            div{
                width: 316px;
                height: 138px;
                font-size: 16px;
                font-family: MiSans, MiSans-Normal;
                font-weight: normal;
                text-align: center;
                color: #0063e6;
                line-height: 28px;
                letter-spacing: 0.48px;
                margin: 0 auto;
            }
        }
    }
}
 .triad-lunbo {
        height: 254px;
        width: 1200px;
        margin: 0 auto;
        background: linear-gradient(90deg, #30abeb, #1e77ec);
        margin-top: 13px;
        .triad-img {
          width: 339px;
          height: 247px;
          margin-top: -23px;
          margin-left: 30px;
        }
        h3 {
          color: #475669;
          font-size: 14px;
          opacity: 0.75;
          line-height: 150px;
          margin: 0;
          text-align: center;
        }
        .border {
          width: 572px;
          height: 1px;
          opacity: 0.4;
          background: #ffffff;
          margin-left: 39px;
          
        }
        h4 {
          font-size: 24px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin: 44px 40px 15px;
        }
        p {
          margin: 15px 40px 0;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 28px;
        }
      }
      .bg1{
        background: #fff;
      }
.platform{
    .cont{
        .cont_flex{
            display: flex;
            justify-content: space-between;
            .vs{
                width: 102px;
                height: 102px;
                background: #0063e6;
                border-radius: 50%;
                font-size: 56px;
                font-family: MiSans, MiSans-Regular;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
                text-align: center;
                line-height: 102px;
                margin-top: 330px;
            }
            .lists{
                background: #fff;
                img{
                    margin: 50px;
                }
            }
        }
    }
}
.cont_l{
  // border: 0.5px dashed #0063e6;
  margin-right: 16px;
}
.cont_r{
  // width: 351px;
  // height: 401px;
  padding: 30px 24px;
  border: 0.5px dashed #0063e6;
  text-align: left;
  .cont_r_t{
    font-size: 18px;
    font-family: MiSans, MiSans-Medium;
    font-weight: 500;
    color: #363636;
    line-height: 31px;
  }
  .text{
    font-size: 16px;
    font-family: MiSans, MiSans-Regular;
    font-weight: 400;
    text-align: left;
    color: #363636;
    line-height: 31px;
  }
  .line{
    width: 307px;
    height: 1px;
    background: #4e9aff;
    margin: 26px 0;
  }
}
</style>
