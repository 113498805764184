import { validate } from '@/utils/index'
// import { json } from 'express'
export default {
  data() {
    return {
      params: {
        realName: '',
        mobile: '',
        remark: ''
      },
      ruleData: {
        mobile: {
          rule: /^1[3456789]\d{9}$/,
          msg: '请输入正确手机号'
        }
      },
      slideShows:false
    }
  },
  methods: {
     submit() {
      let data = {
        realName: this.params.realName,
        mobile: this.params.mobile,
        remark: this.params.remark
      }
      localStorage.setItem("userData",JSON.stringify(data))
      if (this.params.realName === '') return this.$message({
        message: '请填写姓名',
        type: 'error'
      })
      if (this.params.realName.length > 40) return this.$message({
        message: '输入的姓名不能大于40个字符',
        type: 'error'
      })
      if (!validate(this.ruleData, this.params, 'mobile')) return
      if (this.params.remark === '') return this.$message({
        message: '请填写您的需求',
        type: 'error'
      })
      this.slideShows = true;
      this.loading = true;
    },
    async CustomManager(){
      let datas = localStorage.getItem("userData")
      let data = JSON.parse(datas)
      this.$http.post('https://oa.yunbaitech.cn:8820/bsapi/CustomManager/AddByWebSite.html',  data ).then(res => {
        console.log(res)
        this.slideShows = false;
        this.$emit('closeTop')
       
        if(res.data.code === 0){
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        }else if(res.data.code === 800){
          this.$message({
            message: '您已经提交过信息了，工作人员会尽快联系您哦',
            type: 'fail'
          })
        }else{
          this.$message({
            message: res.data.msg,
            type: 'fail'
          })
        }
        this.$refs.slideblock.reset();
        Object.assign(this.params, {
          realName: '',
          mobile: '',
          remark: ''
        })
        
      })
      this.loading = false
    },
    async aboutSubmit() {
      if (this.params.remark === '') return this.$message({
        message: '请填写公司名称',
        type: 'error'
      })
      if (this.params.realName === '') return this.$message({
        message: '请填写姓名',
        type: 'error'
      })
      if (!validate(this.ruleData, this.params, 'mobile')) return
      this.loading = true
      let { status } = await this.$http.post('https://oa.yunbaitech.cn:8820/bsapi/CustomManager/AddByWebSite.html', data )
      if (status) {
        this.$message({
          message: '提交成功',
          type: 'success'
        })
        Object.assign(this.params, {
          realName: '',
          mobile: '',
          remark: ''
        })
      }
      this.loading = false
    }
  }
}