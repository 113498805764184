import Vue from 'vue'
import App from './App';
import router from './router/index'
import Router from 'vue-router'

import axios from 'axios'  //引入axios
import About from '@/components/about.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// import MetaInfo from 'vue-meta-info'

// import '@/style/_variable.scss'
import '@/style/base.scss'

import '@/assets/css/font.css'; // 引入字体样式

import ScrollTop from '@/components/backToTop.vue'  // 回到顶部

import AOS from "aos"; // 滚动动画
import "../node_modules/aos/dist/aos.css";

import VueLazyload from 'vue-lazyload' // 懒加载
//判断是否在移动端

import SlideVerify from 'vue-monoplasty-slide-verify';

// Vue.use(MetaInfo)
Vue.use(VueLazyload)
Vue.use(SlideVerify)
// 配置项
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/logo_zw.png',
  loading: 'dist/logo_zw.png',
  attempt: 1
})

Vue.use(AOS)
Vue.use(ElementUI)//通过这个命令使所有的element-ui标签(<el-*></el-*>)可被解析
Vue.component('scroll-top', ScrollTop)
Vue.component('about-box', About)
Vue.config.productionTip = false
Vue.prototype.$http = axios

// 引入rem布局
import 'amfe-flexible'

axios.defaults.baseURL = '/wan' 

AOS.init({
  mirror:false,
  anchorPlacement:'top-top',
  once:true//是否仅触发一次
}) // 初始化aos动画

router.afterEach((to, from) => {
  // console.log(to, from)
  window.scrollTo(0,0);
})

// 对Router原型链上的push、replace方法进行重写(push和replace方法会返回一个promise)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//去掉console.log
if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
  console.log = function () {}
  console.error = function(){}
  console.dir = function(){}
}
new Vue({
  render: h => h(App),
  router,
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
