<template>
    <div class="adddialog">
        <el-dialog :visible.sync="dialogVisible" center @close="closeDialogs" :close-on-click-modal="false" :destroy-on-close="true">
            <div class="demo-dialog">
                <div class="block bgImg">
                    <!-- <el-image :src="src"></el-image> -->

                    <div class="centImg">
                        <div class="textBox">
                            <div class="context">
                                <h3>免费获取方案</h3>
                                <h5>提交您的需求，我们将会在24小时内联系您。</h5>
                                <div class="iptBox">
                                    <div class="left">
                                        <div class="b-item">
                                            <span class="text">填写姓名：</span>
                                            <el-input
                                                class="input-radius"
                                                clearable
                                                v-model="params.realName"
                                                placeholder="怎么称呼您呢"
                                            ></el-input>
                                        </div>
                                        <div class="b-item">
                                            <span class="text">填写手机号：</span>
                                            <el-input
                                                class="input-radius"
                                                type="tel"
                                                maxlength="11"
                                                clearable
                                                v-model="params.mobile"
                                                placeholder="我们将第一时间联系您"
                                            ></el-input>
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div class="b-item">
                                            <span class="text">备注：</span>
                                            <el-input
                                                class="input-radius"
                                                type="textarea"
                                                clearable
                                                :rows="7"
                                                placeholder="您有什么需求呢？"
                                                v-model="params.remark"
                                            ></el-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="submit" @click="submit" >
                                    <span>提交信息</span>
                                    <img src="@img/home/ic_xq_qd@2x.png" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <v-slides :slideShow.sync="slideShows" @submit="CustomManager" ></v-slides>
    </div>
</template>

<script>
import mixin from '@/mixin/index.js'
import vSlides from '../components/slide.vue'
export default {
    name: 'plandialog',
    mixins: [mixin],
    components: {
        vSlides
    },
    data() {
        return {
            dialogVisible: this.isShow,
            src: '',
            loading: false,
        }
    },
    props: {
        isShow: {
            type: Boolean,
            default: false
        },

    },
    mounted() {
        console.log(this.dialogVisible);
    },
    methods: {
        closeDialogs() {
            this.$emit('closeTop')
        }
    }

}
</script>

<style lang="scss">
.adddialog {
    .el-dialog {
        width: 834px;
        background: url("../../public/img/img_hqfa_bg.png") no-repeat;
        background-size: 100% 100%;
        border-radius: 0;
        box-shadow: none;
        position: relative;
        .el-dialog__header {
            padding: 0;
            position: absolute;
            right: 2px;
            z-index: 999;
        }
        .el-dialog__body {
            padding: 0;
        }
        .el-dialog__headerbtn {
            position: absolute;
            top: 40px;
            right: 55px;
            font-size: 15px;
            width: 24px;
            height: 24px;
            border: 1px solid #fff;
            border-radius: 50% 50%;
            .el-icon-close:before {
                content: "\e6db";
                color: #fff;
            }
        }
    }
    .demo-dialog {
        position: relative;
        height: 649px;
        .top-fix {
            margin-top: 0 !important;
        }
        .bgImg {
            display: inline-block;
            height: auto;
            width: 100%;
            position: absolute;
            height: 500px;
            left: 0;
            .bgImg_img {
                width: 100%;
                background-size: cover;
            }
        }
        .foots {
            position: absolute;
            bottom: 19px;
            color: #ffffff;
            width: 100%;
            text-align: center;
            h3 {
                font-size: 14px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                font-weight: 400;
                text-align: center;
                color: #7e8995;
                line-height: 24px;
            }
        }

        .centImg {
            .centImg_img {
                width: 100%;
                height: 100%;
                background-size: cover;
            }
            .textBox {
                position: absolute;
                top: 72px;
                left: 90px;
                h3 {
                    opacity: 1;
                    font-size: 32px;
                    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
                    font-weight: 700;
                    text-align: left;
                    color: #ffffff;
                    line-height: 31px;
                    margin: 0;
                }
                h5 {
                    opacity: 0.6;
                    margin: 0;
                    margin-top: 12px;
                    font-size: 16px;
                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: #ffffff;
                    line-height: 31px;
                }
                .iptBox {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 654px;
                    margin-top: 30px;
                    .b-item {
                        width: 300px;
                    }
                    .text {
                        font-size: 16px;
                        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        line-height: 20px;
                        display: block;
                        margin: 10px 0;
                    }
                    .top {
                        width: 100%;
                        .el-input__inner {
                            height: 64px;
                            font-size: 16px;
                        }
                    }
                    .bot {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                        // height: 250px;
                        .el-input__inner {
                            height: 64px;
                            font-size: 16px;
                        }
                    }
                    .left {
                        display: flex;
                        justify-content: space-between;
                        flex-flow: column;
                        height: 250px;
                        .el-input__inner {
                            height: 64px;
                            font-size: 16px;
                        }
                    }
                    .right {
                        height: 250px;
                        .el-textarea__inner {
                            padding-top: 10px;
                            font-size: 16px;
                            height: 210px;
                        }
                    }
                }
            }
            .input-radius input,
            .input-radius textarea {
                border-radius: 8px 0px 8px 8px;
            }
            .submit {
                width: 179px;
                height: 61px;
                opacity: 1;
                background: linear-gradient(90deg, #1cc1ff 0%, #1e77ec 99%);
                border-radius: 8px 0px 8px 8px;
                box-shadow: 0px 8px 8px 0px rgba(61, 179, 248, 0.19);
                margin-top: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                float: right;
                font-size: 16px;
                cursor: pointer;
                img {
                    width: 22px;
                    height: 8px;
                    opacity: 1;
                    margin-left: 5px;
                }
            }
        }
    }
}
</style>