<template>
  <div class="serve">
    <el-container>
      <el-header>
        <!-- <v-head :active='1' :bannerLists="bannerLists"></v-head> -->
        <v-head :active="1" :isBanner="true" :bannerData="bannerData" indicator="none"></v-head>
      </el-header>
      <el-main >
        <div class="level" style="padding-top: 18px; padding-bottom: 48px">
          <div class="container">
            <div class="head_title">
              <div class="max_tit">云百IoT核心竞争力</div>
            </div>
            <div class="oddBox flexs">
              <div class="outside" v-for="(item, index) in oddsList" :key="index">
                <div class="oddItem bg-box oddItem_front">
                  <img src="@img/img_iot_hx_ys.png"  class="bg_pic_outside">
                  <div class="odd_img">
                    <img :src="item.img" alt />
                  </div>
                  <div class="odd_name">{{ item.name }}</div>
                  <div class="oddLine"></div>
                  <div class="odd_title">{{ item.title }}</div>
                </div>
                <!-- <div class="oddItem bg-box oddItem_back">
                  <div class="odd_name">{{ item.name }}</div>
                  <div class="oddLine"></div>
                  <div class="odd_title">{{ item.title }}</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class=" df" :class="bg_pic" style="padding-bottom: 88px !important;">
            <div class="dfl">
                <div @click="tabs_fn(0,'bg_pic2')" :class=" is_active === 0 ? 'is_active' : '' ">IoT管理平台</div>
                <div @click="tabs_fn(1,'bg_pic3')" :class=" is_active === 1 ? 'is_active' : '' ">车载视频监控集中分发平台</div>
              </div>
            <div class="container tab-padding ">
              <div class="dfr" v-for="(item, index) in tabsData" :key="index" v-if="index == is_active">
                <div class="dfr_f">
                  <div>
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="dfr_f_r">
                    <div class="dfr_title">{{ item.title }} </div>
                    <div style="width: 573px;height:1px;margin: 16px 0;background: #0063e6;"></div>
                    <div class="dfr_text" v-html=" item.text "></div>
                    <div class="dfr_f_r_bg_pic">
                      <img :src="item.bg_img" alt="">
                    </div>
                  </div>
                </div>
                <div class="dfr_b">
                  <div
                    class="dfr_b_list"
                    v-for="(items, indexs) in item.data"
                    :key="indexs"
                  >
                    <div class="dfr_b_list_l">
                      <img :src="items.img" alt="" />
                    </div>
                    <div class="dfr_b_list_r">
                      <div class="dfr_b_list_r_title">{{ items.titles }}</div>
                      <div class="dfr_b_list_r_text">{{ items.text }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- <div class="level management">
          <div class="container" style="position: relative">
            <div class="head_title">
              <div class="max_tit">领先技术平台 赋能企业智能化升级</div>
            </div>
            <el-carousel class="triad" arrow="never" ref="cardShow" indicator-position="outside">
              <el-carousel-item v-for="item in 1" :key="item" style="height: 650px">
                <div class="triad-lunbo">
                  <el-row>
                    <el-col :span="10">
                      <img src="@img/serve/ic_home_pt_gy_yj.png" class="triad-img" style />
                    </el-col>
                    <el-col :span="14">
                      <h4>IOT管理平台</h4>
                      <div class="border"></div>
                      <p>
                        提供流量消耗查询、订单管理、到期时间、充值续费以及预警提醒等功能。
                        涵盖：激活、流量监管、停机保号、流量叠加、消耗查询、订单管理、到期时间、续期申请、预警提醒、短信管理、实名认证、IP管理等。
                      </p>
                    </el-col>
                  </el-row>
                </div>
                <ul class="hot-ul">
                  <li v-for="(item, index) in hotList" :key="index" class="bg-box" @mouseenter.stop="manageenter(index)"
                    @mouseleave.stop="manageenter" :class="
                      manageActive === ''
                        ? ''
                        : manageActive === null
                          ? 'aos-init aos-animate'
                          : manageActive === index
                            ? 'manageActive aos-init aos-animate'
                            : 'aos-init aos-animate'
                    ">
                    <img :src="item.img" />
                    <div class="plan-div">
                      <h4>{{ item.title }}</h4>
                      <p>{{ item.content }}</p>
                    </div>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div> -->
        <!-- 云百智能网关助力IoT设备连接 -->
        <!-- <div class="level bg_color3">
          <div class="container">
            <div class="head_title">
              <div class="max_tit">云百智能网关助力IoT设备连接</div>
            </div>
            <div class="flexs plc_box">
              <div class="plc_l">
                <div class="plc_l_t">
                  <img src="@img/serve/iot/plc.png" alt="">
                  <div>云百PLC智能网关</div>
                </div>
                <div class="plc_l_list">
                  <div class="plc_l_item">
                    <div class="plcTitle">工作电压</div>
                    <div class="plcText">DC 9V~36V</div>
                  </div>
                  <div class="plc_l_item">
                    <div class="plcTitle">工作电流</div>
                    <div class="plcText">180mA@12V，最大：300mA @12V</div>
                  </div>
                  <div class="plc_l_item">
                    <div class="plcTitle">工作温度</div>
                    <div class="plcText">默认-35℃- 75℃</div>
                  </div>
                  <div class="plc_l_item">
                    <div class="plcTitle">存储温度</div>
                    <div class="plcText">-40℃- 125℃</div>
                  </div>  
                  <div class="plc_l_item">
                    <div class="plcTitle">有线网口</div>
                    <div class="plcText">WAN口/LAN口</div>
                  </div>
                </div>
              </div>
              <div class="plc_r">
                <div class="plc_r_tit">
                  功能特点
                </div>
                <div class="plc_r_list">
                  <div class="plc_r_item" v-for="item in plc_r_item">
                    <img src="@img/InsidePages/7516.png" class="icons">
                    <div>{{item.text}}</div>
                    <img :src="item.img" class="icons1">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- 提供全球物联网服务能力 -->
        <div class="level bg_colorW">
          <div class="head_title">
            <div class="max_tit">全球物联网服务能力</div>
          </div>
          <div class="container" style="margin-top: 48px">
            <img :src="allsSever" alt="" />
          </div>
        </div>
        <!-- 5G三方联合实验室 -->
        <div class="level bg_color11">
          <div class="head_title">
            <div class="max_tit">5G三方联合实验室</div>
          </div>
          <div class="container lab" style="margin-top: 48px">
            <div>
              <img :src="labImg" alt="" />
            </div>
            <div class="lab_right">
              <div class="title">我司与广东移动及比XX车企于2021年10月成立5G三方联合实验室</div>
              <div class="line"></div>
              <div class="labInfo">研究方向</div>
              <div class="labFlex">
                <div style="padding: 10px 14px;">4G/5G网络部署优化</div>
                <div style="padding: 10px 17px;">高精准定位</div>
                <div style="padding: 10px 17px;">车路协同</div>
                <div style="padding: 10px 43px;">...</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 应用领域 -->
        <!-- <div class="level bg_colorW">
          <div class="head_title">
            <div class="max_tit">应用领域</div>
          </div>
          <div class="container" style="margin-top: 48px">
            <div class="apply_box">
              <div class="apply" v-for="(item, index) in carList" :key="index">
                <img :src="item.img" alt="" />
                <div>{{ item.label }}</div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="level bg_color3" style="padding-bottom:0">
          <div
            class="head_title"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-once="true"
          >
            <div class="max_tit">物联网服务应用场景</div>
          </div>
          <div class="container" style="margin-top: 30px;">
            <el-tabs v-model="activeName" :stretch="true">
              <el-tab-pane
                :label="item.label"
                :name="item.name"
                v-for="(item, index) in carList"
                :key="index"
              >
                <el-row>
                  <el-col :span="14" class="scenario">
                    <h4>{{ item.label }}</h4>
                    <div class="border"></div>
                    <p>{{ item.content }}</p>
                  </el-col>
                  <el-col :span="10">
                    <img :src="item.img" class="scenario-img" />
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div> -->
        <div class="level bg_color11" >
          <div class="">
            <div class="head_title">
              <div class="max_tit" style="padding-bottom:20px;">成功案例</div>
            </div>
            <div class="case">
              <el-tabs v-model="activeName" class="case_tabs" @tab-click="case_tabs">
                <el-tab-pane :name="item.name" v-for="(item, index) in caseList" :key="index" :label="item.lable">
                  <div class="case_box">
                    <div class="case_left"><img :src="item.img" alt="" /></div>
                    <div class="case_right">
                      <div class="title">{{ item.title }}</div>
                      <div class="line"></div>
                      <div class="case_cont">
                        <label>客户痛点</label>
                        <div>{{ item.painSpot }}</div>
                      </div>
                      <div class="case_cont">
                        <label>赋能过程</label>
                        <div>{{ item.process }}</div>
                      </div>
                      <div class="case_cont case_cont_flex" style="margin-top: 16px">
                        <div>
                          <span>{{ item.labelsName[0] }}</span><b>{{ item.labels[0] }}</b><i>%</i>
                          <img class="icon_up" src="@img/serve/up_icon.png" v-if="item.styleName[0] === 'icon_up' " />
                          <img class="icon_up" src="@img/serve/down_icon.png" v-if="item.styleName[0] === 'icon_down' " />
                        </div>
                        <div>
                          <span>{{ item.labelsName[1] }}</span><b>{{ item.labels[1] }}</b><i>%</i>
                          <img class="icon_up" src="@img/serve/up_icon.png" v-if="item.styleName[1] === 'icon_up' " />
                          <img class="icon_up" src="@img/serve/down_icon.png" v-if="item.styleName[1] === 'icon_down' " />
                        </div>
                        <div>
                          <span>{{ item.labelsName[2] }}</span><b>{{ item.labels[2] }}</b><i>%</i>
                          <img class="icon_up" src="@img/serve/up_icon.png" v-if="item.styleName[2] === 'icon_up' " />
                          <img class="icon_up" src="@img/serve/down_icon.png" v-if="item.styleName[2] === 'icon_down' " />
                        </div>
                      </div>
                      <div class="case_cont case_cont_btn">
                        <div class="details_btn">
                          <router-link :to="item.path" class="details_btn" style="margin-right: 0;"> 了解详情 </router-link>
                        </div>
                        <div class="custom_btn" @click="isShowFn">定制方案</div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer style="background: #f4f6f9">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
    <planDialog :isShow="isShow" v-if="isShow" @closeTop="closeTop"></planDialog>
    <!-- <scroll-top></scroll-top> -->
  </div>
</template>

<script>
import vHead from "../components/head.vue";
import vFooter from "../components/footer.vue";
import planDialog from "../components/planDialog.vue";
import { getServe } from "@/api/home";
export default {
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  metaInfo: {
    title: "物联网",
    meta: [
      {
        name: "keyWords",
        content: "物联网，网关，自动化，软硬件开发，车联网，监控，智慧码头",
      },
    ],
  },
  data() {
    return {
      activeName: "first",
      manageActive: "",
      isShow: false,
      is_active:0,
      oddActive: "",
      bg_pic:'bg_pic2',
      allsSever: require("@img/serve/alls.png"),
      labImg: require("@img/serve/5G.gif"),
      tabsData: [
        {
          title: "云百IoT管理平台",
          text:'提供流量消耗查询、订单管理、到期时间、充值续费以及预警提醒等功能。<br/>涵盖：物联网卡管理、流量监管、停卡保号、流量叠加、消耗查询、订单管理、到期时间、续期申请、预警提醒、短信管理、实名认证、IP管理等。',
          img:require("@img/home/img_iot_pt_sy.png"),
          bg_img:require("@img/home/img_iot_pt_ys1.png"),
          data: [
            {
              img: require("@img/home/icon/ic_.png"),
              titles: "三网合一",
              text: "统一三大运营商接口，一个平台管控三网卡片，缩短对接周期。",
            },
            {
              img: require("@img/home/icon/ic__1.png"),
              titles: "提供管理平台",
              text: "免费提供流量消耗查询、订单管理、到期时间、充值续费以及预警提醒等功能。",
            },
            {
              img: require("@img/home/icon/ic__2.png"),
              titles: "流量池预警",
              text: "支持组建流量池，无需担心流量浪费或透支问题，有效降低运营成本。",
            },
            {
              img: require("@img/home/icon/ic__4.png"),
              titles: "实名认证",
              text: "提供门店端、定制APP、小程序实名认证接口。",
            },
            {
              img: require("@img/home/icon/ic__3.png"),
              titles: "灵活计费",
              text: "提供多种灵活套餐，支持分润分销。",
            },
            {
              img: require("@img/home/icon/ic__5.png"),
              titles: "支持二次开发",
              text: "提供API接口，支持客户二次开发。",
            },
          ],
        },
        {
          title: "车载视频监控分发平台",
          text:'专为客户解决终端设备连接多个监管平台及网络改造的问题，可满足道路运输行业协会及各级政府监管平台对于车载终端接入的要求，同时提供更加直观和便利的管理功能，降低客户的流量使用成本，解决原视频监控平台转发通道的限制问题。',
          img:require("@img/home/img_cz_pt_sy.png"),
          bg_img:require("@img/home/img_home_pt_ys.png"),
          data: [
            {
              img: require("@img/home/icon/ic_car1.png"),
              titles: "转发服务器管理",
              text: "实现转发服务器配置功能，便于各监管机构的接入。",
            },{
              img: require("@img/home/icon/ic__4.png"),
              titles: "角色管理",
              text: "提供角色管理功能，可配置多个角色，满足平台管理需求。",
            },{
              img: require("@img/home/icon/ic_car3.png"),
              titles: "分发日志统计",
              text: "对数据分发日志进行统计，满足各级监管平台的审计要求。",
            },{
              img: require("@img/home/icon/ic_car4.png"),
              titles: "车辆信息管理",
              text: "提供车辆基础信息的管理功能，便于客户对旗下车辆进行维护。",
            },{
              img: require("@img/home/icon/ic_car5.png"),
              titles: "设备状态查询",
              text: "对车载视频监控设备提供实时状态查询能力。",
            },{
              img: require("@img/home/icon/ic_car6.png"),
              titles: "分发日志查询",
              text: "可查询数据分发日志，对分发数据进行监管。",
            }
          ],
        },
      ],
      bannerData: [
        {
          title: "云百IoT全栈服务",
          content:
            "提供智能化、安全管控等全栈全场景服务和开发、集成、托管、运营等一站式服务，助力合作伙伴和客户轻松、快速地构建5G应用和万物互联的场景化物联网解决方案。",
          button: "获取专属方案",
          img: require("@assets/dynamic_banner.gif"),
          banner: "serve_bg",
        },
      ],
      carList: [
        {
          label: "车联网",
          name: "first",
          content:
            "采集分析司机的驾驶习惯，实时监测车辆各零部件（如轮胎、刹车片、空调等）的状态以及交通路况，适时给司机提供驾驶建议；同时可以将上述数据和保险公司数据库结合，提供最合适的保险计划。",
          img: require("@img/serve/467aaf3369aea0bd0.png"),
        },
        {
          label: "智能制造",
          name: "second",
          content:
            "传统安防对人员的依赖性比较大，非常耗费人力，而智能安防能够通过设备实现智能判断。目前，智能安防最核心的部分在于智能安防系统，该系统是对拍摄的图像进行传输与存储，并对其分析与处理。一个完整的智能安防系统主要包括三大部分，门禁、报警和监控，行业中主要以视频监控为主。",
          img: require("@img/serve/888.png"),
        },
        {
          label: "智慧医疗",
          name: "third",
          content:
            "物联网应用于智能家居领域，能够对家居类产品的位置、状态、变化进行监测，分析其变化特征，同时根据人的需要，在一定的程度上进行反馈。智能家居行业发展主要分为三个阶段，单品连接、物物联动和平台集成。其发展的方向是首先是连接智能家居单品，随后走向不同单品之间的联动，最后向智能家居系统平台发展。当前，各个智能家居类企业正在从单品向物物联动的过渡阶段。",
          img: require("@img/serve/000.png"),
        },
        {
          label: "安防监控",
          name: "sixth",
          content:
            "智慧能源环保属于智慧城市的一个部分，其物联网应用主要集中在水能，电能，燃气、路灯等能源以及井盖、垃圾桶等环保装置。如智慧井盖监测水位以及其状态、智能水电表实现远程抄表、智能垃圾桶自动感应等。将物联网技术应用于传统的水、电、光能设备进行联网，通过监测，提升利用效率，减少能源损耗。",
          img: require("@img/serve/666.png"),
        },
        {
          label: "穿戴设备",
          name: "fifth",
          content:
            "在数字化以及智能化的工厂改造上，包括工厂机械设备监控和工厂的环境监控。通过在设备上加装相应的传感器，使设备厂商可以远程随时随地对设备进行监控、升级和维护等操作，更好的了解产品的使用状况，完成产品全生命周期的信息收集，指导产品设计和售后服务;而厂房的环境主要是采集温湿度、烟感等信息。",
          img: require("@img/serve/777.png"),
        },
        {
          label: "智能电力",
          name: "seventh",
          content:
            "物联网技术可以用于近场和中场零售，且主要应用于近场零售，即无人便利店和自动(无人)售货机。智能零售通过将传统的售货机和便利店进行数字化升级、改造，打造无人零售模式。通过数据分析，并充分运用门店内的客流和活动，为用户提供更好的服务，给商家提供更高的经营效率。",
          img: require("@img/serve/03445217.png"),
        },
        {
          label: "智能家居",
          name: "fourth",
          content:
            "物联网技术是数据获取的主要途径，能有效地帮助医院实现对人的智能化管理和对物的智能化管理。对人的智能化管理指的是通过传感器对人的生理状态(如心跳频率、体力消耗、血压高低等)进行监测，将获取的数据记录到电子健康文件中，方便个人或医生查阅。除此之外，通过RFID技术还能对医疗设备、物品进行监控与管理，实现医疗设备、用品可视化，主要表现为数字化医院。",
          img: require("@img/serve/999.png"),
        },
        {
          label: "智慧零售",
          name: "eighth",
          content:
            "应用于物联网领域主要体现在三个方面，仓储、运输监测以及快递终端等，通过物联网技术实现对货物的监测以及运输车辆的监测，包括货物车辆位置、状态以及货物温湿度，油耗及车速等，物联网技术的使用能提高运输效率，提升整个物流行业的智能化水平。",
          img: require("@img/serve/444.png"),
        },
        {
          label: "共享设备",
          name: "eighth",
          content:
            "应用于物联网领域主要体现在三个方面，仓储、运输监测以及快递终端等，通过物联网技术实现对货物的监测以及运输车辆的监测，包括货物车辆位置、状态以及货物温湿度，油耗及车速等，物联网技术的使用能提高运输效率，提升整个物流行业的智能化水平。",
          img: require("@img/serve/501349062.png"),
        },
        {
          label: "智慧物流",
          name: "eighth",
          content:
            "应用于物联网领域主要体现在三个方面，仓储、运输监测以及快递终端等，通过物联网技术实现对货物的监测以及运输车辆的监测，包括货物车辆位置、状态以及货物温湿度，油耗及车速等，物联网技术的使用能提高运输效率，提升整个物流行业的智能化水平。",
          img: require("@img/serve/333.png"),
        },
      ],
      hotList: [
        {
          img: require("@img/serve/ic_wlw_d_a.png"),
          title: "三网合一",
          content: "统一三大运营商接口，一个平台管控三网，缩短对接周期。",
          path: "",
        },
        {
          img: require("@img/serve/ic_wlw_d_b.png"),
          title: "提供管理平台",
          content:
            "免费提供流量消耗查询、订单管理、到期时间、 充值续费以及预警提醒等功能。",
          path: "",
        },
        {
          img: require("@img/serve/ic_wlw_d_c.png"),
          title: "流量池预警",
          content:
            "支持组建流量池，无需担心流量浪费或透支问题，有效降低运营成本。",
          path: "",
        },
        {
          img: require("@img/serve/ic_wlw_d_d.png"),
          title: "实名认证",
          content: "提供公众号、小程序实名认证接口。",
          path: "",
        },
        {
          img: require("@img/serve/ic_wlw_d_e.png"),
          title: "灵活计费。",
          content: "提供多种灵活套餐，支持分润分销。",
          path: "",
        },
        {
          img: require("@img/serve/ic_wlw_d_f.png"),
          title: "支持二次开发",
          content: "提供API接口，支持客户二次开发。",
          path: "",
        },
      ],
      oddsList: [
        {
          img: require("@img/home/ic_iot_hx_a.png"),
          name: "市场地位领先",
          title:
            "我司在车联网及车载视频监控等细分市场中都处于业内领先的位置，为国内知名车企以及 “两客一危一重” 项目的各大GPS运营商等客户提供专业的物联网服务。",
        },
        {
          img: require("@img/serve/ic_iot_hx_b.png"),
          name: "技术支撑可靠",
          title:
            "实施了大量全国性项目，通过了5大ISO体系、CCRC、CMMI3等认证，有自主专利产权，具有丰富的实施经验和较强的实施能力，技术、服务支撑保障更可靠。",
        },
        {
          img: require("@img/serve/ic_iot_hx_c.png"),
          name: "使用安全无忧",
          title:
            "所提供的方案已经广泛地应用在了银行、汽车、港口、电力等行业，使用安全稳定，同时，承接了360南方基地部分的安全服务工作，为客户资产的管理提供更好的安全保障。",
        },
        {
          img: require("@img/serve/ic_iot_hx_d.png"),
          name: "用户成本最优",
          title:
            "我们和运营商保持长期深入的合作关系，及时跟进政策调整，同时免费提供智能管理系统平台，让用户使用成本最优。",
        },
        {
          img: require("@img/serve/ic_iot_hx_e.png"),
          name: "服务保障及时",
          title:
            "成立了专门的售后服务团队为物联网流量项目提供服务保障，提供高效的故障处理响应服务，保障服务的及时性与专业性。",
        },
      ],
      caseList: [
        {
          lable: "两客一危一重",
          title: "两客一危一重",
          name: "first",
          img: require("@img/serve/img_iot_al_lk.png"),
          painSpot:
            "客户使用传统方案面临终端设备连接多个监管平台、上传线路多、消耗流量多、转发通道的限制等问题。",
          process:
            "专为客户解决终端设备连接多个监管平台及网络改造的问题，可满足道路运输行业协会及各级政府监管平台对于车载终端接入的要求，实现一对多，同时提供更加直观和便利的管理功能，降低客户的流量使用成本。",
          labels: ["70", "60", "80"],
          styleName:['icon_down','icon_down','icon_down'],
          labelsName:["流量节省：","成本下降：","对接压力："],
          path: "./InsidePages/coach",
        },
        {
          lable: "智慧车联网",
          title: "智慧车联网",
          name: "second",
          img: require("@img/serve/img_iot_al_clw.png"),
          painSpot:'缺乏针对智能网联汽车信息安全的攻击特征库，企业无法共享外部攻击特征，从而实现有效防范。',
          process:'为车主提供更好的出行服务体验，增加产品竞争力。提供满足车联应用的云计算、大数据、人工智能、物联网等云服务，助力车企打造智能网联汽车，让客户享受人车智能生活。',
          labels:['40','30','50'],
          styleName:['icon_up','icon_up','icon_up'],
          labelsName:["智能化：","安全性：","娱乐性："],
          path:"./InsidePages/carNetworking"
        },
      
      ],
      plc_r_item:[
        {
          text:'支持ADSL方式数据上云',
          img:require("@img/serve/iot/ic_iot_wg_a.png"),
        },
        {
          text:'边缘计算',
          img:require("@img/serve/iot/ic_iot_wg_a_4.png"),
        },
        {
          text:'WAN/LAN双网口设计',
          img:require("@img/serve/iot/ic_iot_wg_a_2.png"),
        },
        {
          text:'实时数据监控',
          img:require("@img/serve/iot/ic_iot_wg_a_6.png"),
        },
        {
          text:'APN 专网',
          img:require("@img/serve/iot/ic_iot_wg_a_1.png"),
        },
        {
          text:'设备远程维护',
          img:require("@img/serve/iot/ic_iot_wg_a_5.png"),
        },
        {
          text:'设备历史数据查看',
          img:require("@img/serve/iot/ic_iot_wg_a_3.png"),
        },
        {
          text:'防火墙',
          img:require("@img/serve/iot/ic_iot_wg_a_7.png"),
        }
      ]
    };
  },
  created() {
    //  this.init()
  },
  methods: {
    async init() {
      let {
        data: { oddsList, hotList },
      } = await getServe();
      Object.assign(this, { oddsList, hotList });
    },
    mouseenter(i) {
      this.oddActive = i;
    },
    manageenter(index) {
      this.manageActive = index;
    },
    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
    isShowFn() {
      this.isShow = true;
    },
    closeTop() {
      this.isShow = false;
    },
    gotoInside(url) {
      // this.$router.push({ path: url });
    },
    tabs_fn(id,name){
      this.is_active = id,
      this.bg_pic = name
    },
    case_tabs(e){

      if(e.label == '智慧车联网'){
         document.getElementsByClassName("el-tabs__active-bar").left = 300+'px !important'
         console.log( document.getElementsByClassName("el-tabs__active-bar").left)
      }
      if(e.label == '两客一危一重'){}
      if(e.label == '数据采集'){}
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/base.scss";

.base_head {
  height: auto;
}
// .bg_pic1 {
//   background: url("../../public/img/home/ic_home_pt_bg.png");
//   background-size: 100% 100%;
// }
.bg_pic2 {
  background: url("../../public/img/home/ic_home_pt_bg2.png");
  background-size: 100% 100%;
}
.bg_pic3 {
  background: url("../../public/img/home/img_cz_pt_bg.png");
  background-size: 100% 100%;
}
.serve {
  .el-carousel__indicators {
    display: none;
  }

  .el-main {
    // background: #f4f6f9 !important;
    .triad {
      margin-top: 36px;
      width: 1200px;
      background: linear-gradient(180deg, #f4f6f9, #ffffff);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 5px 5px 15px 0px #ebeff5;
      height: 650px !important;

      el-carousel-item {
        height: 650px !important;
      }

      .el-carousel__container {
        height: 650px !important;
      }

      .triad-lunbo {
        height: 254px;
        background: linear-gradient(90deg, #30abeb, #1e77ec);
        margin: 36px 32px 0 32px;

        .triad-img {
          width: 339px;
          height: 247px;
          margin-top: -23px;
          margin-left: 30px;
        }

        h3 {
          color: #475669;
          font-size: 14px;
          opacity: 0.75;
          line-height: 150px;
          margin: 0;
          text-align: center;
        }

        .border {
          width: 572px;
          height: 1px;
          opacity: 0.4;
          background: #ffffff;
          margin-left: 39px;
        }

        h4 {
          font-size: 27px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin: 44px 40px 15px;
        }

        p {
          margin: 15px 40px 0;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 28px;
        }
      }
    }

    .el-carousel__indicators {
      bottom: 20px;
    }

    .apply_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .apply {
        width: 220px;
        height: 157px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
        position: relative;
        margin-bottom: 26px;
        border-radius: 4px;
        transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);

        img {
          width: 100%;
          border-radius: 4px;
        }

        div {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 40px;
          background: rgba(35,126,255,.7);
          border-radius: 4px;
          font-size: 18px;
          line-height: 40px;
          color: #fff;
        }
      }

      .apply:hover {
        box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
        transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
      }
    }

    .hot-ul {
      display: flex;
      justify-content: space-between;
      width: 1136px;
      padding: 0px 32px;
      flex-wrap: wrap;

      li {
        width: 316px;
        padding: 6px;
        display: flex;
        margin-top: 24px;
        height: 95px;
        padding: 20px 20px;

        .plan-div {
          margin-left: 8px;
          text-align: left;
          position: relative;
          z-index: 3;

          p {
            color: #363737;
            margin-top: 9px;
          }

          h4 {
            font-weight: normal;
            font-size: inherit;
            font-size: 18px;
            font-weight: 500;
            height: 40px;
            line-height: 40px;
            color: #1a1a1a;
          }

          h4::after {
            content: "";
            display: block;
            width: 44px;
            height: 2px;
            background: linear-gradient(90deg, #0063e6, #9cc6fe);
            border-radius: 2px;
            position: absolute;
            top: 38px;
          }

          a {
            // color: #1e77ec;
            // position: absolute;
            // bottom: 3px;
            color: #959da7;
            position: absolute;
            bottom: 6px;
            border: 1px solid #959da7;
            border-radius: 2px;
            text-align: center;
            display: inline-block;
            padding: 2px;
            color: #959da7;
          }
        }

        img {
          width: 56px;
          height: 56px;
        }
      }

      .manageActive {
        background: linear-gradient(180deg, #edf2fa, #fefefe);
        box-shadow: 0px 5px 18px 0px #c7d6eb;
      }
    }

    .management {
      background: url("../../public/img/serve/management.png") no-repeat;
      background-size: 100% 100%;
      margin-top: -11px;
    }

    .arrow-switch {
      position: absolute;
      right: 32px;
      margin-top: 75px;
      z-index: 99;

      span:nth-child(1) {
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-right-color: #fff;
        display: inline-block;
      }

      span:nth-child(2) {
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left-color: #fff;
        display: inline-block;
        margin-left: 10px;
      }
    }

    // .el-carousel{
    //   height: 327px;
    //   .el-carousel__container{
    //     height: 327px;
    //   }
    // }
    .triad2 {
      .el-carousel__container {
        margin-top: 32px;
        height: 400px;

        .el-carousel__item {
          display: flex;

          img {
            width: 651px;
            height: 334px;
            margin-top: 30px;
            z-index: 9;
          }

          .successful-case {
            margin-top: 8px;
            margin-left: -268px;
            width: 810px;
            height: 378px;
            background: #ffffff;
            border: 1px solid #ffffff;
            border-radius: 16px;
            box-shadow: 0px 3px 8px 0px #e9eef7;

            img {
              position: absolute;
              top: -21px;
              right: 7px;
              width: 204px;
              height: 181px;
            }

            h3 {
              font-size: 22px;
              font-weight: 700;
              text-align: left;
              color: #161719;
              margin-left: 300px;
              margin-top: 133px;
            }

            h3::after {
              content: "";
              display: block;
              width: 31px;
              height: 3px;
              margin-top: 16px;
              background: linear-gradient(90deg, #0063e6, #9cc6fe);
            }

            p {
              margin-top: 19px;
              margin-left: 300px;
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              color: #959da7;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .case {
    .case_tabs {
       
       ::v-deep .el-tabs__nav-scroll {
          width: 260px;
          margin: 0 auto;
        }

      .case_box {
        display: flex;
        width: 1200px;
        margin: auto;
        // padding-bottom: 48px;
        .case_left {
          img {
            width: 539px;
          height: 354px;
          }
        }

        .case_right {
          padding-left: 32px;
          position: relative;

          .title {
            font-size: 30px;
            font-weight: 700;
            text-align: left;
            color: #141414;
            line-height: 28px;
          }
          .line{
            width: 627px;
          height: 1px;
          margin: 16px 0 18px 0;
          background: #0063e6;
          }
          .case_cont {
            text-align: left;
            margin-top: 24px;

            label {
              font-size: 16px;
              text-align: left;
              color: #0063e6;
              line-height: 23px;
              display: inline-block;
              vertical-align: top;
              margin-right: 16px;
            }

            div {
              display: inline-block;
              width: 549px;
              font-size: 16px;
              text-align: left;
              color: #141414;
              line-height: 28px;
              position: relative;
              top:-3px;
            }
          }

          .case_cont_flex {
            display: flex;
            width: 550px;
            justify-content: space-evenly;
            span{
              font-size: 16px;
              color: #a3a3a3;
            }
            b{
              font-size: 28px;
              font-weight: 700;
              color: #323232;
              position: relative;
              left: -2px;
            }
          }

          .case_cont_btn {
            position: absolute;
            bottom: 0;

            .details_btn {
              width: 121px;
              height: 40px;
              background: #0063e6;
              border: 1px solid #0063e6;
              border-radius: 2px;
              text-align: center;
              line-height: 40px;
              color: #fff;
              margin-right: 40px;
              cursor: pointer;
              font-size: 16px;
            }

            .custom_btn {
              width: 121px;
              height: 40px;
              border: 1px solid #0063e6;
              color: #0063e6;
              border-radius: 2px;
              text-align: center;
              line-height: 40px;
              cursor: pointer;
              font-size: 16px;
            }
          }
        }
      }
    }

    .el-tabs__nav-wrap::after {
      height: 0px !important;
    }

    ::v-deep .el-tabs__active-bar {
      background: #0063e6 !important;
      // width: 24px !important;
      height: 2px !important;
      border-radius: 2px !important;
      // left: 28px;
    }
  }

  .container {
    width: 1200px;
    margin: 0 auto;

    .scenario-img {
      width: 100%;
      height: 295px;
    }

    .scenario {
      background: linear-gradient(90deg, #30abeb, #1e77ec);
      height: 295px;
      text-align: left;
      padding: 0 60px 0 39px;

      .border {
        width: 572px;
        height: 1px;
        opacity: 0.4;
        background: #fff;
      }

      h4 {
        margin: 65px 0 11px;
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
      }

      p {
        margin: 12px 0 0px;
        font-size: 16px;
        font-weight: Normal;
        text-align: left;
        color: #ffffff;
        line-height: 28px;
      }
    }

    .oddBox {
      margin-top: 52px;
      height: 300px;

      .outside {
        width: 214px;
        height: 330px;
        cursor: pointer;
        margin: 50px auto;
        position: relative;
        perspective: 500;
        -webkit-perspective: 1000;
        overflow: hidden;
        background: linear-gradient(180deg,#f2f6fc, #fcfcfc);
        // border: 1px solid #ffffff;
        border-radius: 4px;
        box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08); 
        .bg_pic_outside{
          position: absolute;
          display: none;
          z-index: 1;
          right: 0;
        }
      }

      // .oddItem_front {
      //   box-shadow: 0 3px 14px 0 #f0f3f8;
      //   background: linear-gradient(180deg,#f2f6fc, #fcfcfc);
      //   border: 2px solid #ffffff;
      //   border-radius: 4px;
      //   box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08); 
      // }

      .oddItem_front {
        width: 210px;
        height: 100%;
      }

     
    }

    .oddItem_back {
      .odd_name {
        margin-top: 12px;
      }
    }

    // .outside:hover .oddItem_front {
    //   background: linear-gradient(180deg,#edf3fc, #fcfcfc);
    //   border: 2px solid #ffffff;
    //   border-radius: 4px;
    //   box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.16); 
      
    // }

    .outside:hover{
      .bg_pic_outside {
          display: block;
          position: absolute;
          z-index: 1;
        }
        .oddItem{
          background: linear-gradient(180deg,#edf3fc, #fcfcfc);
          transition: all .3s cubic-bezier(0,0,.5,1);
        }  
        
        border-radius: 4px;
        box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.16); 
        transition: all .3s cubic-bezier(0,0,.5,1);
        }

    .oddItem {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
      background: linear-gradient(180deg,#f2f6fc, #fcfcfc);
      .odd_img {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        z-index: 2;
        img {
          width: 100%;
          height: 100%;
          background-size: cover;
        }
      }

      .odd_name {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: #161719;
        letter-spacing: -1px;
        z-index: 2;
      }

      .lineB {
        display: none;
        opacity: 1;
        position: absolute;
        background: #1e77ec;
        bottom: -4px;
        width: 214px;
        height: 2px;
      }

      .odd_title {
        font-size: 14px;
        text-align: center;
        color: #959da7;
        letter-spacing: 0px;
        padding: 0 16px;
        margin-top: 12px;
        line-height: 26px;
      }
    }

    .oddActive {
      background: linear-gradient(180deg, #edf2fa, #fefefe);
      box-shadow: 0px 5px 18px 0px #c7d6eb;

      .lineB {
        display: block;
        opacity: 1;
        position: absolute;
        background: #1e77ec;
        bottom: -4px;
        width: 214px;
        height: 2px;
      }
    }
  }

  .levelone::after {
    content: "ADVANTAGE";
    position: absolute;
    width: 530px;
    top: -10%;
    left: 50%;
    margin-left: -265px;
    height: 95px;
    opacity: 0.05;
    font-size: 72px;
    font-weight: 700;
    text-align: center;
    color: red;
    letter-spacing: 7px;
  }
}

.el-tabs__item.is-active {
  color: #005de0;
}
::v-deep .el-tabs__nav-wrap .el-tabs__nav-scroll{
  width: 260px;
  margin: 0 auto;
}

.oddLine {
  width: 25px;
  height: 2px;
  background: #0063e6;
  margin: 0 auto;
  margin-top: 12px;
}

.icon_up {
  height: 14px;
  margin-left: 10px;
  vertical-align: inherit;
}

.icon_down {
  height: 14px;
  margin-left: 8px;
  vertical-align: middle;
}

::v-deep .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 100%;
  height: 0;
  background-color: #e4e7ed;
  z-index: 1;
}

.oddLine {
  width: 25px;
  height: 4px;
  background: #0063e6;
  margin: 0 auto;
  margin-top: 12px;
  border-radius: 2px;
}

.outside:hover {
  // background: linear-gradient(180deg,#edf3fc, #fcfcfc);
  // box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.16);
}

.el-carousel__indicators {
  display: none;
}

::v-deep .el-carousel__indicators {
  display: none;
}

.plc_box{
  width: 1100px;
  margin:0 auto;
  // height: 584px;
  background: linear-gradient(180deg,#f7f9fc, #fcfcfc);
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08); 
  padding:48px;
  margin-top: 48px;
  .plc_l{
    position:relative;
    .plc_l_t{
      width: 420px;
      height: 212px;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 30px;
      img{
        width: 200px;
        height: 108px;
        margin: 0 auto;
        margin-top: 33px;
      }
      div{
        height: 32px;
        font-size: 23px;
        font-weight: 700;
        text-align: center;
        color: #0063e6;
        position: relative;
        top:19px;
      }
      div::after{
        content: " ";
        width: 183px;
        height: 13px;
        opacity: 0.16;
        background: linear-gradient(180deg, #a1c9ff, #0063e6);
        position: absolute;
        bottom: 0;
        left: 118px;
      }
    }
    .plc_l_list{
      .plc_l_item{
        height: 44px;
        display: flex;
        margin-top: 10px;
        .plcTitle{
          width: 120px;
          height: 44px;
          line-height: 44px;
          background: #e0eeff;
          border-radius: 4px 0px 0px 4px;
          color: #1f272a;
          text-align: center;
          font-size: 15px;
        }
        .plcText{
          width: 300px;
          height: 42px;
          line-height: 42px;
          border: 1px solid #e0eeff;
          border-radius: 0px 4px 4px 0px;
          color: #1f272a;
          font-size: 15px;
        }
      }
    }
  }
  .plc_l::after{
    content: ' ';
    height: 500px;
    border: 1px solid #f0f0f0;
    position: absolute;
    right: -42px;
    top: 0;
  }
  .plc_r{
    width: 596px;
    .plc_r_tit{
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: #141414;
      text-align: left;
      position: relative;
      left: 12px;
    }
    .plc_r_tit::before{
      content: ' ';
      width: 3px;
      height: 18px;
      background: #0063e6;
      position: absolute;
      top: 5px;
      left: -12px;
    }
    .plc_r_list{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .plc_r_item{
        display: flex;
        width: 282px;
        height: 91px;
        background: #fcfcfc;
        border: 2px solid #ffffff;
        border-radius: 4px;
        box-shadow: 6px 6px 18px 0px rgba(0,55,128,0.08); 
        margin-top: 24px;
        justify-content: space-between;
        .icons{
          width: 16px;
          height: 16px;
          display: block;
          margin-left: 24px;
          margin-top: 24px;
        }
        div{
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #0063e6;
          line-height: 17px;
          margin-top: 24px;
        }
        .icons1{
          width: 48px;
          height: 48px;
          display: block;
          margin-top: 24px;
        }
      }
    }
  }
}

.df {
  .dfl {
    width: 432px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 54px auto;
    padding-top: 54px;
    div {
      padding: 13px 24px;
      font-size: 16px;
      cursor: pointer;
      background: linear-gradient(180deg,#f2f6fc, #fcfcfc);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08); 
    }
    .is_active {
      background: #0063e6;
      color: #fff;
    }
  }
  .dfr {
    width: 1142px;
    height: 548px;
    background: linear-gradient(180deg,#f2f6fc, #fcfcfc);;
    border: 2px solid #ffffff;
    border-radius: 0px 2px 2px 0px;
    box-shadow: 5px 5px 15px 0px #dde2eb;
    padding: 34px 27px 16px 27px;
    
     .dfr_f {
      display: flex;
      border-radius: 4px;
      background: linear-gradient(90deg,rgba(252,254,255,0.00), #fafcff 45%, rgba(255,255,255,0.00));
      border: 1px solid #d6e9ff;
      border-radius: 4px;
      padding: 30px 43px;
      img {
        width: 325px;
        height: 205px;
        margin-right: 21px;
      }
      .dfr_f_r {
        padding-left: 24px;
        position: relative;
        .dfr_title {
          font-size: 24px;
          text-align: left;
          color: #0063e6;
          margin-top: 25px;
        }
        .dfr_text {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #141414;
          line-height: 30px;
          width: 580px;
        }
        .dfr_f_r_bg_pic{
          width: 250px;
          height: 165px;
          position: absolute;
          right: -147px;
          top: -30px;
          img{
            width: 250px;
            height: 165px;
          }
        }
      }
    }
    .dfr_b {
      display: flex;
      flex-wrap: wrap;
      .dfr_b_list {
        width: 336px;
        height: 104px;
        background: #ffffff;
        border: 1px solid #f5f5f5;
        border-radius: 4px;
        padding: 15px 16px 0 16px;
        display: flex;
        margin-top: 16px;
        .dfr_b_list_l {
          width: 44px;
          img {
            width: 44px;
            height: 44px;
            display: block;
          }
        }
        .dfr_b_list_r {
          .dfr_b_list_r_title {
            font-size: 16px;
            text-align: left;
            color: #141414;
            position: relative;
            margin-top: 2px;
          }
          .dfr_b_list_r_title::after {
            content: "";
            width: 25px;
            height: 2px;
            background: #0063e6;
            position: absolute;
            bottom: -12px;
            left: 0;
          }
          .dfr_b_list_r_text {
            font-size: 12px;
            text-align: left;
            color: #727f8f;
            line-height: 20px;
            margin-top: 20px;
          }
        }
      }
      .dfr_b_list:nth-child(3n-1) {
        margin-left: 16px;
        margin-right: 16px;
      }
      .dfr_b_list:hover{
        // box-shadow: 5px 5px 15px 0px #dde2eb; 
        transition: all .3s cubic-bezier(0,0,.5,1);
        box-shadow: 0px 5px 18px 0px #c7d6eb !important;
      }
    }
  }
}
::v-deep .el-tabs__content{
  background: linear-gradient(87deg,#fafcff 2%, #e9f2ff 51%, #fafcff 98%);
  border: 2px solid #ffffff;
  box-shadow: 5px 5px 15px 0px #ebeff5; 
  padding: 55px 0;
}
::v-deep .el-tabs__nav-scroll{
  width: 260px !important;
  margin: auto !important;
}
::v-deep .el-tabs__nav-scroll{
  width: 260px !important;
  margin: auto !important;
}


::v-deep .el-tabs__active-bar{
  background: #409EFF !important;
}
.bg_color11{
  background: #f4f6f9 !important;
}
.bg_colorW{
  background: #fff !important;
}

.lab{
  width: 1120px !important;
  height: 300px;
  padding: 40px;
  background: linear-gradient(180deg,#f2f6fc, #fcfcfc);
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08);
  display: flex;
  justify-content: space-between;
  .lab_right{
    margin-left: 40px;
    .title{
      width: 540px;
      height: 73px;
      font-size: 24px;
      font-weight: 400;
      text-align: left;
      color: #141414;
      line-height: 40px;
      margin-top: 40px;
    }
    .line{
      width: 542px;
      height: 1px;
      background: #0063e6;
      margin: 31px 0 34px 0;
    }
    .labInfo{
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      color: #0063e6;
      line-height: 33px;
      margin-bottom: 16px;
    }
    .labFlex{
      display: flex;
      justify-content:space-between;
      div{
        background: #0063e6;
        border: 1px solid #0063e6;
        border-radius: 2px;
        font-size: 15px;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  
}
</style>

