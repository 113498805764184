<template>
  <div class="wisdom_park">
    <el-container>
      <el-header>
        <v-head :active="10"  :isBanner="false" :bannerData="bannerData"></v-head>
      </el-header>
      <el-main class="main-bottom bg_color2">
        <div class="top_bg ">
            <div class="top_text_mc">
                <div class="top_text_box">
                    <div class="top_text_tit">智慧仓储解决方案</div>
                    <div class="top_text_info">
                      基于 RFID 技术的仓库管理，将改变传统的仓库管理的工作方式 与流程，把所有关键的因素通过贴上 RFID 标签，在仓库管理的核心 业务流程：出库、入库、盘点、库存控制上实现更高效精确的管理。
                    </div>
                    <div class="top_text_btn" @click="isShowFn">获取方案</div>
                </div>
            </div>
        </div>

        <div class="max_tit">管理方案</div>
        <div class="cont">
            <img src="@img/InsidePages/warehousing.png" alt="">
        </div>

      </el-main>

      <planDialog :isShow="isShow" v-if="isShow" @closeTop="closeTop">
      </planDialog>
      <!-- <scroll-top></scroll-top> -->
      <el-footer style="background: rgb(244, 246, 249);">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import vHead from "../../components/head.vue";
import vFooter from "../../components/footer.vue";
import planDialog from "../../components/planDialog.vue";
export default {
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      isShow: false,
      activeName: "first",
      bannerData: [
        {
          title: "云百工业数字化平台",
          content:
            "帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
          button: "获取专属方案",
          img: require("@img/plan/gy_img_banner_a_r.png"),
          banner: "banners banners1",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    isShowFn(){
        this.isShow = true;
    },
    closeTop(){
         this.isShow = false;
    }
  },
};
</script>

<style scoped lang="scss">
    .main-bottom{
        padding-top: 80px;
         padding-bottom: 100px !important;
        .top_bg {
            // width: 1920px;
            height: 460px;
            background: url("../../../public/img/home/banner/banner1.png");
            background-repeat: no-repeat;
            background-size: cover;
            .top_text_mc{
                width: 1200px;
                margin: 0 auto;
                .top_text_box{
                    width: 502px;
                    height: 229px;
                    border-radius: 12px;
                    backdrop-filter: blur(4px);
                    padding: 38px 28px;
                    color: #fff;
                    position: relative;
                    top: 74px;
                    background: rgba(0,0,0,.2);
                    .top_text_tit{
                        font-size: 36px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 30px;
                    }
                    .top_text_info{
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        line-height: 26px;
                    }
                    .top_text_btn{
                        width: 124px;
                        height: 44px;
                        line-height: 44px;
                        border: 1px solid #ffffff;
                        border-radius: 4px;
                        position: absolute;
                        bottom: 20px;
                        cursor: pointer;
                    }
                }
            }
           
        }
        .max_tit{
            width: 100%;
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #141414;
            text-align: center;
            padding: 68px 0 48px 0;
        }
        .cont{
            display: flex;
            width: 1200px;
            margin: 0 auto;
            justify-content: space-between;
            .right{
                background: linear-gradient(180deg,#f4f6f9, #ffffff);
                border: 2px solid #ffffff;
                border-radius: 4px;
                box-shadow: 5px 5px 15px 0px #ebeff5; 
                width: 343px;
                height: 250px;
                font-size: 16px;
                font-family: MiSans, MiSans-Normal;
                text-align: left;
                color: #141414;
                line-height: 36px;
                padding: 60px 25px;
            }
        }
    }

</style>
