<template>
  <div class="fd-c">
    <div class="cd-container">
        <div class="big_txt">申请试用</div>
        <div class="b-item">
          <span class="text">公司名称：</span>
          <el-input clearable v-model="params.remark" placeholder="联系人公司名称"></el-input>
        </div>
        <div class="b-item">
          <span class="text">联系人：</span>
          <el-input clearable v-model="params.realName" placeholder="联系人姓名"></el-input>
        </div>
        <div class="b-item">
          <span class="text">手机号：</span>
          <el-input clearable v-model="params.mobile" placeholder="联系人手机号"></el-input>
        </div>
        <el-button type="primary" @click="aboutSubmit">提交</el-button>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixin/index.js'
export default {
  mixins: [mixin],
  data() {
    return{

    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.fd-c{
  width: 100%;
  z-index: 999;
  position: fixed;
  bottom: 0;
  background: rgba(0,0,0,.68);
  padding:30px;
  box-sizing: border-box;
  display: flex;
  color: #fff;
  .big_txt{
    font-size: 20px;
  }
  .cd-container{
    width: 1500px;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .b-item{
      display: flex;
        align-items: center;
      .text{
        width: 150px;
      }
    }
    input{
      font-size: 16px;
    }
    button{
      margin-left: 25px;
    }
  }
}
</style>