<template>
  <div class="solution">
    <el-container>
      <el-header>
        <v-head :active="3" :isGif="false" :bannerData="bannerData" :color="'white'" indicator="none"></v-head>
      </el-header>
      <el-main class="main-bottom">
      <div class="tabs_box">
        <el-tabs :tab-position="tabPosition" style="height: 373px;">
          <el-tab-pane label="通用解决方案">
            <div class="list_box">
               <div class="list" v-for="(item,index) in cdyTab" :key="index">
                <div class="list_title">{{item.title}}</div>
                <div class="list_cont">
                  <div v-for="(items,indexs) in item.tips" :key="indexs" @click="gotoNext(items.path)" >{{items.text}}</div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="行业解决方案">
            <div class="list_box">
              <div class="list" v-for="(item,index) in yyyTab" :key="index">
                <div class="list_title">{{item.title}}</div>
                <div class="list_cont">
                  <div v-for="(items,indexs) in item.tips" :key="indexs">{{items}}</div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      </el-main>
      <el-footer style="background:#f4f6f9;">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
    <plan-dialog :isShow="isShow" v-if="isShow" @closeTop="closeTop"></plan-dialog>
  </div>
</template>

<script>
import vHead from '../components/head.vue'
import vFooter from '../components/footer.vue'
import planDialog from "@components/planDialog.vue"
import { getSolution } from '@/api/home'
export default {
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      isShow: false,
      planActive: '',
      successActive: '',
      activeName: 'first',
      tabPosition: 'left',
      bannerData: [
        {
          title: "解决方案",
          content:
            "提供智能高效、安全、稳定的高性价比系统整体解决方案。",
          button: "申请试用",
          img: '',
          banner: "banners3",
        },

      ],
       cdyTab:[
        {
          title:'IoT物联网',
          tips:[
            // {
            //   text:'车联网',
            //   path:'./InsidePages/carNetworking'
            // },
             {
              text:'智慧养殖',
              path:'./InsidePages/farming'
            }
          ]
        },
         {
          title:'智能网关',
           tips:[
            {
              text:'数据采集',
              path:'/InsidePages/carNetworking'
            },	 
		    {
				text:'智慧园区',
				path:'./InsidePages/wisdom_park'
			} 
          ]
        },
         {
          title:'工业数字化',
          tips:[
            {
              text:'智慧港口',
              path:'./InsidePages/warehousing'
            },{
              text:'智慧仓储',
              path:'/InsidePages/warehousing'
            }
          ]
        },
         {
          title:'智慧车联网',
          tips:[
            {
              text:'智慧车联网',
              path:'./InsidePages/carNetworking'
            },
             {
              text:'两客一危一重',
              path:'./InsidePages/carNetworking'
            } 
          ]
        },
      ],
      yyyTab:[
        {
          title:'制造业',
          tips:['自动化生产','工业数字化','智慧工厂']
        },
         {
          title:'农业',
          tips:['自动化浇灌','智慧种植','智慧监控']
        },
         {
          title:'医疗',
          tips:['AI智能辅诊','智慧就医平台','智慧医院']
        },
         {
          title:'教育',
          tips:['在线智能课堂','工业数字化','智慧工厂']
        },
         {
          title:'金融',
          tips:['云上金融','多重安全机制','多中心合规']
        },
        
      ]
    }
  },
  created() {
    // this.init()
  },
  methods: {
    async init() {
      let { data: { hotList, successList } } = await getSolution()
      Object.assign(this, { hotList, successList })
      // 123123
    },
    closeTop() {
      this.isShow = false;
    },
    planHover(i) {
      this.planActive = i
    },
    mouseenterSucc(i) {
      this.successActive = i
    },
    gotoNext(url){
      console.log(url)
      this.$router.push({ path : url })
    }
  }
}
</script>

<style lang="scss" scoped>

  .tabs_box{
    // width: 1920px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0px 6px 12px 0px #e4e6eb; 
  }

  .list_box{
    display: flex;
    flex-wrap: wrap;
    margin-left: 80px;
    width: 910px;
    .list{
      width: 300px;
      margin-bottom: 49px;
      height: 125px;
      .list_title{
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #0063e6;
        border-bottom: 2px solid #cccccc;
        margin-right: 103px;
        padding-bottom: 15px;
      }
      .list_cont{
        padding-top: 15px;
        div{
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #00001e;
          margin-bottom: 16px;
          cursor: pointer;
        }
        div:hover{
         color: #0063e6;
        }
      }
    }
  }


:deep(.el-tabs__nav .el-tabs__active-bar){
  height: 2px !important;
  transform: translateY(0px);
  left: 20px;
  top: 60px;
  width: 120px;
}

:deep(.el-tabs__header){
  background: #fff;
  padding-left: 25%;
}
:deep(.el-tabs__content){
  background: #f1f2f5;
  padding-top: 25px;
}
:deep(.el-tabs--left .el-tabs__header.is-left){
  margin-right: 0px !important;
}

:deep(.el-tabs .el-tabs--left){
  // width: 1200px;
  margin: 0 auto;
}
:deep(.el-tabs--left .el-tabs__nav-wrap.is-left::after){
  width: 0px !important;
}
:deep(.base_head .el-carousel .el-carousel__container) {
    height: 502px;
}
:deep(.base_head .el-carousel) {
    height: 502px;
}

.LOT-title {
  color: #fff;
}
</style>