<template>
  <div class="wisdom_park">
    <el-container>
      <el-header>
        <v-head :active="10" :isBanner="false" :bannerData="bannerData"></v-head>
      </el-header>
      <el-main class="main-bottom">
        <div class="top_bg">
            <div class="top_text_mc">
                <div class="top_text_box">
                    <div class="top_text_tit">智慧养殖解决方案</div>
                    <div class="top_text_info">
                      实现将数据通过物联网传输给智慧养殖管理平台，平台对数据进行分析处理。生产者可以根据数据，自动智能控制养殖场的通风、温度调节等设施设备，实现精准作业，减少人工成本投入，提高养殖的科学性。
                    </div>
                    <div class="top_text_btn" @click="isShowFn">获取方案</div>
                </div>
            </div>
        </div>

        <div class="max_tit">管理方案</div>
        <div class="cont">
            <img src="@img/InsidePages/farming.png" alt="">
        </div>

        <div class="level top-fix" style="padding-bottom: 10px;">
            <div class="container">
              <div>
                <div class="max_tit">方案优势</div>
              </div>
              <div class="oddBox flexs">
                <div
                  v-for="(item, index) in oddsList"
                  :key="index"
                  class="oddItem bg-box"
                  @mouseenter.stop="mouseenter(index)"
                  @mouseleave.stop="mouseenter"
                 
                >
                  <div class="odd_img">
                    <img :src="item.img" alt />
                  </div>
                  <div class="odd_name">{{ item.name }}</div>
                  <div class="odd_title">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>

      </el-main>

      <planDialog :isShow="isShow" v-if="isShow" @closeTop="closeTop">
      </planDialog>
      <!-- <scroll-top></scroll-top> -->
      <el-footer style="background: rgb(244, 246, 249);">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import vHead from "../../components/head.vue";
import vFooter from "../../components/footer.vue";
import planDialog from "../../components/planDialog.vue";
export default {
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      isShow: false,
      activeName: "first",
      bannerData: [
        {
          title: "云百工业数字化平台",
          content:
            "帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
          button: "获取专属方案",
          img: require("@img/plan/gy_img_banner_a_r.png"),
          banner: "banners banners1",
        },
      ],
      oddsList: [
        {
          img: require('@img/InsidePages/ic_ny_zhyz_ys_aq.png'),
          name: '更安全',
          title: '24小时监测养殖舍内环境参数，出现异常及时进行报警，提高异常响应和处理能力，降低养殖风险'
        },
        {
          img: require('@img/InsidePages/ic_ny_zhyz_ys_gx.png'),
          name: '更高效',
          title: '为养殖舍提供均匀的温湿度和通风，提供健康舒适的生活环境，实现绿色节能和生产高效'
        },
        {
          img: require('@img/InsidePages/ic_ny_zhyz_ys_xj.png'),
          name: '更先进',
          title: '基于物联网的设备数据采集和控制，自动开关设备进行温湿度等调节，改善养殖环境促进健康成长'
        },
        {
          img: require('@img/InsidePages/ic_ny_zhyz_ys_jj.png'),
          name: '更经济',
          title: '采用自动化技术减少人工作业，做到少人值守降低人力成本，改善养殖环境促进商畜健康成长'
        }
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    isShowFn(){
        this.isShow = true;
    },
    closeTop(){
         this.isShow = false;
    }
  },
};
</script>

<style scoped lang="scss">
    .level{
      background: #f4f6f9;
      padding-top: 0 !important;
      padding-bottom: 80px;
    }    .main-bottom{
        padding-top: 80px;
        padding-bottom: 0px !important;
        .top_bg {
            // width: 1920px;
            height: 460px;
            background: url("../../../public/img/home/banner/banner2.png");
            background-repeat: no-repeat;
            background-size: cover;
            .top_text_mc{
                width: 1200px;
                margin: 0 auto;
                .top_text_box{
                    width: 502px;
                    height: 229px;
                    border-radius: 12px;
                    backdrop-filter: blur(4px);
                    padding: 38px 28px;
                    color: #fff;
                    position: relative;
                    top: 74px;
                    background: rgba(0,0,0,.2);
                    .top_text_tit{
                        font-size: 36px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 30px;
                    }
                    .top_text_info{
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        line-height: 26px;
                    }
                    .top_text_btn{
                        width: 124px;
                        height: 44px;
                        line-height: 44px;
                        border: 1px solid #ffffff;
                        border-radius: 4px;
                        position: absolute;
                        bottom: 20px;
                        cursor: pointer;
                    }
                }
            }
           
        }
        .max_tit{
            width: 100%;
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #141414;
            text-align: center;
            padding: 68px 0 48px 0;
        }
        .cont{
            display: flex;
            width: 1200px;
            margin: 0 auto;
            justify-content: space-between;
            padding-bottom: 80px;
            .right{
                background: linear-gradient(180deg,#f4f6f9, #ffffff);
                border: 2px solid #ffffff;
                border-radius: 4px;
                box-shadow: 5px 5px 15px 0px #ebeff5; 
                width: 343px;
                height: 250px;
                font-size: 16px;
                font-family: MiSans, MiSans-Normal;
                text-align: left;
                color: #141414;
                line-height: 36px;
                padding: 60px 25px;
            }
        }
    }
    .container {
    .oddBox {
      margin-top: 32px;
      padding-bottom: 80px;
    }
    .oddItem {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
      width: 276px;
      height: 283px;
      transition: all .5s;
      background: linear-gradient(180deg,#f2f6fc, #fcfcfc);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08); 
      .odd_img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        img {
          width: 100%;
          height: 100%;
          background-size: cover;
        }
      }
      .odd_name {
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        color: #161719;
        letter-spacing: -1px;
        position: relative;
        top: 10px;
      }
      // .odd_name::after{
      //   content: '';
      //   width: 30px;
      //   height: 4px;
      //   background: #0051de;
      //   position: absolute;
      //   bottom: -12px;
      //   left: 20px;
      //   border-radius: 2px;
      // }
      .lineB {
        display: none;
        opacity: 1;
        position: absolute;
        background: #1e77ec;
        bottom: -4px;
        width: 246px;
        height: 2px;
      }
      .odd_title {
        font-size: 16px;
        text-align: center;
        color: #302d2c;
        letter-spacing: 0px;
        padding: 0 32px;
        margin-top: 20px;
        line-height: 26px;
      }
    }
    .oddItem:hover{
      background: linear-gradient(180deg,#edf3fc, #fcfcfc);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.16); 
      transition: all .5s;
    }
    .oddActive {
      // border-bottom: 2px solid #1e77ec;
      background: linear-gradient(180deg, #edf2fa, #fefefe);
      box-shadow: 0px 5px 18px 0px #c7d6eb;
      
      .lineB {
        display: block;
        opacity: 1;
        position: absolute;
        background: #1e77ec;
        bottom: 0px;
        width: 246px;
        height: 2px;
      }
    }
  }
</style>
