<template>
  <div class="wisdom_park">
    <el-container>
      <el-header>
        <v-head :active="10"  :isBanner="false" ></v-head>
      </el-header>
      <el-main class="main-bottom">
        <div class="top_bg">
            <div class="top_text_mc">
                <div class="top_text_box">
                    <div class="top_text_tit">数据采集解决方案</div>
                    <div class="top_text_info">
                        专业的大数据采集平台，可配置化的数据采集，对采集过程实现可视化监控。 支持数据采集的同时，完成数据检核、转换、加载、整合等任务，实现数据的高效集成。
                    </div>
                    <div class="top_text_btn" @click="isShowFn">获取方案</div>
                </div>
            </div>
        </div>

       <div class="bg1">
            <div class="max_tit">解决方案</div>
            <div class="cont">
                <div class="cont_box">
                    <div class="cont_flex">
                        <div class="line" style="margin-right:32px;">
                            <img src="@img/InsidePages/5754.png" alt="">
                        </div>
                        <div class="cont_tb">
                            <div class="cont_tb_tit">工厂初代设备联网方案问题</div>
                            <div class="cont_tb_text">
                                初代工厂设备联网，采用网线接口、小交换机和大交换机，再连接到服务器进行统一采集和统一展现，会存在布线成本高、整体架构复杂、整体部署时间长、调整时间长等问题。
                            </div>
                        </div>
                    </div>

                     <div class="cont_flex">                        
                        <div class="cont_tb"  style="margin-right:32px;">
                            <div class="cont_tb_tit">云百解决方案</div>
                            <div class="cont_tb_text">
                                云百数据采集网关，通过采集器与生产设备的RJ45接口相连，采集设备正常生产和预警信息数据，通过物联网 4G cat 1 模组高速低延时传输到云服务器或者传统服务器，具有部署快、成本低、调整方便、网络结构简单、传输速度快的优势。极大节省了工厂前期搭建基础网络设施和后期的维护成本，极大提高了设备的部署速度。
                            </div>
                        </div>
                        <div>
                            <img src="@img/InsidePages/5752.png" alt="">
                        </div>
                    </div>
                   
                </div>
            </div>
       </div>

      
        <div class="bg2">
            <div class="max_tit">云百数据采集网关产品优势</div>
            <div class="cont">
                <div class="cont_box">
                    <div class="cont_flex">
                        <div class="list" v-for="(item,index) in list" :key="index">
                            <img :src="item.icon" alt=""> <span>{{item.text}}</span>
                        </div>
                    </div>
                </div>
            </div>
       </div>
       

       
      </el-main>

      <planDialog :isShow="isShow" v-if="isShow" @closeTop="closeTop">
      </planDialog>
      <!-- <scroll-top></scroll-top> -->
      <el-footer style="background: rgb(244, 246, 249);">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import vHead from "../../components/head.vue";
import vFooter from "../../components/footer.vue";
import planDialog from "../../components/planDialog.vue";
export default {
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      isShow: false,
      activeName: "first",
      bannerData: [
        {
          title: "云百工业数字化平台",
          content:
            "帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
          button: "获取专属方案",
          img: require("@img/plan/gy_img_banner_a_r.png"),
          banner: "banners banners1",
        },
      ],
      dataList:[
        {
            img:require("@img/InsidePages/image16.png")
        },
        {
            img:require("@img/InsidePages/image2.png")
        },
        {
            img:require("@img/InsidePages/image17.png")
        },
        {
            img:require("@img/InsidePages/image13.png")
        }
      ],
      list:[
        {
            icon:require("@img/InsidePages/7516.png"),
            text:'支持ADSL方式数据上云'
        },{
            icon:require("@img/InsidePages/7516.png"),
            text:'WAN/LAN双网口设计'
        },{
            icon:require("@img/InsidePages/7516.png"),
            text:'APN 专网'
        },{
            icon:require("@img/InsidePages/7516.png"),
            text:'设备历史数据查看'
        },{
            icon:require("@img/InsidePages/7516.png"),
            text:'边缘计算'
        },{
            icon:require("@img/InsidePages/7516.png"),
            text:'实时数据监控'
        },{
            icon:require("@img/InsidePages/7516.png"),
            text:'设备远程维护'
        },{
            icon:require("@img/InsidePages/7516.png"),
            text:'防火墙'
        },
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    isShowFn(){
        this.isShow = true;
    },
    closeTop(){
         this.isShow = false;
    }
  },
};
</script>

<style scoped lang="scss">
    .main-bottom{
        padding-top: 80px;
        padding-bottom: 0 !important;
        .top_bg {
            // width: 1920px;
            height: 460px;
            background: url("../../../public/img/InsidePages/banner001.png");
            background-repeat: no-repeat;
            background-size: cover;
            .top_text_mc{
                width: 1200px;
                margin: 0 auto;
                .top_text_box{
                    width: 502px;
                    height: 229px;
                    border-radius: 12px;
                    backdrop-filter: blur(4px);
                    padding: 38px 28px;
                    color: #fff;
                    position: relative;
                    background: rgba(0,0,0,.2);
                    top: 74px;
                    .top_text_tit{
                        font-size: 36px;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 20px;
                    }
                    .top_text_info{
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        line-height: 26px;
                    }
                    .top_text_btn{
                        width: 124px;
                        height: 44px;
                        line-height: 44px;
                        border: 1px solid #ffffff;
                        border-radius: 4px;
                        position: absolute;
                        bottom: 20px;
                        cursor: pointer;
                    }
                }
            }
           
        }
        .max_tit{
            width: 100%;
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #141414;
            text-align: center;
            padding: 68px 0 48px 0;
        }
        .min_tit{
            font-size: 24px;
            font-family: MiSans, MiSans-Medium;
            font-weight: 500;
            text-align: center;
            color: #323232;
            letter-spacing: 0.72px;
            width: 100%;
        }
        .cont{
            width: 1200px;
            margin: 0 auto;
            .cont_title{
                text-align: left;
                font-size: 18px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: left;
                color: #323232;
                line-height: 30px;
            }
            .cont_box{
                padding: 13px 34px 18px 34px;
            }
            .cont_flex{
                display: flex;
                justify-content: space-between;
                margin-bottom: 44px;
                .cont_tb{
                    width: 359px;
                    height: 306px;
                    background: linear-gradient(180deg,#f4f6f9, #ffffff);
                    border: 2px solid #ffffff;
                    border-radius: 4px;
                    box-shadow: 5px 5px 15px 0px #ebeff5;
                    .cont_tb_tit{
                        font-size: 19px;
                        font-weight: 700;
                        text-align: left;
                        color: #161719;
                        line-height: 25px;
                        padding: 24px;
                    }
                    .cont_tb_text{
                        font-size: 16px;
                        text-align: left;
                        color: #302d2c;
                        line-height: 24px;
                        padding: 24px;
                        padding-top: 0 !important;
                        width: 310px;
                    }
                }
                .list{
                    width: 260px;
                    height:62px;
                    padding: 22px;
                    background: linear-gradient(180deg,#f4f6f9, #ffffff);
                    border: 2px solid #ffffff;
                    border-radius: 4px;
                    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
                    text-align: left;
                    margin-left: 104px;
                    margin-bottom: 34px;
                    img{
                        width: 18px;
                        height: 18px;
                        margin-right: 6px;
                    }
                }
                .list:nth-child(3n + 1){
                    margin-left: 0;
                }
                .lists{
                   margin-top: 45px; 
                }
                .list:hover{
                    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 15%);
                }
            }
            .cont_box_b{
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: #141414;
                line-height: 26px;
            }
        }
    }
.bg2{
    background: #f4f6f9;
    .cont{
        .cont_box{
            .cont_flex{
                display: flex;
                margin-bottom: 44px;
                justify-content: flex-start;
                flex-wrap: wrap;
            }
        }
    }
   
}
 .triad-lunbo {
        height: 254px;
        width: 1200px;
        margin: 0 auto;
        background: linear-gradient(90deg, #30abeb, #1e77ec);
        margin-top: 13px;
        .triad-img {
          width: 339px;
          height: 247px;
          margin-top: -23px;
          margin-left: 30px;
        }
        h3 {
          color: #475669;
          font-size: 14px;
          opacity: 0.75;
          line-height: 150px;
          margin: 0;
          text-align: center;
        }
        .border {
          width: 572px;
          height: 1px;
          opacity: 0.4;
          background: #ffffff;
          margin-left: 39px;
          
        }
        h4 {
          font-size: 24px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin: 44px 40px 15px;
        }
        p {
          margin: 15px 40px 0;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 28px;
        }
      }
      .bg1{
        background: #fff;
      }
.platform{
    .cont{
        .cont_flex{
            display: flex;
            justify-content: space-between;
            
            .vs{
                width: 102px;
                height: 102px;
                background: #0063e6;
                border-radius: 50%;
                font-size: 56px;
                font-family: MiSans, MiSans-Regular;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
                text-align: center;
                line-height: 102px;
                margin-top: 330px;
            }
            .lists{
                background: #fff;
                img{
                    margin: 50px;
                }
            }
        }
        
    }
}
</style>
