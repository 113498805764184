<template>
  <div class="dialog">
    <el-dialog
      title="请完成安全验证"
      :visible.sync="slideShowDialog"
      width="354px"
      append-to-body
      style="margin-top:12vh"
    >
      <slide-verify
        :l="42"
        :r="10"
        :w="310"
        :h="155"
        slider-text="向右滑动"
        ref="slideblock"
        @success="onSuccess"
        @fail="onFail"
      >
      </slide-verify>
      <!-- <span slot="footer" class="dialog-footer"> </span> -->
    </el-dialog>
  </div>
</template>
<script>
// import mixin from "@/mixin/index.js";
export default {
  name: "slides",
//   mixins: [mixin],
  data() {
    return {
      // slideShow: false
    };
  },
  props: {
    slideShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slideShowDialog: {
      get() {
        return this.slideShow;
      },
      set(val) {
        this.$emit("update:slideShow", val);
      },
    },
  },
  watch: {
    slideShowDialog(val) {
        if(!val && this.$refs.slideblock) {
            this.$refs.slideblock.reset()
        }
    }
  },
  methods: {
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      // .then(_ => {
      //     done();
      // })
      // .catch(_ => {});
    },
    onSuccess() {
    //   this.CustomManager();
      this.$emit('submit')
    },
    onFail(){
        this.$refs.slideblock.reset()
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-verify {
  margin: 0 auto;
}
/* .dialog{
    padding-top: 27vh !important;
} */
.el-dialog__body{
    padding-top: 10px;
}
::v-deep .el-dialog__body{
    padding-top: 10px;
}
</style>