<template>
  <div class="demo-image__placeholder" style="background:#f4f6f9;">
    <div class="footerBgImg">
      <div class="footerBgImg_cont">
        <div class="footerBgImg_title">免费获取方案</div>
        <div class="footerBgImg_input">
          <div class="footer_input footer_input1">
            <span>姓名：</span>
            <input type="text" placeholder="" v-model="params.realName">
          </div>
          <div class="footer_input footer_input2">
            <span>手机：</span><input type="tel" placeholder="" v-model="params.mobile">
          </div>
          <div class="footer_input footer_input3">
            <span>留言：</span><input type="text" class="footer_input3_c" placeholder="" v-model="params.remark">
          </div>
          <div class="submit_btn" @click="submit">
            提交信息
            <img src="@img/home/ic_xq_qd@2x.png" alt />
          </div>
        </div>
      </div>
    </div>

    <div class="footer_item">
      <div class="footer_items" v-for="(item,index) in footerItem" :key="index">
        <img :src="item.img" class="footer_items_img">
        <div class="footer_items_title">{{item.title}}</div>
        <div class="footer_items_info">{{item.info}}</div>
      </div>
    </div>

    <div class="lines"></div>
   
    <div class="footer_info">
      <div class="footer_info_l">
        <div class="footer_logo"><img src="@img/logo.png" alt=""><b>|</b><span>让科技为生活赋能，让信息为发展加速。</span></div>
        <div class="footer_Yunbai_zh">Copyright © 2019 - 2025 Yunbai Technology. All Rights Reserved. 云百科技 版权所有</div>
        <!-- 老域名 -->
        <div class="footer_Yunbai_en"><a href="https://beian.miit.gov.cn"
          target="_blank">广东云百科技有限公司 | 粤ICP备2020131390号</a> | <router-link to="/info">信息中心</router-link> | <router-link to="/agreement">隐私协议</router-link></div>
          <!-- 新域名 -->
          <!-- <div class="footer_Yunbai_en"><a href="https://beian.miit.gov.cn"
          target="_blank">广东云百科技有限公司 | 粤ICP备2023108636号-1</a> | <router-link to="/info">信息中心</router-link> | <router-link to="/agreement">隐私协议</router-link></div> -->
      </div>
      <div class="footer_info_r">
        <div class="footer_info_r_1">
          <div class="footer_sever"> <img src="@img/component/ic_a_fwrx.png" alt=""> 服务热线：</div>
          <div class="footer_tel">0769 - 89008900</div>
        </div>
        <div class="footer_info_r_2">
          <div class="footer_sever"> <img src="@img/component/ic_a_gsdz.png" alt=""> 公司地址：</div>
          <div class="footer_addres">广东省东莞市南城街道中熙时代大厦18楼</div>
        </div>
      </div>
    </div>
    <v-slides :slideShow.sync="slideShows" @submit="CustomManager"></v-slides>
  </div>
</template>

<script>
import mixin from '@/mixin/index.js'
import vSlides from '../components/slide.vue'
export default {
  mixins: [mixin],
  components:{
    vSlides
  },
  data() {
    return {
      src: '',
      loading: false,
      slideShows:false,
      footerItem:[
       {
         img:require("@img/component/ic_home_sh_dz.png"),
         title:'定制专属方案',
         info:'为客户量身定制专属解决方案。'
       },
       {
         img:require("@img/component/ic_home_sh_zc.png"),
         title:'专业技术支撑',
         info:'专业的售前、售后客户经理1V1在线答疑。'
       },
       {
         img:require("@img/component/ic_home_sh_pc.png"),
         title:'快速排除故障',
         info:'反应迅速，及时解决故障。'
       },
       {
         img:require("@img/component/ic_home_sh_fw.png"),
         title:'7x24小时服务',
         info:'7×24小时在线，全年不间断提供服务。'
       }
      ]
    }
  },
  mounted() {
  },
  methods: {
    // goInfo(){
    //   this.$router.push({ path: './info'});
    // },
    // goAgreement(){
    //   this.$router.push({ path: './agreement' })
    // }
  }
}
</script>

<style lang="scss">
  .demo-image__placeholder{
    background: #f4f6f9;
  }
.footerBgImg{
  background: url("../../public/img/component/img_hqfa_bg.png");
  .footerBgImg_cont{
    width: 1200px;
    margin: 0 auto;
    .footerBgImg_title{
      height: 31px;
      font-size: 24px;
      font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 31px;
      padding: 32px 0 24px 0;
    }
    .footerBgImg_input{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding-bottom: 32px;
      .footer_input{
        height: 46px;
        padding: 0 10px;
        background: rgba(255,255,255,0.10);
        border: 1px solid #ffffff;
        border-radius: 4px;
        position: relative;
        display: flex;
        input{
          height: 46px;
          width: 70%;
          font-size: 16px;
          border: none;
          background: rgba(0,0,0,0);
          color: #fff;
          border: none !important;
          outline:none;
          padding-left: 56px;
        }
        .footer_input3_c{
          width: 88% !important;
        }
        input:focus-visible{
          border: none !important;
          outline:none;
          width: 88%;
          color: #fff;
        }
        span{
          display: inline-block;
          width: 48px;
          height: 19px;
          opacity: 0.8;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 20px;
          position: absolute;
          left: 10px;
          top: 14px;
        }
      }
      .footer_input1{width: 240px;overflow: hidden;}
      .footer_input2{width: 240px;overflow: hidden;}
      .footer_input3{width: 442px;overflow: hidden;}
      .submit_btn{
        width: 182px;
        height: 46px;
        background: linear-gradient(90deg,#0086e0 0%, #005de0 99%);
        border-radius: 4px;
        box-shadow: 0px 8px 8px 0px rgba(61,179,248,0.19); 
        font-size: 16px;
        border: 1px solid #7eb6ff;
        line-height: 48px;
        color: #fff;
        cursor: pointer;
        img{
          width: 15px;
          vertical-align: super;
        }
      }
    }
  }
}

.footer_item{
  width: 1200px;
  margin: 0 auto;
  padding: 32px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  .footer_items_img{
    display: inline-block;
    height: 56px;
    width: 56px;
    float: left;
  }
  .footer_items_title{
    font-size: 16px;
    width: 200px;
    font-family: 'PingFang SC, PingFang SC-Regular';
    text-align: left;
    color: #141414;
    line-height: 26px;
  }
  .footer_items_info{
    font-size: 12px;
    width: 300px;
    font-family: 'PingFang SC, PingFang SC-Regular';
    text-align: left;
    color: #727f8f;
    line-height: 20px;
  }
}
.lines{
  width: 1200px;
  border: 1px solid #e8e8e8;
  margin: 0 auto;
  margin-bottom: 48px;
}

.footer_info{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  // margin-bottom: 48px;
  padding-bottom: 48px;
  .footer_info_l{
    .footer_logo{
      text-align: left;
      margin-bottom: 21px;
      img{
        height: 32px;
        display: inline-block;
      }
      span{
        font-size: 17px;
        font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
        text-align: left;
        color: #141414;
        line-height: 38px;
        font-weight: 400;
        margin-left: 12px;
      }
      b{
        width: 10px;
        height: 22px;
        font-size: 17px;
        font-weight: 400;
        text-align: left;
        color: #e0e0e0;
        line-height: 31px;
        margin-left: 12px;
      }
    }
    .footer_Yunbai_zh{
      font-size: 14px;
      font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
      font-weight: 400;
      text-align: left;
      color: #4e5c6d;
      line-height: 22px;
      margin-bottom: 17px;
    }
    .footer_Yunbai_en{
      font-size: 14px;
      font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
      font-weight: 400;
      text-align: left;
      color: #4e5c6d;
      line-height: 22px;
      a{
        color: #4e5c6d;
      }
      span{
        cursor: pointer;
      }
    }
  }
  .footer_info_r{
    .footer_info_r_1{
      .footer_sever{
        font-size: 14px;
        font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
        font-weight: 400;
        text-align: left;
        color: #161719;
        line-height: 24px;
        img{
          width: 18px;height: 18px;vertical-align: sub;
        }
      }
      .footer_tel{
        font-size: 29px;
        font-family:'Microsoft YaHei, Microsoft YaHei-Regular';
        font-weight: 400;
        text-align: left;
        color: #0063e6;
        line-height: 32px;
        letter-spacing: 4.06px;
        margin-bottom: 18px;
      }
    }
    .footer_info_r_2{
      .footer_sever{
        font-size: 14px;
        font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
        font-weight: 400;
        text-align: left;
        color: #161719;
        line-height: 24px;
        img{
          width: 18px;height: 18px;vertical-align: sub;
        }
      }
      .footer_addres{
        font-size: 14px;
        font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 22px;
      }
    }
   
  }
}
a{color: #000000;}
</style>