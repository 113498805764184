<template>
  <div class="wisdom_park">
    <el-container>
      <el-header>
        <v-head :active="10"  :isBanner="false" ></v-head>
      </el-header>
      <el-main class="main-bottom">
        <div class="top_bg">
            <div class="top_text_mc">
                <div class="top_text_box">
                    <div class="top_text_tit">两客一危一重解决方案</div>
                    <div class="top_text_info">
                       专为客户解决终端设备连接多个监管平台及网络改造的问题，可满足道路运输行业协会及各级政府监管平台对于车载终端接入的要求，实现一对多，同时提供更加直观和便利的管理功能，降低客户的流量使用成本。
                    </div>
                    <div class="top_text_btn" @click="isShowFn">获取方案</div>
                </div>
            </div>
        </div>

       <div class="bg1">
            <div class="max_tit">背景介绍</div>
            <div class="cont">
                <div class="cont_title">
                    根据国务院相关规定，自2011年起，新出厂的“两客一危一重”车辆，在车辆出厂前应安装符合相关标准的卫星定位装置，并接入全国重点营运车辆联网联控系统，保证车辆监控数据准确、实时、完整地传输，确保车载卫星定位装置工作正常、数据准确、监控有效。
                </div>
                <div class="cont_box">
                    <div class="cont_flex">
                        <div class="list" v-for="(item,index) in dataList" :key="index">
                            <img :src="item.img" class="img">
                        </div>
                    </div>
                    <div class="cont_box_b">
                        两客一危一重货，是指从事旅游的包车、三类以上班线客车、运输危险化学品、烟花爆竹、民用爆炸物品的道路专用车辆和重型货车。 这几类型车辆在道路上行驶存在事故率高，伤亡率高，事故损失大等特征，需要进行重点监控。
                    </div>   
                </div>
            </div>
       </div>

        <div class="bg2">
            <div class="max_tit">解决方案</div>
            <div class="cont">
                <div class="cont_box" style="margin-top: 0 !important;">
                     <img src="@img/InsidePages/5627.png" alt="">
                </div>
            </div>
       </div>

       <div class="bg1">
            <div class="max_tit">平台介绍</div>
            <div class="triad-lunbo">
                  <el-row>
                    <el-col :span="10">
                      <img
                        src="@img/serve/ic_home_pt_gy_yj.png"
                        class="triad-img"
                        style
                      />
                    </el-col>
                    <el-col :span="14">
                      <h4>车载视频分发平台介绍</h4>
                      <div class="border"></div>
                      <p>
                        专为客户解决终端设备连接多个监管平台及网络改造的问题，可满足道路运输行业协会及各级政府监管平台对于车载终端接入的要求，同时提供更加直观和便利的管理功能，降低客户的流量使用成本，解决原视频监控平台转发通道的限制问题。
                      </p>
                    </el-col>
                  </el-row>
                </div>
       </div>

        <div class="bg2 platform">
            <div class="max_tit">平台优势</div>
            <div class="cont">
                <div class="cont_flex">
                    <div class="lists">
                        <img src="@img/InsidePages/5644.png">
                    </div>
                    <div class=" vs">
                        vs
                    </div>
                    <div class="lists">
                        <img src="@img/InsidePages/5642.png">
                    </div>
                </div>            
            </div>
        </div>
      </el-main>

      <planDialog :isShow="isShow" v-if="isShow" @closeTop="closeTop">
      </planDialog>
      <!-- <scroll-top></scroll-top> -->
      <el-footer style="background: rgb(244, 246, 249);">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import vHead from "../../components/head.vue";
import vFooter from "../../components/footer.vue";
import planDialog from "../../components/planDialog.vue";
export default {
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      isShow: false,
      activeName: "first",
      bannerData: [
        {
          title: "云百工业数字化平台",
          content:
            "帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
          button: "获取专属方案",
          img: require("@img/plan/gy_img_banner_a_r.png"),
          banner: "banners banners1",
        },
      ],
      dataList:[
        {
            img:require("@img/InsidePages/image16.png")
        },
        {
            img:require("@img/InsidePages/image2.png")
        },
        {
            img:require("@img/InsidePages/image17.png")
        },
        {
            img:require("@img/InsidePages/image13.png")
        }
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    isShowFn(){
        this.isShow = true;
    },
    closeTop(){
         this.isShow = false;
    }
  },
};
</script>

<style scoped lang="scss">
    .main-bottom{
        padding-top: 80px;
        padding-bottom: 0 !important;
        .top_bg {
            // width: 1920px;
            height: 460px;
            background: url("../../../public/img/home/banner/banner4.png");
            background-repeat: no-repeat;
            background-size: cover;
            .top_text_mc{
                width: 1200px;
                margin: 0 auto;
                .top_text_box{
                    width: 502px;
                    height: 229px;
                    border-radius: 12px;
                    backdrop-filter: blur(4px);
                    padding: 38px 28px;
                    color: #fff;
                    position: relative;
                    background: rgba(255,255,255,.1);
                    top: 74px;
                    .top_text_tit{
                        font-size: 36px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 20px;
                    }
                    .top_text_info{
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        line-height: 26px;
                    }
                    .top_text_btn{
                        width: 124px;
                        height: 44px;
                        line-height: 44px;
                        border: 1px solid #ffffff;
                        border-radius: 4px;
                        position: absolute;
                        bottom: 20px;
                        cursor: pointer;
                    }
                }
            }
           
        }
        .max_tit{
            width: 100%;
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #141414;
            text-align: center;
            padding: 68px 0 48px 0;
        }
        .min_tit{
            font-size: 24px;
            font-family: MiSans, MiSans-Medium;
            font-weight: 500;
            text-align: center;
            color: #323232;
            letter-spacing: 0.72px;
            width: 100%;
        }
        .cont{
            width: 1200px;
            margin: 0 auto;
            .cont_title{
                text-align: left;
                font-size: 18px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: left;
                color: #323232;
                line-height: 30px;
            }
            .cont_box{
                background: #f4f6f9;
                padding: 43px 34px 18px 34px;
                margin-top: 31px;
            }
            .cont_flex{
                display: flex;
                justify-content: space-between;
                .list{
                    width: 270px;
                    height: 161px;
                    margin-bottom: 30px;
                }
                .lists{
                   margin-top: 45px; 
                }
                .list:hover{
                    box-shadow: 5px 5px 5px #8f8e8e;
                }
            }
            .cont_box_b{
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: left;
                color: #141414;
                line-height: 26px;
            }
        }
    }
.bg2{
    background: #f4f6f9;
    margin-top: 68px;
   
    .cont{
        padding-bottom: 63px;
        .rights{
            width: 385px;
            height: 484px;
            background: #ebeff5;
            border-radius: 8px;
            img{
                width: 326px;
                height: 237px;
                margin: 32px 29px;
            }
            div{
                width: 316px;
                height: 138px;
                font-size: 16px;
                font-family: MiSans, MiSans-Normal;
                font-weight: normal;
                text-align: center;
                color: #0063e6;
                line-height: 28px;
                letter-spacing: 0.48px;
                margin: 0 auto;
            }
        }
    }
}
 .triad-lunbo {
        height: 254px;
        width: 1200px;
        margin: 0 auto;
        background: linear-gradient(90deg, #30abeb, #1e77ec);
        margin-top: 13px;
        .triad-img {
          width: 339px;
          height: 247px;
          margin-top: -23px;
          margin-left: 30px;
        }
        h3 {
          color: #475669;
          font-size: 14px;
          opacity: 0.75;
          line-height: 150px;
          margin: 0;
          text-align: center;
        }
        .border {
          width: 572px;
          height: 1px;
          opacity: 0.4;
          background: #ffffff;
          margin-left: 39px;
          
        }
        h4 {
          font-size: 24px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin: 44px 40px 15px;
        }
        p {
          margin: 15px 40px 0;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 28px;
        }
      }
      .bg1{
        background: #fff;
      }
.platform{
    .cont{
        .cont_flex{
            display: flex;
            justify-content: space-between;
            .vs{
                width: 102px;
                height: 102px;
                background: #0063e6;
                border-radius: 50%;
                font-size: 56px;
                font-family: MiSans, MiSans-Regular;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
                text-align: center;
                line-height: 102px;
                margin-top: 330px;
            }
            .lists{
                background: #fff;
                img{
                    margin: 50px;
                }
            }
        }
    }
}
</style>
