<template>
  <div class="indusAuto">
    <el-container>
      <el-header>
        <v-head :active="2" :bannerData="bannerData" indicator="none" :isGif="false"></v-head>
      </el-header>
      <el-main>
        <div class="">
          <!-- <div class="level" style="padding-top: 0">
            <div class="container">
              <div
                class="head_title"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-once="true"
              >
                <div class="max_tit">为客户提供全方位服务支持</div>
              </div>
              <div
                class="oddBox flexs"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-once="true"
              >
                <div
                  class="outside"
                  v-for="(item, index) in oddsList"
                  :key="index"
                >
                  <div class="oddItem bg-box oddItem_front">
                    <div class="odd_img">
                      <img :src="item.img" alt />
                    </div>
                    <div class="odd_name">{{ item.name }}</div>
                  </div>
                  <div class="oddItem bg-box oddItem_back">
                    <div class="odd_name">{{ item.name }}</div>
                    <div class="odd_title">{{ item.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="level" style="padding-top: 20px">
            <div class="container">
              <div class="head_title">
                <div class="max_tit">为客户提供全方位服务支持</div>
              </div>
              <div class="oddBox flexs">
                <div class="outside" v-for="(item, index) in oddsList" :key="index">
                  <div class="oddItem bg-box oddItem_front">
                    <img src="@img/img_gy_fw_ys.png" class="bg_pic_outside">
                    <div class="odd_img">
                      <img :src="item.img" alt />
                    </div>
                    <div class="odd_name">{{ item.name }}</div>
                    <div class="oddLine"></div>
                    <div class="odd_title">{{ item.title }}</div>
                  </div>
                  <!-- <div class="oddItem bg-box oddItem_back">
                  <div class="odd_name">{{ item.name }}</div>
                  <div class="oddLine"></div>
                  <div class="odd_title">{{ item.title }}</div>
                </div> -->
                </div>
              </div>
            </div>
          </div>

          <div class=" management">
            <div class="container" style="position: relative">
              <div class="max_tit" style="padding-bottom: 48px;padding-top:68px;">
                领先技术平台 助力企业智能化升级
              </div>
              <div class="bg_pic1 df" style="padding-bottom: 88px !important">
                <div class="container tab-padding">
                  <div class="dfr" v-for="(item, index) in tabsData" :key="index">
                    <div class="dfr_f">
                      <div>
                        <img :src="item.img" alt="" />
                      </div>
                      <div class="dfr_f_r">
                        <div class="dfr_title">{{ item.title }}</div>
                        <div style="width: 573px;height:1px;margin: 16px 0;background: #0063e6;"></div>
                        <div class="dfr_text">
                          {{ item.text }}
                        </div>
                        <div class="dfr_f_r_bg_pic">
                          <img src="@img/home/ic_gy_d_cl.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="dfr_b">
                      <div class="dfr_b_list" v-for="(items, indexs) in item.data" :key="indexs">
                        <div class="dfr_b_list_l">
                          <img :src="items.img" alt="" />
                        </div>
                        <div class="dfr_b_list_r">
                          <div class="dfr_b_list_r_title">
                            {{ items.titles }}
                          </div>
                          <div class="dfr_b_list_r_text">{{ items.text }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="level" style="padding-top: 68px">
            <div class="container">
              <div class="head_title">
                <div class="max_tit">云百拥有高学历、技术力量雄厚、项目经验丰富的研发核心团队</div>
              </div>
              <div class="oddBox flexs">
                <div class="outside" v-for="(item, index) in oddsList2" :key="index"
                  style="width: 276px;height: 215px;">
                  <div class="oddItem bg-box oddItem_front" style="width: 276px;height: 215px;">
                    <!-- <img src="@img/img_gy_fw_ys.png" class="bg_pic_outside"> -->
                    <div class="odd_img">
                      <img :src="item.img" alt />
                    </div>
                    <div class="odd_name">{{ item.name }}</div>
                    <div class="oddLine"></div>
                    <div class="odd_title">{{ item.title }}</div>
                  </div>
                </div>
              </div>
              <div class="hxBox">
                <div class="hxItem">
                  <div class="hxItem_l ml42">
                    <img src="@img/indusAuto/icon/ic_gy_fw_td.png" alt="">
                    <div class="hxItem_l_t">
                      <div class="title">专业技术团队</div>
                      <div class="text">平均5年以上开发经验，均就职过大型互联网公司。</div>
                    </div>
                  </div>
                  <div class="hxItem_r mr42">
                    <div class="hxItem_l_t">
                      <div class="title">高品质定制</div>
                      <div class="text">20多主流行业的深度研究与服务经验，提供全面、专业的深度定制化开发。</div>
                    </div>
                    <img src="@img/indusAuto/icon/ic_gy_fw_pz.png" alt="">
                  </div>
                </div>
                <div class="hxItem">
                  <div class="hxItem_l">
                    <img src="@img/indusAuto/icon/ic_gy_fw_xjb.png" alt="">
                    <div class="hxItem_l_t">
                      <div class="title">性价比更高</div>
                      <div class="text">多年的定制服务经验，开发流程更具效率，做到行业性价比更高。</div>
                    </div>
                  </div>
                  <div class="hxItem_r">
                    <div class="hxItem_l_t">
                      <div class="title">质量保证与测试</div>
                      <div class="text">提供全面的系统缺陷检测提高测试效率、扩大测试范围。</div>
                    </div>
                    <img src="@img/indusAuto/icon/ic_gy_fw_zl.png" alt="">
                  </div>
                </div>
                <div class="hxItem">
                  <div class="hxItem_l ml42">
                    <img src="@img/indusAuto/icon/ic_gy_fw_zq.png" alt="">
                    <div class="hxItem_l_t">
                      <div class="title">快速的周期</div>
                      <div class="text">规范高效的设计、开发、测试流程，严格控制项目进度，上线周期更短。</div>
                    </div>
                  </div>
                  <div class="hxItem_r mr42">
                    <div class="hxItem_l_t">
                      <div class="title">优质的售后服务</div>
                      <div class="text">运营培训、上线维护、软著申请、渠道发布，还能提供更多售后服务。</div>
                    </div>
                    <img src="@img/indusAuto/icon/ic_gy_fw_sh.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>



          <!-- 云百智能网关助力IoT设备连接 -->
          <div class="level bg_color2">
            <div class="container">
              <div class="head_title">
                <div class="max_tit">云百智能网关</div>
              </div>
              <div class="flexs plc_box">
                <div class="plc_l">
                  <div class="plc_l_t">
                    <img src="@img/serve/iot/plc.png" alt="">
                    <div>云百PLC智能网关</div>
                  </div>
                  <div class="plc_l_list">
                    <div class="plc_l_item">
                      <div class="plcTitle">工作电压</div>
                      <div class="plcText">DC 9V~36V</div>
                    </div>
                    <div class="plc_l_item">
                      <div class="plcTitle">工作电流</div>
                      <div class="plcText">180mA@12V，最大：300mA @12V</div>
                    </div>
                    <div class="plc_l_item">
                      <div class="plcTitle">工作温度</div>
                      <div class="plcText">默认-35℃- 75℃</div>
                    </div>
                    <div class="plc_l_item">
                      <div class="plcTitle">存储温度</div>
                      <div class="plcText">-40℃- 125℃</div>
                    </div>
                    <div class="plc_l_item">
                      <div class="plcTitle">有线网口</div>
                      <div class="plcText">WAN口/LAN口</div>
                    </div>
                  </div>
                </div>
                <div class="plc_r">
                  <div class="plc_r_tit">
                    功能特点
                  </div>
                  <div class="plc_r_list">
                    <div class="plc_r_item" v-for="(item,index) in plc_r_item" :key="index">
                      <img src="@img/InsidePages/7516.png" class="icons">
                      <div>{{ item.text }}</div>
                      <img :src="item.img" class="icons1">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="level bg_color3" style="padding-bottom: 50px">
            <div class="container">
              <div class="head_title">
                <div class="max_tit" style="padding-bottom: 48px;">为特定的行业提供定制数字化企业解决方案</div>
                <!-- <div class="min_tit">
                  为特定的行业提供定制数字化企业解决方案
                </div> -->

              </div>
            </div>
            <div>
              <!-- planData -->
              <div class="line_title"></div>
              <div class="planList">
                <div v-for="(item, index) in planData" :key="index" class="planList_item">
                  <img :src="item.img" alt="" />
                  <div class="planList_b">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="level bg_color2" style="padding-top: 64px">
            <div class="">
              <div class="head_title">
                <div class="max_tit" style="padding-bottom: 20px;">成功案例</div>
              </div>
              <div class="case">
                <el-tabs v-model="activeName" class="case_tabs">
                  <el-tab-pane :name="item.name" v-for="(item, index) in caseLists" :key="index" :label="item.lable">
                    <div class="case_box">
                      <div class="case_left">
                        <img :src="item.img" alt="" />
                      </div>
                      <div class="case_right">
                        <div class="title">{{ item.title }}</div>
                        <div class="line"></div>
                        <div class="case_cont">
                          <label>客户痛点</label>
                          <div>{{ item.painSpot }}</div>
                        </div>
                        <div class="case_cont">
                          <label>赋能过程</label>
                          <div>{{ item.process }}</div>
                        </div>


                        <div class="case_cont case_cont_flex" style="margin-top: 16px">
                          <div>
                            <span>{{ item.labelsName[0] }}</span><b>{{ item.labels[0] }}</b><i>%</i>
                            <img class="icon_up" src="@img/serve/up_icon.png" v-if="item.styleName[0] === 'icon_up'" />
                            <img class="icon_up" src="@img/serve/down_icon.png"
                              v-if="item.styleName[0] === 'icon_down'" />
                          </div>
                          <div>
                            <span>{{ item.labelsName[1] }}</span><b>{{ item.labels[1] }}</b><i>%</i>
                            <img class="icon_up" src="@img/serve/up_icon.png" v-if="item.styleName[1] === 'icon_up'" />
                            <img class="icon_up" src="@img/serve/down_icon.png"
                              v-if="item.styleName[1] === 'icon_down'" />
                          </div>
                          <div>
                            <span>{{ item.labelsName[2] }}</span><b>{{ item.labels[2] }}</b><i>%</i>
                            <img class="icon_up" src="@img/serve/up_icon.png" v-if="item.styleName[2] === 'icon_up'" />
                            <img class="icon_up" src="@img/serve/down_icon.png"
                              v-if="item.styleName[2] === 'icon_down'" />
                          </div>
                        </div>


                        
                        <div class="case_cont case_cont_btn">
                          <div class="details_btn" :data-url="item.path">
                            <router-link :to="item.path" class="details_btn" style="margin-right: 0;"> 了解详情 </router-link>
                          </div>
                          <div class="custom_btn" @click="isShowFn">
                            定制方案
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <plan-dialog :isShow="isShow" v-if="isShow" @closeTop="closeTop"></plan-dialog>
      <el-footer style="background: #f4f6f9">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
    <!-- <about-box></about-box> -->
  </div>
</template>

<script>
import vHead from "../components/head.vue";
import planDialog from "../components/planDialog.vue";
import vFooter from "../components/footer.vue";
import { getIndus } from "@/api/home";
export default{
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      manageActive: "",
      activeName: "0",
      isShow: false,
      palnActive: "",
      inducActive1: "",
      inducActive: "",
      bannerData: [
        {
          title: "服务企业数字化转型",
          content:
            "帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
          button: "获取专属方案",
          img: require("@img/plan/gy_img_banner_a_r.png"),
          banner: "indusAuto_bg",
        },
      ],
      oddsList: [
        {
          img: require("@img/serve/ic_home_c_1.png"),
          name: "私有化部署",
          title: "私有化部署，内网访问严格的权限监控。",
        },
        {
          img: require("@img/serve/ic_home_c_2.png"),
          name: "远程调试",
          title: "可对设备进行远程在线调试，降低人员成本，提高调试效率。",
        },
        {
          img: require("@img/serve/ic_home_c_3.png"),
          name: "打通数据孤岛",
          title: "搭建企业的数据湖，打通企业内、企业间的数据孤岛。",
        },
        {
          img: require("@img/serve/ic_home_c_4.png"),
          name: "数据可视化",
          title: "让企业的管理和执行变得透明化、简单化、可视化以及痕迹化。",
        },
        {
          img: require("@img/serve/ic_gy_zc.png"),
          name: "全天候支持",
          title: "在产品、机器和设备的整个生命同期内提供快速而高效的支持。",
        },
      ],
      oddsList2: [
        {
          img: require("@img/indusAuto/icon/ic_gy_hx_ryj.png"),
          name: "软硬件定制开发",
          title: "为客户打造智能化硬件、集成控制软件和云端数据平台。",
        },
        {
          img: require("@img/indusAuto/icon/ic_gy_hx_xt.png"),
          name: "系统定制开发",
          title: "搭建层次分明、多端同步、协同操作无纸化OA、CRM、ERP系统。",
        },
        {
          img: require("@img/indusAuto/icon/ic_gy_hx_app.png"),
          name: "APP定制开发",
          title: "为多行业客户输出高价值的数字化移动应用解决方案。",
        },
        {
          img: require("@img/indusAuto/icon/ic_gy_hx_xcx.png"),
          name: "小程序定制开发",
          title: "可根据客户需求进行定制开发,提供源代码，可二次开发。",
        },
      ],
      tabsData: [
        {
          title: "云百工业数字化平台",
          text: '通过强大的底层能力，结合平台对基本生产数据进行实时采集与存储，通过数据挖掘、分析等技术手段，优化生产过程，完善科学管控，达到降本增效的目的，同时对产品功能、性能等进行分析改进和创新，更好的满足工业企业的个性化需求。',
          img: require("@img/home/img_home_c_sy.png"),
          data: [
            {
              img: require("@img/home/icon/ic_home_pt_gy_a.png"),
              titles: "数据采集",
              text: "采集设备的实时数据，集成信息系统业务数据。",
            },
            {
              img: require("@img/home/icon/ic_home_pt_gy_b.png"),
              titles: "设备监控",
              text: "对设备资产进行可视化呈现和实时管控运行状态，预警处理。",
            },
            {
              img: require("@img/home/icon/ic_home_pt_gy_d.png"),
              titles: "高性能",
              text: "提供高并发、高可靠和低时延的设备控制。",
            },
            {
              img: require("@img/home/icon/ic_home_pt_gy_c.png"),
              titles: "生产排程",
              text: "通过对作业、投产、备料等环节建模，最优资源调度以应对复杂的订单计划。",
            },
            {
              img: require("@img/home/icon/ic_home_pt_gy_e.png"),
              titles: "权限管控",
              text: "授权机制、资源操作权限与数据行权限灵活设置，充分保证您的数据安全。",
            },
            {
              img: require("@img/home/icon/ic_home_pt_gy_f.png"),
              titles: "预测性维护",
              text: "通过对关键的设备运行参数进行建模，监控机器的运行状态、预测维护时间。",
            },
          ],
        },
      ],
      plc_r_item: [
        {
          text: '支持ADSL方式数据上云',
          img: require("@img/serve/iot/ic_iot_wg_a.png"),
        },
        {
          text: '边缘计算',
          img: require("@img/serve/iot/ic_iot_wg_a_4.png"),
        },
        {
          text: 'WAN/LAN双网口设计',
          img: require("@img/serve/iot/ic_iot_wg_a_2.png"),
        },
        {
          text: '实时数据监控',
          img: require("@img/serve/iot/ic_iot_wg_a_6.png"),
        },
        {
          text: 'APN 专网',
          img: require("@img/serve/iot/ic_iot_wg_a_1.png"),
        },
        {
          text: '设备远程维护',
          img: require("@img/serve/iot/ic_iot_wg_a_5.png"),
        },
        {
          text: '设备历史数据查看',
          img: require("@img/serve/iot/ic_iot_wg_a_3.png"),
        },
        {
          text: '防火墙',
          img: require("@img/serve/iot/ic_iot_wg_a_7.png"),
        }
      ],
      carList: [
        {
          label: "智能制造",
          name: "first",
          content:
            "集绿色、智能等新兴技术于一体，构建一个高效节能、绿色环保、环境舒适的生产制造管理控制系统，其核心是将生产系统及过程用网络化分布式生产设施来实现。",
          img: require("@img/plan/img_gy_e_a.png"),
        },
        {
          label: "智慧港口",
          name: "second",
          content:
            "推进落实“互联网＋港口”战略应用的同时，利用RFID、云计算、二维码及人工智能等物联网、移动互联网技术，实现集疏港车辆APP预约、闸口自动核验、车辆智能衡重、信息共享等一系列功能。",
          img: require("@img/plan/img_gy_e_b.png"),
        },
        {
          label: "智慧园区",
          name: "third",
          content:
            "以云技术及数字化为基础，通过功能合理化、运服体系化、资源专业化等建设，提升园区管理服务部门的运营管理及服务能力。",
          img: require("@img/plan/img_gy_e_c.png"),
        },
        {
          label: "智慧仓储",
          name: "fourth",
          content:
            "在仓储管理业务流程再造基础上，利用RFID射频识别、网络通信、信息系统应用等信息化技术及先进的管理方法，实现入库、出库、盘库、移库管理的信息自动抓取、自动识别、自动预警及智能管理功能，以降低仓储成本、提高仓储效率、提升仓储智慧管理能力。",
          img: require("@img/plan/img_gy_e_d.png"),
        },
        {
          label: "智慧养殖",
          name: "fifth",
          content:
            "集养殖舍内外硬件、场区网络、生活区中控管理软件、监控平台和云服务等搭建于一体，全方位实现养殖场智能化管理，为养殖动物打造健康、舒适、安全的生活环境，为企业管理者提供高效、可靠和综合的管理手段，提升企业数字化和智能化水平。",
          img: require("@img/plan/img_gy_e_e.png"),
        },
        {
          label: "智慧水务",
          name: "sixth",
          content:
            "智慧水务解决方案是以节约水资源、保护生态环境、保障人民群众用水安全为核心目标,实现水务管理的自动化、数字化、协同化、智能化、科学决策化,全面提升水务管理的效率和效益。",
          img: require("@img/plan/img_gy_e_f.png"),
        },
        {
          label: "智慧电厂",
          name: "seventh",
          content:
            "智慧电厂是通过采用先进的大数据、云计算、物联网、边缘计算等技术,实现生产信息与管理信息的智慧,实现人、技术、经营目标和管理方法的集成。",
          img: require("@img/plan/img_gy_e_g.png"),
        },
        {
          label: "智慧农业",
          name: "eighth",
          content:
            "智慧农业系统解决方案是专门为农业温室、农业环境控制、气象观测而开发生产的环境自动控制系统。为用户提供一个可远程、自动化控制的大棚环境，并提供线上线下一条龙运维服务，能够帮助提高用户工作效率。",
          img: require("@img/plan/img_gy_e_h.png"),
        },
      ],
      hotList: [
        {
          img: require("@img/indusAuto/ic_gy_d_a@2x.png"),
          title: "数据采集",
          content: "采集设备的实时数据集成信息系统业务数据",
          path: "",
        },
        {
          img: require("@img/indusAuto/ic_gy_d_b@2x.png"),
          title: "设备监控",
          content: "对设备资产进行可视化呈现和实时控制",
          path: "",
        },
        {
          img: require("@img/indusAuto/ic_gy_d_c@2x.png"),
          title: "生产排程",
          content:
            "通过对作业、投产、备料等环节建模，最优资源调度以应对复杂的订单计划",
          path: "",
        },
        {
          img: require("@img/indusAuto/ic_gy_d_d@2x.png"),
          title: "高性能",
          content: "提供高并发、高可靠和低时延的设备控制",
          path: "",
        },
        {
          img: require("@img/indusAuto/ic_gy_d_e@2x.png"),
          title: "权限管控",
          content:
            "基于角色和用户的授权机制、资源操作权限与数据行权限灵活设置，充分保证您的数据安全",
          path: "",
        },
        {
          img: require("@img/indusAuto/ic_gy_d_f@2x.png"),
          title: "预测性维护",
          content:
            "通过对关键的设备运行参数进行建模，判断机器的运行状态、预测维护时间",
          path: "",
        },
      ],
      platformList: [
        {
          img: require("@img/plan/img_gyc_a.png"),
          name: "数字化",
          title:
            "云百工业数字化平台支持整个工业数字化生态系统，提供了设备资产的连接，集成和支持，帮助企业实现设备，信息的数字化",
        },
        {
          img: require("@img/plan/img_gyc_b.png"),
          name: "网络化",
          title:
            "云百工业数字化平台帮助企业空设备与设备，设备与人之前交互，提高生产数据在不同部门之间的流通效率，打破企业生产过程中的数据孤岛",
        },
        {
          img: require("@img/plan/img_gyc_c.png"),
          name: "智能化",
          title:
            "云百工业数字化平台帮助企业快速完成生产智能化和提供智能服务，加快工业企业运模式，生产模式，商业模式的创新",
        },
      ],
      caseLists: [
        {
          lable: "智慧港口",
          title: "5G数字散装港口",
          name: "0",
          img: require("@img/serve/193.png"),
          painSpot:
            "港口普遍存在智慧化程度不高，生产效率低、人力成本较大、安全隐患不断等问题，极需更自动化、无人化、智慧化的生产管理方式来解决。",
          process:
            "项目为散装港口码头提供从0至1的智慧化转型方案，包含从货料运输、客户订购结算、货车装卸、货物监控、货车进出等全链路环节的软硬件开发服务，助力传统港口码头实现智能监管、智能服务、自动装卸、各信息系统数据打通，完成了机械化到智能化的提升。",
          labels: ["20", "15", "18"],
          styleName: ['icon_up', 'icon_down', 'icon_up'],
          labelsName: ["能效提升：", "成本下降：", "安全提升："],
          path: "./InsidePages/port",
        },
        {
          lable: "智慧园区",
          title: "5G智慧园区",
          name: "1",
          img: require("@img/plan/asdfasdf2.png"),
          painSpot:
            "园区的资源少，管理服务不够全面，产业链服务难，经济增长慢。运营工作繁多，运营难度大。",
          process:
            "专注于全方位智能化应用，以账户、支付、安全为基础，涵盖智慧门禁、智慧访客、智慧餐饮、智慧停车缴费、物业管理和缴费、智慧巡检等系统。通过数据大屏集中展示，实现数据全方位可视化管理，助力园区实现全新智慧化升级、高效便捷化管理和智能化体验。",
          labels: ["20", "15", "18"],
          styleName: ['icon_up', 'icon_up', 'icon_up'],
          labelsName: ["智慧管理：", "服务升级：", "深掘潜力："],
          path: "./InsidePages/wisdom_park",
        },
        {
          lable: "智慧养殖",
          title: "5G智慧养殖",
          name: "2",
          img: require("@img/plan/asdfs.png"),
          painSpot:
            "面临着管理粗放、效率低下、成本偏高、环境污染、养殖过程数据采集困难、养殖智能化和信息化程度低等问题。",
          process:
            "实现将数据通过物联网传输给智慧养殖管理平台，平台对数据进行分析处理。生产者可以根据数据，自动智能控制养殖场的通风、温度调节等设施设备，实现精准作业，减少人工成本投入，提高养殖的科学性。",
          labels: ["24", "22", "30"],
          styleName: ['icon_up', 'icon_up', 'icon_down'],
          labelsName: ["安全：", "效率：", "成本："],
          path: "./InsidePages/farming",
        },
        {
          lable: "智慧仓储",
          title: "5G智慧仓储",
          name: "3",
          img: require("@img/serve/img_gy_al_cc.png"),
          painSpot:
            "由于各个品类的物料管理要求和业务流程的不同所导致物料管理的混乱，仓储物流不能有序且高效的运作。",
          process:
            "基于 RFID 技术的仓库管理，将改变传统的仓库管理的工作方式 与流程，把所有关键的因素通过贴上 RFID 标签，在仓库管理的核心 业务流程：出库、入库、盘点、库存控制上实现更高效精确的管理。",
          labels: ["20", "15", "18"],
          styleName: ['icon_up', 'icon_up', 'icon_up'],
          labelsName: ["智能化：", "利用率：", "便捷性："],
          path: "./InsidePages/warehousing",
        },
        {
          lable: "数据采集",
          title: "数据采集",
          name: "4",
          img: require("@img/serve/img_iot_al_sjcj.png"),
          painSpot:
            "自动化设备品牌类型繁多，厂家和数据接口各异，国外厂家本地支持有限，不同采购年代。",
          process:
            "专业的大数据采集平台，可配置化的数据采集，对采集过程实现可视化监控。 支持数据采集的同时，完成数据检核、转换、加载、整合等任务，实现数据的高效集成。",
          labels: ["20", "30", "50"],
          styleName: ['icon_up', 'icon_down', 'icon_up'],
          labelsName: ["安全性：", "低延时：", "高兼容："],
          path: "./InsidePages/collect",
        },
      ],
      planData: [
        {
          img: require("@img/indusAuto/img_a.png"),
          text: "化工",
        },
        {
          img: require("@img/indusAuto/img_b.png"),
          text: "电力",
        },
        {
          img: require("@img/indusAuto/img_c.png"),
          text: "食品",
        },
        {
          img: require("@img/indusAuto/img_d.png"),
          text: "医药",
        },
        {
          img: require("@img/indusAuto/img_e.png"),
          text: "飞机",
        },
        {
          img: require("@img/indusAuto/img_f.png"),
          text: "船舶",
        },
        {
          img: require("@img/indusAuto/img_g.png"),
          text: "矿业",
        },
        {
          img: require("@img/indusAuto/img_h.png"),
          text: "建材",
        },
        {
          img: require("@img/indusAuto/img_i.png"),
          text: "酒业",
        },
        {
          img: require("@img/indusAuto/img_j.png"),
          text: "玻璃",
        },
        {
          img: require("@img/indusAuto/img_k.png"),
          text: "汽车",
        },
        {
          img: require("@img/indusAuto/img_l.png"),
          text: "机床",
        },
        {
          img: require("@img/indusAuto/img_n.png"),
          text: "能源",
        },
        {
          img: require("@img/indusAuto/img_r.png"),
          text: "造纸",
        },
        {
          img: require("@img/indusAuto/img_m.png"),
          text: "轮胎",
        },
        {
          img: require("@img/indusAuto/img_w.png"),
          text: "电池",
        },
        {
          img: require("@img/indusAuto/img_q.png"),
          text: "电子",
        },
        {
          img: require("@img/indusAuto/img_y.png"),
          text: "机械",
        },
      ],
    };
  },
  created() {
    // this.init()
  },
  methods: {
    async init() {
      let {
        data: { hotList, platformList, carList },
      } = await getIndus();
      Object.assign(this, { hotList, platformList, carList });
    },
    mouseenterPlan1(i) {
      this.palnActive1 = i;
    },
    mouseenterinduc1(i) {
      this.inducActive1 = i;
    },
    mouseenterPlan(i) {
      this.palnActive = i;
    },
    mouseenterinduc(i) {
      this.inducActive = i;
    },
    manageenter(index) {
      this.manageActive = index;
    },
    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
    isShowFn() {
      this.isShow = true;
    },
    closeTop() {
      this.isShow = false;
    },
   
  },
};
</script>

<style lang="scss" scoped>
.indusAuto {
  .base_head {
    height: auto;
  }

  .el-carousel__indicators {
    display: none;
  }

  .el-main {
    .triad {
      margin-top: 36px;
      width: 1200px;
      background: linear-gradient(180deg, #f4f6f9, #ffffff);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 5px 5px 15px 0px #ebeff5;

      // padding: 36px 32px;
      :deep(.el-carousel__container) {
        height: 650px;
      }

      .triad-lunbo {
        height: 254px;
        background: linear-gradient(90deg, #30abeb, #1e77ec);
        margin: 36px 32px 0 32px;

        .triad-img {
          width: 339px;
          height: 247px;
          margin-top: -23px;
          margin-left: 30px;
        }

        h3 {
          color: #475669;
          font-size: 14px;
          opacity: 0.75;
          line-height: 150px;
          margin: 0;
          text-align: center;
        }

        .border {
          width: 572px;
          height: 1px;
          opacity: 0.4;
          background: #ffffff;
          margin-left: 39px;
        }

        h4 {
          font-size: 27px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin: 44px 40px 15px;
        }

        p {
          margin: 15px 40px 0;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 28px;
        }
      }
    }

    .el-carousel__indicators {
      bottom: 20px;

      li {
        width: 47px;
        height: 3px;
        opacity: 0.38;
        background: #ffffff;
        border-radius: 2px;
        padding: 0;
        margin: 0 4px;
        background: #2b4fc7;
        border-radius: 1px;

        .el-carousel__button {
          width: auto;
          height: auto;
        }
      }

      .el-carousel__indicator.is-active {
        opacity: 1;
        background: #1e77ec;

        button {
          width: auto;
          height: auto;
        }
      }
    }

    .hot-ul {
      display: flex;
      justify-content: space-between;
      width: 1133px;
      flex-wrap: wrap;
      margin: 0 36px 32px 36px;

      li {
        width: 318px;
        padding: 6px;
        display: flex;
        margin-top: 24px;
        height: 95px;
        padding: 20px 20px;

        .plan-div {
          margin-left: 8px;
          text-align: left;
          position: relative;
          z-index: 3;

          p {
            color: #363737;
            margin-top: 9px;
          }

          h4 {
            font-weight: normal;
            font-size: inherit;
            font-size: 18px;
            font-weight: 500;
            height: 40px;
            line-height: 40px;
            color: #1a1a1a;
          }

          h4::after {
            content: "";
            display: block;
            width: 44px;
            height: 2px;
            background: linear-gradient(90deg, #0063e6, #9cc6fe);
            border-radius: 2px;
            position: absolute;
            top: 38px;
          }

          a {
            // color: #1e77ec;
            // position: absolute;
            // bottom: 3px;
            color: #959da7;
            position: absolute;
            bottom: 6px;
            border: 1px solid #959da7;
            border-radius: 2px;
            text-align: center;
            display: inline-block;
            padding: 2px;
            color: #959da7;
          }
        }

        img {
          width: 56px;
          height: 56px;
        }
      }

      .manageActive {
        background: linear-gradient(180deg, #edf2fa, #fefefe);
        box-shadow: 0px 5px 18px 0px #c7d6eb;
      }
    }

    .triad2 {
      .el-carousel__container {
        margin-top: 32px;
        height: 400px;

        .el-carousel__item {
          display: flex;

          img {
            width: 651px;
            height: 334px;
            margin-top: 30px;
            z-index: 9;
          }

          .successful-case {
            margin-top: 8px;
            margin-left: -268px;
            width: 810px;
            height: 378px;
            background: #ffffff;
            border: 1px solid #ffffff;
            border-radius: 16px;
            box-shadow: 0px 3px 8px 0px #e9eef7;

            img {
              position: absolute;
              top: -21px;
              right: 7px;
              width: 251px;
              height: 165px;
            }

            h3 {
              font-size: 22px;
              font-weight: 700;
              text-align: left;
              color: #161719;
              margin-left: 300px;
              margin-top: 133px;
            }

            h3::after {
              content: "";
              display: block;
              width: 31px;
              height: 3px;
              margin-top: 16px;
              background: linear-gradient(90deg, #0063e6, #9cc6fe);
            }

            p {
              margin-top: 19px;
              margin-left: 300px;
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              color: #959da7;
              line-height: 24px;
            }
          }
        }
      }
    }

    .scenario-img {
      width: 100%;
      height: 295px;
    }

    .scenario {
      background: linear-gradient(90deg, #30abeb, #1e77ec);
      height: 295px;
      text-align: left;
      padding: 0 60px 0 39px;

      .border {
        width: 572px;
        height: 1px;
        opacity: 0.4;
        background: #fff;
      }

      h4 {
        margin: 65px 0 11px;
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
      }

      p {
        margin: 12px 0 0px;
        font-size: 16px;
        font-weight: Normal;
        text-align: left;
        color: #ffffff;
        line-height: 28px;
      }
    }

    .management {
      background: url("../../public/img/home/ic_home_pt_bg.png") no-repeat;
      background-size: 100% 100%;
    }

    .arrow-switch {
      position: absolute;
      right: 32px;
      margin-top: 75px;
      z-index: 99;

      span:nth-child(1) {
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-right-color: #fff;
        display: inline-block;
      }

      span:nth-child(2) {
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left-color: #fff;
        display: inline-block;
        margin-left: 10px;
      }
    }

    // .el-carousel {
    //   height: 350px;
    //   .el-carousel__container {
    //     height: 350px;
    //   }
    // }
  }

  .success_case1 {
    margin-top: -28px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;

    .success_list {
      width: 363px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;
      padding-bottom: 30px;
      background: linear-gradient(180deg, #f2f5fa, #fefefe);
      border: 1px solid #ffffff;
      border-radius: 4px;
      box-shadow: 0px 3px 14px 0px #e4e9f2;

      .s_img {
        height: 181px;
        width: 100%;
        background-size: cover;
      }

      .s_name {
        font-size: 18px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: center;
        line-height: 38px;
        letter-spacing: -1px;
      }

      .lineB {
        display: none;
        opacity: 1;
        position: absolute;
        background: #1e77ec;
        bottom: -4px;
        width: 158px;
        height: 2px;
      }

      .s_title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        text-align: center;
        color: #959da7;
        line-height: 22px;
        letter-spacing: 0px;
        padding: 0 20px;
      }
    }

    .inducActive {
      background: linear-gradient(180deg, #edf2fa, #fefefe);
      box-shadow: 0px 5px 18px 0px #c7d6eb;

      .lineB {
        display: block;
        opacity: 1;
        position: absolute;
        background: #1e77ec;
        bottom: -3px;
        width: 355px;
        height: 2px;
      }
    }
  }

  .oddItem {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;

    .odd_img {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;

      img {
        width: 100%;
        height: 100%;
        background-size: cover;
      }
    }

    .odd_name {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #161719;
      letter-spacing: -1px;
    }

    .lineB {
      display: none;
      opacity: 1;
      position: absolute;
      background: #1e77ec;
      bottom: -4px;
      width: 214px;
      height: 2px;
    }

    .odd_title {
      font-size: 14px;
      text-align: center;
      color: #959da7;
      letter-spacing: 0px;
      padding: 0 16px;
      margin-top: 12px;
      line-height: 24px;
    }
  }
}

.line_title {
  width: 1200px;
}

.planList {
  display: flex;
  flex-flow: wrap;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;

  .planList_item {
    width: 185px;
    height: 140px;
    margin-bottom: 17px;
    position: relative;

    .planList_b {
      position: absolute;
      width: 185px;
      height: 48px;
      bottom: 0px;
      line-height: 48px;
      // opacity: 0.78;
      background: rgba(35, 126, 255, 0.7);
      border-radius: 4px;
      font-size: 18px;
      font-family: " PingFang SC, PingFang SC-Regular";
      color: #ffffff;
    }
  }

  .planList_item:hover {
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
    box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
    transform: scale3d(1.01, 1.01, 1.01);
  }
}

.line_tit {
  .line_tit_flex {
    display: flex;
    justify-content: space-between;

    div {
      font-size: 18px;
      font-family: "PingFang SC, PingFang SC-Regular";
      font-weight: 400;
      text-align: left;
      color: #8793aa;
    }
  }

  .line_tit_step {
    width: 1185px;
    height: 3px;
    position: relative;
    margin: 0 auto;
    margin-top: 11px;
    margin-bottom: 20px;
    background: linear-gradient(90deg,
        rgba(0, 81, 222, 0.1),
        #0051de 53%,
        rgba(0, 81, 222, 0.16));
  }

  .line_tit_step::after {
    content: "《";
    color: #cfdaee;
    position: absolute;
    font-size: 14px;
    left: -17px;
    top: -9px;
  }

  .line_tit_step::before {
    content: "》";
    color: #cfdaee;
    position: absolute;
    font-size: 14px;
    right: -17px;
    top: -9px;
  }
}

.el-tabs__item.is-active {
  color: #005de0;
}

::v-deep .el-tabs__nav-wrap .el-tabs__nav-scroll {
  width: 560px;
  margin: 0 auto;
}

::v-deep .el-tabs__content{
  background: linear-gradient(87deg, #fafcff 2%, #e9f2ff 51%, #fafcff 98%);
  border: 2px solid #ffffff;
  box-shadow: 5px 5px 15px 0px #ebeff5;
  padding: 55px 0;
}

.case {
  .case_tabs {
    .el-tabs__header {
      text-align: center;
      background: #f00;

      .el-tabs__nav-wrap {
        background: #f00;

        :deep(.el-tabs__nav-scroll) {
          width: 325px;
          margin: 0 auto;
          background: #f00;
        }
      }
    }

    .case_box {
      display: flex;
      width: 1200px;
      margin: auto;
      // padding-bottom: 48px;
      .case_left {
        img {
          width: 539px;
          height: 354px;
        }
      }

      .case_right {
        padding-left: 32px;
        position: relative;

        .title {
          font-size: 30px;
          font-weight: 700;
          text-align: left;
          color: #141414;
          line-height: 28px;
        }

        .line {
          width: 627px;
          height: 1px;
          margin: 16px 0 18px 0;
          background: #0063e6;
        }

        .case_cont {
          text-align: left;
          margin-top: 14px;

          label {
            font-size: 16px;
            text-align: left;
            color: #0063e6;
            line-height: 23px;
            display: inline-block;
            vertical-align: top;
            margin-right: 16px;
          }

          div {
            display: inline-block;
            width: 549px;
            font-size: 16px;
            text-align: left;
            color: #141414;
            line-height: 28px;
            position: relative;
            top: -3px;
          }
        }

        .case_cont_flex {
          display: flex;
          width: 550px;
          justify-content: space-evenly;

          span {
            font-size: 16px;
            color: #a3a3a3;
          }

          b {
            font-size: 28px;
            font-weight: 700;
            color: #323232;
            position: relative;
            left: -2px;
          }
        }

        .case_cont_btn {
          position: absolute;
          bottom: 0;

          .details_btn {
            width: 121px;
            height: 40px;
            background: #0063e6;
            border: 1px solid #0063e6;
            border-radius: 2px;
            text-align: center;
            line-height: 40px;
            color: #fff;
            margin-right: 40px;
            cursor: pointer;
            font-size: 16px;
          }

          .custom_btn {
            width: 121px;
            height: 40px;
            border: 1px solid #0063e6;
            color: #0063e6;
            border-radius: 2px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
  }

  .el-tabs__nav-wrap::after {
    height: 0px !important;
  }

  .el-tabs__active-bar {
    width: 24px !important;
    height: 4px !important;
    border-radius: 2px !important;
    left: 28px;
    background: #0063e6 !important;
  }
}

.container {
  width: 1200px;
  margin: 0 auto;

  .scenario-img {
    width: 100%;
    height: 295px;
  }

  .scenario {
    background: linear-gradient(90deg, #30abeb, #1e77ec);
    height: 295px;
    text-align: left;
    padding: 0 60px 0 39px;

    .border {
      width: 572px;
      height: 1px;
      opacity: 0.4;
      background: #fff;
    }

    h4 {
      margin: 65px 0 11px;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
    }

    p {
      margin: 12px 0 0px;
      font-size: 16px;
      font-weight: Normal;
      text-align: left;
      color: #ffffff;
      line-height: 28px;
    }
  }

  .oddBox {
    margin-top: 30px;
    height: 300px;

    .outside {
      width: 214px;
      height: 270px;
      cursor: pointer;
      margin: 50px auto;
      position: relative;
      perspective: 500;
      -webkit-perspective: 1000;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 8px 8px 24px 0px rgba(0, 55, 128, 0.08);
    }

    .oddItem_front {
      position: relative;
      width: 212px;
      height: 100%;
    }

  }

  .oddItem {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(180deg, #f2f6fc, #fcfcfc);

    .odd_img {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;
      z-index: 2;

      img {
        width: 64px;
        height: 64px;
        background-size: cover;
      }
    }

    .odd_name {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #161719;
      letter-spacing: -1px;
      z-index: 2;
    }

    .lineB {
      display: none;
      opacity: 1;
      position: absolute;
      background: #1e77ec;
      bottom: -4px;
      width: 214px;
      height: 2px;
    }

    .odd_title {
      font-size: 14px;
      text-align: center;
      color: #959da7;
      letter-spacing: 0px;
      padding: 0 16px;
      margin-top: 12px;
      line-height: 26px;
    }
  }

  .oddActive {
    background: linear-gradient(180deg, #edf2fa, #fefefe);
    box-shadow: 0px 5px 18px 0px #c7d6eb;

    .lineB {
      display: block;
      opacity: 1;
      position: absolute;
      background: #1e77ec;
      bottom: -4px;
      width: 214px;
      height: 2px;
    }
  }
}

.icon_up {
  height: 14px;
  margin-left: 10px;
  vertical-align: inherit;
}

.icon_down {
  height: 14px;
  margin-left: 8px;
  vertical-align: middle;
}

::v-deep .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 100%;
  height: 0;
  // background-color: #e4e7ed;
  z-index: 1;
  opacity: 1;
}

.oddItem,
.outside {
  background: linear-gradient(180deg, #f3f5f8, #fefefe);
  border: 1px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0px 3px 14px 0px #f0f3f8;
  position: relative;

  .bg_pic_outside {
    position: absolute;
    z-index: 1;
    display: none;
  }
}

.oddLine {
  width: 25px;
  height: 4px;
  background: #0063e6;
  margin: 0 auto;
  margin-top: 12px;
  border-radius: 2px;
}

.outside:hover {
  box-shadow: 0 5px 18px 0 #c7d6eb !important;
  transition: all .3s cubic-bezier(0, 0, .5, 1);
}

.outside:hover {
  .bg_pic_outside {
    display: block;
    z-index: 1;
  }

  .oddItem {
    background: linear-gradient(180deg, #edf3fc, #fcfcfc);
  }

  border-radius: 4px;
  box-shadow: 8px 8px 24px 0px rgba(0, 55, 128, 0.16);
  transition: all .3s cubic-bezier(0, 0, .5, 1);
}

.df {
  .dfl {
    width: 540px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 54px auto;
    padding-top: 54px;

    div {
      padding: 13px 24px;
      cursor: pointer;
      background: linear-gradient(180deg, #f2f6fc, #fcfcfc);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 8px 8px 24px 0px rgba(0, 55, 128, 0.08);
    }

    .is_active {
      background: #0063e6;
      color: #fff;
    }
  }

  .dfr {
    width: 1142px;
    height: 548px;
    background: linear-gradient(180deg, #f2f6fc, #fcfcfc);
    ;
    border: 2px solid #ffffff;
    border-radius: 0px 2px 2px 0px;
    box-shadow: 5px 5px 15px 0px #dde2eb;
    padding: 34px 27px 16px 27px;

    .dfr_f {
      display: flex;
      border-radius: 4px;
      background: linear-gradient(90deg, rgba(252, 254, 255, 0.00), #fafcff 45%, rgba(255, 255, 255, 0.00));
      border: 1px solid #d6e9ff;
      border-radius: 4px;
      padding: 30px 43px;

      img {
        width: 325px;
        height: 205px;
        margin-right: 21px;
      }

      .dfr_f_r {
        padding-left: 24px;
        position: relative;

        .dfr_title {
          font-size: 24px;
          text-align: left;
          color: #0063e6;
          margin-top: 25px;
        }

        .dfr_text {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #141414;
          line-height: 30px;
          width: 580px;
          position: relative;
          z-index: 2;
        }

        .dfr_f_r_bg_pic {
          width: 250px;
          height: 165px;
          position: absolute;
          right: -147px;
          top: -30px;

          img {
            width: 250px;
            height: 165px;
          }
        }
      }
    }

    .dfr_b {
      display: flex;
      flex-wrap: wrap;

      .dfr_b_list {
        width: 336px;
        height: 104px;
        background: #ffffff;
        border: 1px solid #f5f5f5;
        border-radius: 4px;
        padding: 15px 16px 0 16px;
        display: flex;
        margin-top: 16px;

        .dfr_b_list_l {
          width: 44px;

          img {
            width: 44px;
            height: 44px;
            display: block;
          }
        }

        .dfr_b_list_r {
          .dfr_b_list_r_title {
            font-size: 16px;
            text-align: left;
            color: #141414;
            position: relative;
            margin-top: 2px;
          }

          .dfr_b_list_r_title::after {
            content: "";
            width: 25px;
            height: 2px;
            background: #0063e6;
            position: absolute;
            bottom: -12px;
            left: 0;
          }

          .dfr_b_list_r_text {
            font-size: 12px;
            text-align: left;
            color: #727f8f;
            line-height: 20px;
            margin-top: 20px;
          }
        }
      }

      .dfr_b_list:nth-child(3n-1) {
        margin-left: 16px;
        margin-right: 16px;
      }

      .dfr_b_list:hover {
        // box-shadow: 5px 5px 15px 0px #dde2eb; 
        transition: all .3s cubic-bezier(0, 0, .5, 1);
        box-shadow: 0px 5px 18px 0px #c7d6eb !important;
      }
    }
  }
}

::v-deep .el-tabs__active-bar {
  background: #0063e6 !important;
  // width: 24px !important;
  // margin-left: 29px;
  height: 2px;
  border-radius: 2px;
}
::v-deep .el-tabs__nav-scroll{
  width: 560px !important;
  margin: auto !important;
}


.plc_box {
  width: 1100px;
  margin: 0 auto;
  // height: 584px;
  background: linear-gradient(180deg, #f7f9fc, #fcfcfc);
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 8px 8px 24px 0px rgba(0, 55, 128, 0.08);
  padding: 48px;
  margin-top: 48px;

  .plc_l {
    position: relative;

    .plc_l_t {
      width: 420px;
      height: 212px;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 30px;

      img {
        width: 200px;
        height: 108px;
        margin: 0 auto;
        margin-top: 33px;
      }

      div {
        height: 32px;
        font-size: 23px;
        font-weight: 700;
        text-align: center;
        color: #0063e6;
        position: relative;
        top: 19px;
      }

      div::after {
        content: " ";
        width: 183px;
        height: 13px;
        opacity: 0.16;
        background: linear-gradient(180deg, #a1c9ff, #0063e6);
        position: absolute;
        bottom: 0;
        left: 118px;
      }
    }

    .plc_l_list {
      .plc_l_item {
        height: 44px;
        display: flex;
        margin-top: 10px;

        .plcTitle {
          width: 120px;
          height: 44px;
          line-height: 44px;
          background: #e0eeff;
          border-radius: 4px 0px 0px 4px;
          color: #1f272a;
          text-align: center;
          font-size: 15px;
        }

        .plcText {
          width: 300px;
          height: 42px;
          line-height: 42px;
          border: 1px solid #e0eeff;
          border-radius: 0px 4px 4px 0px;
          color: #1f272a;
          font-size: 15px;
          text-align: left;
          text-indent: 1.2em;
        }
      }
    }
  }

  .plc_l::after {
    content: ' ';
    height: 500px;
    border: 1px solid #f0f0f0;
    position: absolute;
    right: -42px;
    top: 0;
  }

  .plc_r {
    width: 596px;

    .plc_r_tit {
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: #141414;
      text-align: left;
      position: relative;
      left: 12px;
    }

    .plc_r_tit::before {
      content: ' ';
      width: 3px;
      height: 18px;
      background: #0063e6;
      position: absolute;
      top: 5px;
      left: -12px;
    }

    .plc_r_list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .plc_r_item {
        display: flex;
        width: 282px;
        height: 91px;
        background: #fcfcfc;
        border: 2px solid #ffffff;
        border-radius: 4px;
        box-shadow: 6px 6px 18px 0px rgba(0, 55, 128, 0.08);
        margin-top: 24px;
        justify-content: space-between;

        .icons {
          width: 16px;
          height: 16px;
          display: block;
          margin-left: 24px;
          margin-top: 24px;
        }

        div {
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #0063e6;
          line-height: 17px;
          margin-top: 24px;
          width: 170px;
        }

        .icons1 {
          width: 48px;
          height: 48px;
          display: block;
          margin-top: 24px;
        }
      }
    }
  }
}

.hxBox {
  width: 1200px;
  margin: 0 auto;
  background: url("../../public/img/indusAuto/icon/img_gy_fw_bg.png") no-repeat;
  background-position-x: 50%;
  background-position-y: 105%;

  .hxItem {
    display: flex;
    justify-content: space-between;

    .ml42 {
      margin-left: 42px;
    }

    .mr42 {
      margin-right: 42px;
    }

    .hxItem_l {
      display: flex;
      margin-top: 80px;

      img {
        width: 76px;
        height: 76px;
        box-shadow: 8px 8px 24px 0px rgb(0 55 128 / 30%);
        border-radius: 76px;
      }

      .hxItem_l_t {
        .title {
          font-size: 22px;
          font-weight: 700;
          text-align: left;
          color: #0063e6;
          line-height: 46px;
          position: relative;
          top: -7px;
          left: 21px;
        }

        .text {
          width: 280px;
          text-align: left;
          height: 49px;
          font-size: 14px;
          text-align: left;
          color: #6e737a;
          position: relative;
          top: -4px;
          left: 21px;
        }
      }
    }

    .hxItem_r {
      float: right;
      display: flex;
      margin-top: 80px;

      img {
        width: 76px;
        height: 76px;
        box-shadow: 8px 8px 24px 0px rgba(0, 55, 128, 30%);
        border-radius: 76px;
      }

      .hxItem_l_t {
        .title {
          font-size: 22px;
          font-weight: 700;
          text-align: right;
          color: #0063e6;
          line-height: 46px;
          position: relative;
          top: -7px;
          right: 21px;
        }

        .text {
          width: 280px;
          text-align: right;
          height: 49px;
          font-size: 14px;
          text-align: right;
          color: #6e737a;
          position: relative;
          top: -4px;
          right: 21px;
        }
      }
    }
  }
}

.bg_color1 {
  background: #fff !important;
}

.bg_color2 {
  background: #f4f6f9 !important;
}
</style>
