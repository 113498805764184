<template>
  <div class="home">
    <el-container>
      <el-header>
        <v-head :active="0" :bannerData="bannerData" :isBanner="true" :color="'black'" indicator="none"></v-head>
      </el-header>
      <el-main style="position: relative;top: -90px;">
        <div >
          <!-- <div class="level" >
            <div class="container">
              <div
                class="head_title"
              >
                <div class="max_tit" style="padding-top:20px;">丰富、安全、稳定的产品及服务</div>
              </div>
              <div class="oddBox">
                <div class="oddBox_flexs1 flexs">
                  <div
                    v-for="(item, index) in oddBox_flexs1"
                    :key="index"
                    class="position_r"
                  >
                    <img :src="item.bg_img" alt="">
                    <div class="oddBox_flexs_title oddBox_flexs_title1">
                      <div class="title" > <router-link :to="item.path" class="title">{{ item.title }} <img src="@img/home/ic_home_iot_jt@2x.png" class="imgs"> </router-link> </div>
                      <div class="text">{{ item.text }}</div>
                      <div class="line"></div>
                      <div class="flex_oddBox">
                        <div v-for="(items,indexs) in item.info" :key="indexs" class="flex_oddBox_list">
                          <img src="@img/home/icon/ic_home_cp_cj.png" alt="">
                          <span>{{items.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
       
          <div class="level df" :class="bg_pic" style="margin-top:40px;padding-bottom: 88px !important;padding-top: 40px !important;">
            <!--<div class="dfl">
                 <div @click="tabs_fn(0,'bg_pic2')" :class=" is_active === 0 ? 'is_active' : '' ">IoT管理平台</div>
                 <div @click="tabs_fn(1,'bg_pic1')" :class=" is_active === 1 ? 'is_active' : '' ">工业数字化平台</div> 
                <div @click="tabs_fn(2,'bg_pic3')" :class=" is_active === 2 ? 'is_active' : '' ">车载视频监控集中分发平台</div>
              </div> -->
            <div class="container tab-padding " >
              <div class="dfr" v-for="(item, index) in tabsData" :key="index" v-if="index == is_active">
                <div class="dfr_f">
                  <div>
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="dfr_f_r">
                    <div class="dfr_title">{{ item.title }} </div>
                    <div style="width: 573px;height:1px;margin: 16px 0;background: #0063e6;"></div>
                    <div class="dfr_text" v-html=" item.text "></div>
                    <div class="dfr_f_r_bg_pic">
                      <img :src="item.bg_img" alt="">
                    </div>
                  </div>
                </div>
                <div class="dfr_b">
                  <div
                    class="dfr_b_list"
                    v-for="(items, indexs) in item.data"
                    :key="indexs"
                  >
                    <div class="dfr_b_list_l">
                      <img :src="items.img" alt="" />
                    </div>
                    <div class="dfr_b_list_r">
                      <div class="dfr_b_list_r_title">{{ items.titles }}</div>
                      <div class="dfr_b_list_r_text">{{ items.text }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- 赋能干行百业的一体化解决方案 #f7f9fc -->
          <div class="level success bg_color3" :class="bg_img" >
            <div class="container">
              <div
                class="head_title"
               
              >
                <div class="max_tit">赋能千行百业的一体化解决方案</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="container plan_container">
              <div class="plan">
                <div
                  class="plan_box"
                  v-for="(item, index) in plan"
                  :key="index" v-if = "index == planTabs"  >
                  <div class="title">{{ item.data.titles }}</div>
                  <div class="text">
                    {{ item.data.text }}
                  </div>
                  <div class="plan_span">
                    <span
                      v-for="(items, index) in item.data.info"
                      :key="index"
                      >{{ items }}</span
                    >
                  </div>
                  <div class="plan_btn">
                    <div class="plan_btn1" :data-path="item.path"><router-link :to="item.path" style="color:#fff;">了解详情</router-link></div>
                    <div class="plan_btn2" @click="isShowFn">定制方案</div>
                  </div>
                </div>
              </div>
              <div class="plan_right">
                <div
                  class="plan_right_list"
                  v-for="(item, index) in plan"
                  :key="index" @click="planTabsFn(index)"
                >
                  <div :class="planTabs == index ? 'planIsActive' : ''" >
                    <img :src="item.icon" alt="" />
                    <span>{{ item.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 合作伙伴 -->
          <div class="level bg_color3" style="padding: 40px 0">
            <div
              class="head_title"
              
            >
              <div class="max_tit" style="color: #141414;padding-bottom: 8px;">各行各业客户的信赖</div>
            </div>
            <div class="autoWidth" >
              <div class="partner" >
                <div
                  v-for="(item, index) in partnerList"
                  :key="index"
                  class="partner_list"
                  @mouseenter.stop="mouseenterPartner(index)"
                  @mouseleave.stop="mouseenterPartner"
                  :class="
                    partnerActive === ''
                      ? ''
                      : partnerActive === null
                        ? 'aos-init aos-animate'
                        : partnerActive === index
                          ? 'partnerActive aos-init aos-animate'
                          : 'aos-init aos-animate'
                  ">
                  <img v-lazy="item.img" :key="item.img" alt="item.title" />
                </div>
              </div>
            </div>
          </div>
          <!-- 安全、合规、可信的服务 -->
          <!-- <div class="level bg_color2" v-show="true" style="margin-bottom: 70px;" >
            <div class="container" style="padding-bottom: 30px;">
              <div
                class="head_title"
               
              >
                <div class="max_tit">安全、合规、可信的服务</div>
              </div>
              <div class="moving">
                <div class="left moving_item">
                  <div class="moving_left">
                    <div class="moving_left_list">
                      <div class="moving_left_list_t">
                        <img :src="sjx_img" alt="" />
                        <span class="fs28">500</span>
                        <span class="fs24">+</span>
                      </div>
                      <div class="moving_left_list_b">
                        <p>服务客户</p>
                      </div>
                    </div>
                    <div class="moving_left_list">
                      <div class="moving_left_list_t">
                        <img :src="sjx_img" alt="" />
                        <span class="fs28">30</span>
                        <span class="fs24">+</span>
                      </div>
                      <div class="moving_left_list_b">
                        <p>覆盖行业</p>
                      </div>
                    </div>
                    <div class="moving_left_list">
                      <div class="moving_left_list_t">
                        <img :src="sjx_img" alt="" />
                        <span class="fs28">50</span>
                        <span class="fs24">+</span>
                      </div>
                      <div class="moving_left_list_b">
                        <p>服务地区</p>
                      </div>
                    </div>
                    <div class="moving_left_list">
                      <div class="moving_left_list_t">
                        <img :src="sjx_img" alt="" />
                        <span class="fs28">100</span>
                        <span class="fs24">%</span>
                      </div>
                      <div class="moving_left_list_b">
                        <p>项目成功交付</p>
                      </div>
                    </div>
                  </div>
                  <div class="right moving_item">
                    <img :src="moving_bg" alt="" style="height:430px;width:830;"/>
                  </div>
                </div>
                <div class="moving_item_bottom">
                  <div
                    class="moving_item_b"
                    v-for="(item, index) in moving_data"
                    :key="index"
                  >
                    <img :src="item.img" alt="" />
                    <div>
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

           <div class="level bg_color2" style="padding: 40px 0;padding-bottom: 80px !important;">
            <div
              class="head_title"
             
            >
              <div class="max_tit" style="padding-bottom: 20px;">聚焦行业前沿资讯</div>
            </div>
            <div class="information" >
              <!-- <div class="information_t">
                <div class="information_t_l">
                  <div class="tabs isTabs">
                    热点新闻
                  </div>
                  <div class="tabs">
                    产业政策
                  </div>
                </div>
                <div class="information_t_r">
                  <span>查看更多</span>
                  <img src="@img/home/icon/ic_home_zx_gd.png" alt="">
                </div>
              </div> -->
              <div class="information_c">
                <div class="information_c_t">
                  <div class="information_c_t_item" v-for="(item,index) in newList.yearData" :key="index"  
                      @mouseenter.stop="manageenter(index)"
                      @mouseleave.stop="manageenter"
                      :class="
                        manageActive === ''
                          ? ''
                          : manageActive === null
                          ? 'aos-init aos-animate'
                          : manageActive === index
                          ? 'manageActive aos-init aos-animate'
                          : 'aos-init aos-animate'
                      ">
                    <div class="information_c_t_tit">{{item.name}}</div>
                    <div class="information_c_t_text">{{item.title}}</div>
                    <div class="information_c_t_more" ><router-link :to="`information?id=${index}`"><span>更多</span><img src="@img/home/icon/ic_new_h.png" alt=""></img></router-link></div>
                  </div>
                </div>
                <div class="information_c_b">
                  <div class="information_c_b_item" v-for="(item,index) in newList.todayData" :key="index" 
                      @mouseenter.stop="manageenter(index)"
                      @mouseleave.stop="manageenter"
                      :class="
                        manageActive === ''
                          ? ''
                          : manageActive === null
                          ? 'aos-init aos-animate'
                          : manageActive === index
                          ? 'manageActive aos-init aos-animate'
                          : 'aos-init aos-animate'
                      ">
                    <div class="information_c_b_tit">{{item.name}}</div>
                    <div class="information_c_b_more" >
                      <router-link :to="`information?id=${index+2}`"> <span>更多</span><img src="@img/home/icon/ic_new_h.png" alt="" /></router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </el-main>
      <el-footer style="background:#f4f6f9;">
        <v-footer></v-footer>
      </el-footer>
    </el-container>
    <plan-dialog
      :isShow="isShow"
      v-if="isShow"
      @closeTop="closeTop"
    ></plan-dialog>
    <!-- <about-box></about-box> -->
  </div>
</template>

<script>
import vHead from "../components/head.vue";
import vFooter from "../components/footer.vue";
import planDialog from "@components/planDialog.vue";
import { getList } from "@/api/home";
// import { home } from "@/mockJson/index";
export default {
  name: "home",
  metaInfo: {
    title: "首页",
    meta: [
      {
        name: "keyWords",
        content: "物联网，网关，自动化，软硬件开发，车联网，监控，智慧码头",
      },
    ],
  },
  components: {
    vHead,
    vFooter,
    planDialog,
  },
  data() {
    return {
      isShow: false,
      planShow: 1,
      // planActive: "",
      oddActive3: "",
      bg_pic:'bg_pic2',
      activeName: "first",
      tabPosition: "left",
      manageActive: "",
      oddActive: "",
      successActive: "",
      partnerActive: "",
      centA: "",
      centB: "",
      home: {},
      img_display: require("@img/serve/wlw_d_pt.png"),
      sjx_img: require("@img/home/sjx.png"),
      moving_bg: require("@img/home/ic_home_fw_map@2x.png"),
      oddBox_flexs1: [
        {
          bg_img: require("@img/home/img_home_iot.png"),
          title: "物联网",
          text:'为客户快速搭建垂直物联网平台，高效实现场景化智能应用。',
          info: [
            { name: "车联网" },
            { name: "智能制造" },
            { name: "智慧物流" },
            { name: "穿戴设备" },
            { name: "安防监控" },
            { name: "智慧医疗" },
          ],
          path:"/serve"
        },
        // {
        //   bg_img: require("@img/home/img_home_gy.png"),
        //   title: "工业数字化",
        //   text:'服务企业数字化转型，高效构建各种工业物联网应用。',
        //   info: [
        //     { name: "智慧港口" },
        //     { name: "智慧能源" },
        //     { name: "智慧电厂" },
        //     { name: "智慧仓储" },
        //     { name: "智慧养殖" },
        //     { name: "智慧园区" },
        //   ],
        //   path:"/indusAuto"
        // },
        
      ],
      oddBox_flexs2: [
        {
          bg_img: require("@img/home/img_home_wg.png"),
          title: "智能网关",
          text:'为客户快速搭建垂直物联网平台，高效实现场景化智能应用。',
          info: [
            { name: "信号采集" },
            { name: "智慧工厂" },
            { name: "仓库管理" },
            { name: "环境检测" },
            { name: "智能监控" },
            { name: "冷链追踪" },
          ],
        },
        {
          bg_img: require("@img/home/img_home_clw.png"),
          title: "智慧车联网",
          text:'为客户快速搭建垂直物联网平台，高效实现场景化智能应用。',
          info: [
            { name: "智慧交通" },
            { name: "车路协同" },
            { name: "安全预警" },
            { name: "高精度定位" },
            { name: "远程驾驶" },
            { name: "编队行驶" },
          ],
        },
        {
          bg_img: require("@img/home/img_home_ryj.png"),
          title: "软硬件开发",
          text:'为客户快速搭建垂直物联网平台，高效实现场景化智能应用。',
          info: [
            { name: "工业" },
            { name: "农业" },
            { name: "水务" },
            { name: "物流" },
            { name: "汽车" },
            { name: "医疗" },
          ],
        },
      ],
      tabsData: [
        {
          title: "云百IoT管理平台",
          text:'提供流量消耗查询、订单管理、到期时间、充值续费以及预警提醒等功能。<br/>涵盖：物联网卡管理、流量监管、停卡保号、流量叠加、消耗查询、订单管理、到期时间、续期申请、预警提醒、短信管理、实名认证、IP管理等。',
          img:require("@img/home/img_iot_pt_sy.png"),
          bg_pic:'bg_pic2',
          bg_img:require("@img/home/img_iot_pt_ys1.png"),
          data: [
            {
              img: require("@img/home/icon/ic_.png"),
              titles: "三网合一",
              text: "统一三大运营商接口，一个平台管控三网卡片，缩短对接周期。",
            },
            {
              img: require("@img/home/icon/ic__1.png"),
              titles: "提供管理平台",
              text: "免费提供流量消耗查询、订单管理、到期时间、充值续费以及预警提醒等功能。",
            },
            {
              img: require("@img/home/icon/ic__2.png"),
              titles: "流量池预警",
              text: "支持组建流量池，无需担心流量浪费或透支问题，有效降低运营成本。",
            },
            {
              img: require("@img/home/icon/ic__4.png"),
              titles: "实名认证",
              text: "提供公众号、小程序实名认证接口。",
            },
            {
              img: require("@img/home/icon/ic__3.png"),
              titles: "灵活计费",
              text: "提供多种灵活套餐，支持分润分销。",
            },
            {
              img: require("@img/home/icon/ic__5.png"),
              titles: "支持二次开发",
              text: "提供API接口，支持客户二次开发。",
            },
          ],
        },
        // {
        //   title: "云百工业数字化平台",
        //   text:'通过强大的底层能力，结合平台对基本生产数据进行实时采集与存储，通过数据挖掘，分析等技术手段，优化生产过程，完善科学管控，达到降本增效的目的，同时对产品功能，性能等进行分析改进和创新，更好的满足工业企业的个性化需求。',
        //   img:require("@img/home/img_home_c_sy.png"),
        //   bg_pic:'bg_pic1',
        //   bg_img:require("@img/home/ic_gy_d_cl.png"),
        //   data: [
        //     {
        //       img: require("@img/home/icon/ic_home_pt_gy_a.png"),
        //       titles: "数据采集",
        //       text: "采集设备的实时数据，集成信息系统业务数据。",
        //     },
        //     {
        //       img: require("@img/home/icon/ic_home_pt_gy_b.png"),
        //       titles: "设备监控",
        //       text: "对设备资产进行可视化呈现和实时管控运行状态，预警处理。",
        //     },
        //     {
        //       img: require("@img/home/icon/ic_home_pt_gy_d.png"),
        //       titles: "高性能",
        //       text: "提供高并发、高可靠和低时延的设备控制。",
        //     },
        //     {
        //       img: require("@img/home/icon/ic_home_pt_gy_c.png"),
        //       titles: "生产排程",
        //       text: "通过对作业、投产、备料等环节建模，最优资源调度以应对复杂的订单计划。",
        //     },
        //     {
        //       img: require("@img/home/icon/ic_home_pt_gy_e.png"),
        //       titles: "权限管控",
        //       text: "授权机制、资源操作权限与数据行权限灵活设置，充分保证您的数据安全。",
        //     },
        //     {
        //       img: require("@img/home/icon/ic_home_pt_gy_f.png"),
        //       titles: "预测性维护",
        //       text: "通过对关键的设备运行参数进行建模，监控机器的运行状态、预测维护时间。",
        //     },
        //   ],
        // },
       
        
        
      ],
      plan: [
        // {
        //   title: "智慧园区",
        //   icon: require("@img/home/icon/ic_home_fa_yq.png"),
        //   path: '/InsidePages/wisdom_park',
        //   data: {
        //     titles: "智慧园区",
        //     text: "专注于全方位智能化应用，以账户、支付、安全为基础，涵盖智慧门禁、智慧访客、智慧餐饮、智慧停车缴费、物业管理和缴费、智慧巡检等系统。通过数据大屏集中展示，实现数据全方位可视化管理，助力园区实现全新智慧化升级、高效便捷化管理和智能化体验。",
        //     info: ["智慧物业", "智慧监控", "智慧门禁", "能耗管理"],
        //   },
        // },
        {
          title: "车联网",
          icon: require("@img/home/icon/ic_home_fa_clw.png"),
          path: '/InsidePages/carNetworking',
          data: 
            {
              titles: "车联网",
              text: "为车主提供更好的出行服务体验，增加产品竞争力。提供满足车联应用的云计算、大数据、人工智能、物联网等云服务，助力车企打造智能网联汽车，让客户享受人车智能生活。",
              info: ["智慧交通", "车路协同", "安全预警", "远程驾驶"],
            },
          
        },
        // {
        //   title: "智慧仓储",
        //   icon: require("@img/home/icon/ic_home_fa_clw.png"),
        //   path: '/InsidePages/warehousing',
        //   data: 
        //     {
        //       titles: "智慧仓储",
        //       text: "基于 RFID 技术的仓库管理，将改变传统的仓库管理的工作方式 与流程，把所有关键的因素通过贴上 RFID 标签，在仓库管理的核心 业务流程：出库、入库、盘点、库存控制上实现更高效精确的管理。",
        //       info: ["仓库管理", "环境检测", "冷链追踪"],
        //     },
          
        // },
        // {
        //   title: "智慧养殖",
        //   icon: require("@img/home/icon/ic_home_fa_yz.png"),
        //   path: '/InsidePages/farming',
        //   data: 
        //     {
        //       titles: "智慧养殖",
        //       text: "实现将数据通过物联网传输给智慧养殖管理平台，平台对数据进行分析处理。生产者可以根据数据，自动智能控制养殖场的通风、温度调节等设施设备，实现精准作业，减少人工成本投入，提高养殖的科学性。",
        //       info: ["智能控制", "数据分析", "生长监测"],
        //     },
          
        // },
        // {
        //   title: "智慧港口",
        //   icon: require("@img/home/icon/ic_home_fa_gk.png"),
        //   path:'/InsidePages/port',
        //   data: 
        //     {
        //       titles: "智慧港口",
        //       text: "项目为散装港口码头提供从0至1的智慧化转型方案，包含从货料运输、客户订购结算、货车装卸、货物监控、货车进出等全链路环节的软硬件开发服务，助力传统港口码头实现智能监管、智能服务、自动装卸、各信息系统数据打通，完成了机械化到智能化的提升。",
        //       info: ["智能监管", "自动装卸", "一码通系统"],
        //     },
          
        // },
        {
          title: "数据采集",
          icon: require("@img/home/icon/ic_home_fa_cj.png"),
          path:'/InsidePages/collect',
          data: 
            {
              titles: "智慧数据采集",
              text: "专业的大数据采集平台，可配置化的数据采集，对采集过程实现可视化监控。 支持数据采集的同时，完成数据检核、转换、加载、整合等任务，实现数据的高效集成。",
              info: ["高效集成", "智能网关","可视化监控"],
            },
          
        },
        {
          title: "两客一危一重",
          icon: require("@img/home/icon/ic_home_fa_ywyz.png"),
          path:'/InsidePages/coach',
          data: 
            {
              titles: "两客一危一重",
              text: "两客一危一重”车辆智能化管理系统，无论是对于危险品运输车辆或是长途客运车辆，对其形成全方位的监控管理，包括客车超载状况、危险品车辆的各种探测报警、驾驶员疲劳检测、车载视频监控、GPS定位跟踪、智能自动报警等等，以确保车辆安全行驶。",
              info: ["车载视频监控", "智能自动报警", "GPS定位跟踪"],
            },
          
        },
      ],
      bannerData: [
        //  {
        //   title: "赋能企业数字化新力量",
        //   content:
        //     "帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
        //   button: "获取专属方案",
        //   img: require("@img/plan/banner-gy1.png"),
        //   banner: "home_bg1 ",
        // },
        {
          title: "云百loT全栈服务",
          content:
            "提供智能化、安全管控等全栈全场景服务和开发、集成、托管、运营等一站式服务，助力合作伙伴和客户轻松、快速地构建5G应用和万物互联的场景化物联网解决方案。",
          button: "获取专属方案",
          img: require("@assets/dynamic_banner.gif"),
          banner: "serve_bg",
        },
        // {
        //   title: "服务企业数字化转型",
        //   content:"帮助设备制造商和生产企业快速实现从设备端到服务端的无缝连接，高效构建各种工业物联网应用。",
        //   button: "获取专属方案",
        //   img: require("@img/plan/gy_img_banner_a_r.png"),
        //   banner: "indusAuto_bg1",
        // },
        
      ],
      hotList: [
        {
          img: require("@img/home/car.png"),
          title: "车联网",
          content: "提供平台开发支撑和车联网服务",
          path: "",
        },
        {
          img: require("@img/home/transport.png"),
          title: "两客一危一重",
          content: "协同运营商推出专用适配套餐",
          path: "",
        },
        {
          img: require("@img/home/hot-img.png"),
          title: "智慧园区",
          content: "利用数据化等手段对产业园区进行管理",
          path: "",
        },

        {
          img: require("@img/home/water.png"),
          title: "智慧水务",
          content: "实现水务管理的自动化、数字化、协同化、智能化",
          path: "",
        },
        {
          img: require("@img/home/storage.png"),
          title: "智慧仓储",
          content: "提高生产效率、降低运营成本",
          path: "",
        },
        {
          img: require("@img/home/power-plant.png"),
          title: "智慧电厂",
          content: "打造更安全、更可靠、更经济更环保更高效的智慧发电",
          path: "",
        },
        {
          img: require("@img/home/farming.png"),
          title: "智慧养殖",
          content: "实现对环境数据的无线采集监测和自动化养殖",
          path: "",
        },
        {
          img: require("@img/home/collection.png"),
          title: "数据采集",
          content: "智慧物联数据采集传输及分析系统",
          path: "",
        },
        {
          img: require("@img/home/port.png"),
          title: "智慧港口",
          content: "实现信息服务产业化，优化港口各环节作业",
          path: "",
        },
      ],
      serveList: [
        {
          img: require("@img/home/car.png"),
          title: "车联网",
          content: "提供平台开发支撑和车联网服务",
          path: "",
        },
        {
          img: require("@img/home/transport.png"),
          title: "两客一危一重",
          content: "协同运营商推出专用适配套餐",
          path: "",
        },
      ],
      indusAutoList: [
        {
          img: require("@img/home/water.png"),
          title: "智慧水务",
          content: "实现水务管理的自动化、数字化、协同化、智能化",
          path: "",
        },
        {
          img: require("@img/home/storage.png"),
          title: "智慧仓储",
          content: "提高生产效率、降低运营成本",
          path: "",
        },
        {
          img: require("@img/home/power-plant.png"),
          title: "智慧电厂",
          content: "打造更安全、更可靠、更经济更环保更高效的智慧发电",
          path: "",
        },
        {
          img: require("@img/home/farming.png"),
          title: "智慧养殖",
          content: "实现对环境数据的无线采集监测和自动化养殖",
          path: "",
        },
        {
          img: require("@img/home/collection.png"),
          title: "数据采集",
          content: "智慧物联数据采集传输及分析系统",
          path: "",
        },
        {
          img: require("@img/home/port.png"),
          title: "智慧港口",
          content: "实现信息服务产业化，优化港口各环节作业",
          path: "",
        },
      ],
      devList: [
        {
          img: require("@img/home/hot-img.png"),
          title: "智慧园区",
          content: "利用数据化等手段对产业园区进行管理",
          path: "",
        },
      ],
      oddsList: [
        {
          img: require("@img/home/ic_home_c_a.png"),
          name: "团队优势",
          title: "核心团队成员主要来自于三大运营商、华为、科研机构等。",
        },
        {
          img: require("@img/home/ic_home_c_b.png"),
          name: "技术优势",
          title:
            "与中科院软件所形成战略合作关系，共同创新技术，结合中科院组建自己独特的物联网、工业数字化及软硬件开发技术团队。",
        },
        {
          img: require("@img/home/ic_home_c_c.png"),
          name: "行业优势",
          title:
            "与三大运营商、知名科研机构、国内主流车企、大型工业制造企业都保持紧密的合作，拥有丰富的车联网、工业数字化行业经验。",
        },
        {
          img: require("@img/home/ic_home_c_d.png"),
          name: "平台优势",
          title:
            "自主研发的智能管理平台，为客户的物联网、工业数字化、软硬件定制等业务提供专业智能的平台服务。",
        },
      ],

      successList: [
        {
          img: require("@img/home/ic_wlw_e_a.png"),
          name: "制造业",
          title: ["自动化生产", "工业数字化", "智慧工厂"],
        },
        {
          img: require("@img/home/ic_wlw_e_b.png"),
          name: "农业",
          title: ["自动化浇灌", "智慧种植", "智慧监控"],
        },
        {
          img: require("@img/home/ic_wlw_e_c.png"),
          name: "医疗",
          title: ["AI智能辅诊", "智慧就医平台", "智慧医院"],
        },
        {
          img: require("@img/home/ic_wlw_e_d.png"),
          name: "教育",
          title: ["在线智能课堂", "工业数字化", "智慧工厂"],
        },
        {
          img: require("@img/home/ic_wlw_e_e.png"),
          name: "金融",
          title: ["云上金融", "多重安全机制", "多中心合规"],
        },
        {
          img: require("@img/home/ic_wlw_e_f.png"),
          name: "小程序",
          title: ["官方生态", "原生云端支撑", "云开发"],
        },
        {
          img: require("@img/home/ic_wlw_e_g.png"),
          name: "电商",
          title: ["定制商城", "自动发货", "新零售"],
        },
        {
          img: require("@img/home/ic_wlw_e_h.png"),
          name: "能源",
          title: ["节能优化", "智慧电厂", "新能源"],
        },
        {
          img: require("@img/home/ic_wlw_e_i.png"),
          name: "互联网家居",
          title: ["设备智慧联动", "多重探测", "智跟踪行为"],
        },
        {
          img: require("@img/home/ic_wlw_e_j.png"),
          name: "物流",
          title: ["智慧仓储", "海运服务", "精准管理"],
        },
        {
          img: require("@img/home/ic_wlw_e_k.png"),
          name: "汽车",
          title: ["智慧出行", "精准定位", "车辆管理"],
        },
        {
          img: require("@img/home/ic_wlw_e_l.png"),
          name: "云计算",
          title: ["智慧组网", "高性能", "车辆管理"],
        },
      ],
      partnerList: [
        {
          img: require("@img/home/logo/logo01.png"),
          title: "中国移动",
        },
        {
          img: require("@img/home/logo/logo02.png"),
          title: "中国电信",
        },
        {
          img: require("@img/home/logo/logo03.png"),
          title: "中国联通",
        },
        {
          img: require("@img/home/logo/logo04.png"),
          title: "比亚迪汽车",
        },
        {
          img: require("@img/home/logo/logo05.png"),
          title: "HUAWEI",
        },
        {
          img: require("@img/home/logo/logo06.png"),
          title: "Tencent腾讯",
        },
        {
          img: require("@img/home/logo/logo07.png"),
          title: "阿里云",
        },
        {
          img: require("@img/home/logo/logo08.png"),
          title: "中国科学院",
        },
        {
          img: require("@img/home/logo/logo09.png"),
          title: "",
        },
        {
          img: require("@img/home/logo/logo10.png"),
          title: "碧桂园",
        },
        {
          img: require("@img/home/logo/logo11.png"),
          title: "禾喜",
        },
        {
          img: require("@img/home/logo/logo12.png"),
          title: "中海达",
        },
        {
          img: require("@img/home/logo/logo13.png"),
          title: "南方卫星导航",
        },
        {
          img: require("@img/home/logo/logo14.png"),
          title: "合众思壮",
        },
        {
          img: require("@img/home/logo/logo16.png"),
          title: "勤思网络",
        },
        {
          img: require("@img/home/logo/logo17.png"),
          title: "中交慧联",
        },
        {
          img: require("@img/home/logo/logo18.png"),
          title: "",
        },
        {
          img: require("@img/home/logo/logo15.png"),
          title: "日滨科技",
        },
      ],
      moving_data: [
        {
          img: require("@img/home/iso1.png"),
          title: "ISO9001",
        },
        {
          img: require("@img/home/iso2.png"),
          title: "ISO14001",
        },
        {
          img: require("@img/home/iso3.png"),
          title: "ISO45001",
        },
        {
          img: require("@img/home/iso4.png"),
          title: "ISO27001",
        },
        {
          img: require("@img/home/iso5.png"),
          title: "ISO20000",
        },
        {
          img: require("@img/home/cmmi3.png"),
          title: "CMMI3认证",
        },{
          img: require("@img/home/ccrc.png"),
          title: "CCRC认证",
        },
      ],
      newList: {
        yearData: [
          {
            name: "工地人员定位的出发点，以及工地定位的意义",
            title:
              "随着我国的发展速度加快，针对基础建设一类的需求也日益增加。城市地铁、铁路施工单位改善了路线技术指标、缩短了路程和行车时间，提高了运营效益，国家不但加大施工单位的建设力度；然而施工单位建设造价高、运营管理相对复杂，所以各地对施工单位的建设都十分重视，不敢掉以轻心。 这一类的大型的施工一旦出现责任事故都是严峻重大的，所以近几年社会各界以及政府单位对施工的安全越来越重视。那么对于施工单位方面应该如何提高安全监管落后的管理模式、从而实现智能化、现代化、信息化的转型升级呢？这成为了需要管理者去研究的重要课题。",
            year: "2020",
            month: "12",
            date: "14",
          },
          {
            name: "华云神舟开放式IoT平台的组成",
            title:
              '物联网IoT平台是通过各种传感技术、通讯手段，将传统物体与互联网进行连接。以实现远程监控、自动报警、远程控制、远程诊断、远程维护。进而实现管理、控制、营运一体化的网络。作为新一代的信息通信技术，实现人与人、人与物、物与物的信息互联。随着物联网(IoT)继续向企业领域和面向消费者的应用程序渗透，物联网有望转变我们的业务模式，甚至转变业务本身的性质。物联网不是单一的技术，而是很多技术相互作用的结果，因此它与行业和业务之间的相关性是高低不等的。华云神舟开放式IOT平台：开放式IoT平台是华云神舟自主研发的新一代基于物联网产品，主要面向智能家居、智慧社区、智慧园区和生活服务，综合运用物联网技术、边缘计算、大数据分析、自动化等技术手段，构建"互联网+设备"的智能化平台，建立设备与云端之间的双向通信，支持海量数据的收集、预测、告警和监控，通过分布式结构和多重数据保障机制进行大量的信息传输，从而在极短时间内为产品赋予联网智能化，真正达到万物互联。',
            year: "2020",
            month: "12",
            date: "14",
          },
        ],
        todayData: [
          {
            name: "智慧农业大棚环境监控系统",
            title: "智慧农业大棚环境监控系统",
            year: "2020",
            month: "12",
            date: "14",
          },
          {
            name: "物联网 (IoT) 安全市场",
            title: "物联网 (IoT) 安全市场",
            year: "2020",
            month: "12",
            date: "14",
          },
          {
            name: "NEC 电子标签解决方案简要介绍",
            title:
              "NEC 电子标签解决方案简要介绍",
            year: "2021",
            month: "12",
            date: "23",
          },
        ],
      },
      is_active:0,
      planTabs:0,
      bg_img:'bg_img0'
    };
  },
  computed: {},
  created() {
    // this.init()
    // this.home = home
  },
  mounted() {
    // this.init()
    // AOS.init({
    //   offset: 120,
    //   duration: 1200,   //持续时间
    //   easing: 'ease-in-sine',
    //   delay: 150,
    //   once:true,
    // })
  },
  updated(){
    // this.$nextTick(() => {   //页面渲染完，在执行
    //   AOS.init();
    // })
  },
  methods: {
    async init() {
      let {
        data: {
          bannerData,
          oddsList,
          successList,
          partnerList,
          newList,
          hotList,
        },
      } = await getList();
      Object.assign(this, {
        bannerData,
        oddsList,
        successList,
        partnerList,
        newList,
        hotList,
      });
      // console.log(this)
    },
    closeTop() {
      this.isShow = false;
    },
    goDetail1() {
      this.$router.push({ path: "/details?id=" + 0 });
    },
    gotoInformation(i) {
      console.log(i)
      this.$router.push({ path: "/information?id=" + i });
    },
    manageenter(index) {
      this.manageActive = index;
    },
    gotoNext(data){
      this.$router.push({ path: data.target.dataset.path});
    },
    gotos(path){
      this.$router.push({ path: path});
    },
    planHover(i) {
      this.planActive = i;
    },
    mouseenter(i) {
      this.oddActive = i;
    },
    mouseenter3(i) {
      this.oddActive3 = i;
    },
    mouseenterSucc(i) {
      this.successActive = i;
    },
    mouseenterPartner(i) {
      console.log(i);
      this.partnerActive = i;
    },
    mouseentercent(i) {
      this.centA = i;
    },
    mouseenterRight(i) {
      this.centB = i;
    },
    tabs_fn(id,name){
      this.is_active = id,
      this.bg_pic = name
    },
    planTabsFn(index){
      this.planTabs = index;
      this.bg_img = 'bg_img'+index;
    },
    isShowFn(){
      this.isShow = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/base.scss";
.level {
  padding: 0 !important;
}
.max_tit {
  font-size: 28px;
  padding: 68px 0 50px 0;
  height: 40px;
  font-size: 28px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #141414;
  line-height: 46px;
}
.oddBox {
  .oddBox_flexs1 {
    margin-bottom: 27px;
  }
  .flexs {
    .position_r {
      position: relative;
      overflow: hidden;
    }
    .oddBox_flexs_title {
      height: 245px;
      border-radius: 2px;
      backdrop-filter: blur(6px);
      line-height: 64px;
      position: absolute;
      bottom: -180px;
      width: 100%;
      .imgs{
        width: 20px;
        margin-top: 21px;
        display: none;
      }
      .title{
        font-size: 24px;
        color: #fff;
      }
      .router-link a{
        font-size: 24px;
        color: #fff;
      }
      .text{
        font-size: 12px;
        font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
        font-weight: 400;
        text-align: center;
        color: #fff;
      }
      .line{
        width: 315px;
        height: 1px;
        background: #ffffff;
        margin: 0 auto;
      }
      .flex_oddBox{
        display: flex;
        flex-wrap: wrap;
        width: 315px;
        justify-content: space-around;
        margin: 0 auto;
        .flex_oddBox_list{
          width: 30%;
          text-align: left;
          height: 40px;
          img{
            display: inline-block;
            vertical-align: middle;
            margin-right: 3px;
          }
          span{
            font-size: 14px;
            font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
            text-align: left;
            color: #ffffff;
          }
        }
      }
    }
    .oddBox_flexs_title2{
      height: 192px;
      bottom: -129px;
      width: 100%;
      .text{
        line-height: 30px;
      }
      .flex_oddBox{
        .flex_oddBox_list{
          height: 30px;
        }
      }
    }
    .position_r:hover {
      .oddBox_flexs_title1{
        bottom: -9px;
        transition: all .5s;
      }
      .imgs{
        display: inline-block;
      }
      .title{
        cursor: pointer;
        padding-top: 10px;
      }
    }
    .position_r:hover .oddBox_flexs_title2{
      bottom: 0;
      transition: all .5s;
    }
    .position_r:hover{
      //  border: 1px solid #005de0;
      // box-shadow:5px 5px 15px 0px #595959; 
      box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
      // transform: scale3d(1.01,1.01,1.01);
      transition: all .3s cubic-bezier(0,0,.5,1);
    }
   
  }
}

//t2
.df {
  .dfl {
    width: 360px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 54px auto;
   //padding-top: 54px;
    div {
      padding: 13px 24px;
      font-size: 16px;
      cursor: pointer;
      background: linear-gradient(180deg,#f2f6fc, #fcfcfc);
      border: 2px solid #ffffff;
      border-radius: 4px;
      box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08); 
    }
    .is_active {
      background: #0063e6;
      color: #fff;
    }
  }
  .dfr {
    width: 1142px;
    height: 548px;
    background: linear-gradient(180deg,#f2f6fc, #fcfcfc);;
    border: 2px solid #ffffff;
    border-radius: 0px 2px 2px 0px;
    box-shadow: 5px 5px 15px 0px #dde2eb;
    padding: 34px 27px 16px 27px;
    
     .dfr_f {
      display: flex;
      border-radius: 4px;
      background: linear-gradient(90deg,rgba(252,254,255,0.00), #fafcff 45%, rgba(255,255,255,0.00));
      border: 1px solid #d6e9ff;
      border-radius: 4px;
      padding: 30px 43px;
      img {
        width: 325px;
        height: 205px;
        margin-right: 21px;
      }
      .dfr_f_r {
        padding-left: 24px;
        position: relative;
        .dfr_title {
          font-size: 24px;
          text-align: left;
          color: #0063e6;
          margin-top: 25px;
        }
        .dfr_text {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #141414;
          line-height: 30px;
          width: 580px;
          position: relative;
          z-index: 2;
        }
        .dfr_f_r_bg_pic{
          width: 250px;
          height: 165px;
          position: absolute;
          right: -147px;
          top: -30px;
          img{
            width: 250px;
            height: 165px;
            position: relative;
            z-index: 1;
          }
        }
      }
    }
    .dfr_b {
      display: flex;
      flex-wrap: wrap;
      .dfr_b_list {
        width: 336px;
        height: 104px;
        background: #ffffff;
        border: 1px solid #f5f5f5;
        border-radius: 4px;
        padding: 15px 16px 0 16px;
        display: flex;
        margin-top: 16px;
        .dfr_b_list_l {
          width: 44px;
          img {
            width: 44px;
            height: 44px;
            display: block;
          }
        }
        .dfr_b_list_r {
          .dfr_b_list_r_title {
            font-size: 16px;
            text-align: left;
            color: #141414;
            position: relative;
            margin-top: 2px;
          }
          .dfr_b_list_r_title::after {
            content: "";
            width: 25px;
            height: 2px;
            background: #0063e6;
            position: absolute;
            bottom: -12px;
            left: 0;
          }
          .dfr_b_list_r_text {
            font-size: 12px;
            text-align: left;
            color: #727f8f;
            line-height: 20px;
            margin-top: 20px;
          }
        }
      }
      .dfr_b_list:nth-child(3n-1) {
        margin-left: 16px;
        margin-right: 16px;
      }
      .dfr_b_list:hover{
        // box-shadow: 5px 5px 15px 0px #dde2eb; 
        transition: all .3s cubic-bezier(0,0,.5,1);
        box-shadow: 0px 5px 18px 0px #c7d6eb !important;
      }
    }
  }
}
.bg_pic {
  background: url("../../public/img/serve/management.png");
}
.bg_pic1 {
  background: url("../../public/img/home/ic_home_pt_bg.png");
  background-size: contain;
}
.bg_pic2 {
  background: url("../../public/img/home/ic_home_pt_bg2.png");
  background-size: 100% 100%;
}
.bg_pic3 {
  background: url("../../public/img/home/img_cz_pt_bg.png");
  background-size: 100% 100%;
}
.bg_color3 {
  // background: url("../../public/img/home/img_cz_pt_bg.png");
  .max_tit {
    color: #fff;
    padding: 68px 0 16px 0;
  }
  .line {
    width: 1200px;
    height: 1px;
    background: #c4c4c4;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .plan_container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 48px;
    margin-top: 15px;
  }
  .plan {
    width: 646px;
    background: rgba(0,0,0,.3);
    border-radius: 4px;
    backdrop-filter: blur(4px);
    .plan_box {
      padding: 30px 28px;
      font-family: "PingFang SC, PingFang SC-Regular";
      .title {
        height: 30px;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        text-align: left;
        margin-bottom: 24px;
      }
      .text {
        width: 520px;
        height: 85px;
        opacity: 0.71;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 26px;
        margin-bottom: 38px;
      }
      .plan_span {
        text-align: left;
        span {
          color: #fff;
          line-height: 25px;
          background: rgba(255, 255, 255, 0.31);
          border-radius: 2px;
          display: inline-block;
          margin-right: 12px;
          text-align: center;
          padding: 4px 7px;
        }
      }
      .plan_btn {
        color: #fff;
        display: flex;
        margin-top: 38px;
        .plan_btn1 {
          padding: 6px 40px;;
          background: #0063e6;
          border: 1px solid #0063e6;
          border-radius: 2px;
          cursor: pointer;
          margin-right: 24px;
        }
        .plan_btn2 {
          padding: 6px 40px;
          border: 1px solid #ffffff;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }
  }

  .plan_right{
    width: 168px;
    height: 336px;
    background: rgba(0, 0, 0, 0.42);
    border-radius: 4px;
    float: right;
    .plan_right_list {
      border-bottom: 1px solid rgb(210 210 210 / 14%);
      transition: all .5s;
      div {
        padding: 14px 24px;
        color: #fff;
        text-align: left;
        font-size: 14px;
        cursor: pointer;
        img{
          margin-right: 5px;
        }
      }
      .notLine{
        border-bottom: none;
      }
    }
    .plan_right_list:hover {
      background: #005de0;
      // transform: ;
      transition: all .5s;
      // animation: all 1s;
    }
    .plan_right_list:last-child{
      border-bottom: none;
    }
    .planIsActive {
      background: #005de0;
    }
  }
}

// t3

.autoWidth {
  width: 1200px;
  margin: 0 auto;
  margin-top: 32px;
  padding-bottom: 88px;
}
.partner {
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .partner_list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9px;
border-radius: 4px;
box-shadow: 8px 8px 24px 0px rgba(0,55,128,0.08); 
    // loading大小
    img[lazy="loading"] {
      display: block;
      height: 50% !important;
      width: 80% !important;
    }
    img {
      width: 178px;
      height: 78px;
      // background-size: cover;
    }
  }
  .partner_list:hover{
    // transform: all .5s;
    transition: all .5s;
  }
  .partnerActive {
    transform: scale(1.3) !important;
    z-index: 11;
  }
}

.moving {
  .left {
    display: flex;
    .moving_left {
      width: 320px;
      display: flex;
      flex-wrap: wrap;
      height: 190px;
      position: relative;
      bottom: -150px;
    }
    .moving_left_list {
      margin-right: 68px;
      margin-bottom: 48px;
      text-align: left;
      .moving_left_list_t {
        color: #0063e6;
        .fs28 {
          font-size: 28px;
        }
        .fs24 {
          font-size: 22px;
        }
        img {
          vertical-align: super;
        }
      }
      .moving_left_list_b {
        p {
          font-size: 16px;
          color: #4e5c6d;
        }
      }
    }
  }
  .moving_item_bottom {
    display: flex;
    width: 1200px;
    padding: 20px 0 31px 0;
    background: linear-gradient(180deg, #f4f6f9, #ffffff);
    border: 2px solid #ffffff;
    border-radius: 4px;
    box-shadow: 5px 5px 15px 0px #ebeff5;
    position: absolute;
    bottom: -70px;
    .moving_item_b {
      width: 512.4px;
      text-align: center;
      img {
        width: 66px;
        height: 66px;
        border-radius: 66px;
    box-shadow: 8px 8px 24px 0px rgb(0 55 128 / 8%);
      }
      div {
        color: #323232;
        font-size: 14px;
        width: 138px;
        text-align: center;
        margin: 0 auto;
        margin-top: 16px;
      }
    }
    .moving_item_b img:hover{
      transition: all .3s cubic-bezier(0,0,.5,1);
      box-shadow: 0px 5px 18px 0px #c7d6eb !important
    }
  }
}
.bg_img0{
  background: url("../../public/img/home/ic_home_fa_yq_bg.png");
  transition: all .5s;
  width: 100%;
  transition: all 0.5s;
  background-size: cover;
  // background-repeat: no-repeat;
}
.bg_img1{
  background: url("../../public/img/home/banner/banner5.png");
  transition: all .5s;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg_img2{
  background: url("../../public/img/home/banner/banner1.png");
  transition: all .5s;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg_img3{
  background: url("../../public/img/home/banner/banner2.png");
  transition: all .5s;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg_img4{
  background: url("../../public/img/home/banner/banner3.png");
  transition: all .5s;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg_img5{
  background: url("../../public/img/home/banner/banner6.png");
  transition: all .5s;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg_img6{
  background: url("../../public/img/home/banner/banner4.png");
  transition: all .5s;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

}

.information{
  width: 1200px;
  margin: 0 auto;
  .information_t{
    display: flex;
    justify-content: space-between;
    .information_t_l{
      display: flex;
      .tabs{
        width: 150px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #b1b5b9;
        color: #323232;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        cursor: pointer;    
      }
      .isTabs{
        background: #0063e6;
        border: 1px solid #0063e6;
        color: #fff;
      }
    }
    .information_t_r{
      text-align: right;
      padding-top: 20px;
      span{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #323232;
        line-height: 24px;
      }
      img{
        vertical-align: baseline;
        position: relative;
        top: 2px;
      }
    }
  }
  .information_c{
    margin-top: 24px;
    .information_c_t{
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;
      .information_c_t_item{
        padding: 32px 28px;
        width: 530px;
        background: linear-gradient(180deg,#f4f6f9, #ffffff);
        border: 2px solid #ffffff;
        border-radius: 4px;
        border-top:4px solid #0063e6;
        box-shadow: 5px 5px 15px 0px #ebeff5; 
        height: 136px;
        position: relative;
        .information_c_t_tit{
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          text-align: left;
          color: #161719;
          margin-bottom: 22px;
        }
        .information_c_t_text{
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #727f8f;
          line-height: 24px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .information_c_t_more{
          text-align: left;
          position: absolute;
          bottom: 12px;
          cursor: pointer;
          span{
            font-size: 14px;
            color: #0063e6;
            line-height: 23px;
          }
          img{
            vertical-align: middle;
            position: relative;
            top: -2px;
            left: 4px;
          }
        }
      }
      .information_c_t_item:last-child{
        margin-left: 22px;
      }
    }
    .information_c_b{
      display: flex;
      justify-content: space-between;
      .information_c_b_item{
        padding: 32px 28px;
        background: linear-gradient(180deg,#f4f6f9, #ffffff);
        border: 2px solid #ffffff;
        border-radius: 4px;
        box-shadow: 5px 5px 15px 0px #ebeff5; 
        position: relative;
        width: 326px;
        .information_c_b_tit{
          height: 46px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          text-align: left;
          color: #161719;
          line-height: 24px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 16px;
        }
        .information_c_b_more{
          position: absolute;
          bottom: 12px;
          text-align: left;
          cursor: pointer;
          span{
            font-size: 14px;
            color: #0063e6;
            line-height: 23px;
          }
          img{
            vertical-align: middle;
            position: relative;
            top: -2px;
            left: 4px;
          }
        }
      }
      .information_c_b_item:nth-child(2){
        margin: 0 22px;
      }
    }
  }
}
.manageActive {
  background: linear-gradient(180deg, #edf2fa, #fefefe);
  box-shadow: 0px 5px 18px 0px #c7d6eb;
}
.information_c_t_item:hover,.information_c_b_item:hover{
  background: linear-gradient(180deg, #edf2fa, #fefefe);
  box-shadow: 0px 5px 18px 0px #c7d6eb !important;
  transition: all .3s cubic-bezier(0,0,.5,1);
}
</style>
