<template>
  <div class="t_footer">
    <h3>Copyright © 2020-2022 东莞云百科技有限公司版权所有 Power by yunbai</h3>
    <h3>ICP备案号：粤ICP备17032472号</h3>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss">
.t_footer{
  padding:5px 0;
    h3{
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: center;
      color: #1a1a1a;
      line-height: 24px;
    }
  }
</style>